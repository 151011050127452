import React, { Fragment, useState, useEffect } from 'react';
import { Link, useHistory, useParams } from 'react-router-dom';
import creService from "../../services/creService";
import { toast } from 'react-toastify';
import UnitNoExistsMessage from './unitNotExistsMessage';

const TenantEdit = () => {

    const history = useHistory();

    const params = useParams();

    const [tenant, setTenant] = useState({});
    const [formData, setFormData] = useState({});
    const [isLoading, setIsLoading] = useState(false);

    const loadTenant = async () => {
        const { data } = await creService.getOneTenant(params.id);
        const _tenant = data.data || {};
        setFormData({
            _id: _tenant._id,
            aptNumber: _tenant.aptNumber,
            name: _tenant.fullName,
            email: _tenant.email,
            phone: _tenant.phone,
            notes: _tenant.notes,
            allowMultipleTenants: _tenant.allowMultipleTenants,
        });
        setTenant(_tenant);
    }

    const onChange = (field, value) => {
        setFormData((prev) => ({
            ...prev,
            [field]: value
        }));
    }

    const phoneValidation = () => {
        if (formData.phone === undefined || formData.phone === '') {return true}
    
        // eslint-disable-next-line no-useless-escape
        const regex = /^(\+\d{1,2}\s?)?1?\-?\.?\s?\(?\d{3}\)?[\s.-]?\d{3}[\s.-]?\d{4}$/i;
        return regex.test(formData.phone) ;
    }

    const onSubmit = async (event) => {
        event.preventDefault();

        const isPhoneValidate = phoneValidation();
        
        if(!isPhoneValidate) {
            toast.error("Phone Number not valid!");
            return false;
        }
        
        setIsLoading(true);
        try {
            const { data } = await creService.updateTenant(formData);
            if (data.status) {
                toast.success(data.message);
                history.push('/tenants/list');
            } else {
                toast.error(<UnitNoExistsMessage {...data} />);
            }
            setIsLoading(false);
        } catch (_err) {
            console.log(_err);
            toast.error(_err.message);
            setIsLoading(false);
        }

    }

    useEffect(() => {
        loadTenant()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [params.id]);

    return (
        <Fragment>
            <div className="row violation-sec">
                <div className="col-xl-12 my-3">
                    <div className="card">
                        <div className="card-header">
                            <Link to='/tenants/list'>
                                <button className="btn btn-light btn-lg align-self-center back-btn mx-2" type="button">
                                    <i className="fa fa-angle-left" />
                                </button>
                            </Link>
                            <h5>Edit Tenant</h5>
                        </div>
                        <div className="card-body">
                            <form method="post" onSubmit={onSubmit}>
                                <div className="row">
                                    <div className="form-group col-12">
                                        <label>Building</label>
                                        <input type="text" className="form-control" disabled value={tenant?.building?.display_address ? tenant?.building?.display_address : tenant?.building?.address} />
                                    </div>
                                    <div className="form-group col-6">
                                        <label>Unit/Apt#</label>
                                        <input type="text" value={formData.aptNumber} className="form-control" name="aptNumber" onChange={(e) => onChange(e.target.name, e.target.value)} />
                                    </div>
                                    <div className="col-6 d-flex align-items-center">
                                        <div className="form-check" style={{ marginTop: '18px'}}>
                                            <input type="checkbox" className="form-check-inline" id="allowMultipleTenants" name="allowMultipleTenants" checked={formData.allowMultipleTenants} onChange={(e) => onChange(e.target.name, e.target.checked)} />
                                            <label className="form-check-label" for="allowMultipleTenants" style={{position: 'relative', top: '-2px'}}>Allow Multiple Tenants</label>
                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="form-group col-12 col-md-4">
                                        <label>Tenant Name</label>
                                        <input type="text" value={formData.name} className="form-control" name="name" onChange={(e) => onChange(e.target.name, e.target.value)} />
                                    </div>
                                    <div className="form-group col-12 col-md-4">
                                        <label>Tenant Email</label>
                                        <input type="email" className="form-control" value={formData.email} name="email" onChange={(e) => onChange(e.target.name, e.target.value)} />
                                    </div>
                                    <div className="form-group col-12 col-md-4">
                                        <label>Tenant Phone</label>
                                        <input type="text" className="form-control" value={formData.phone} name="phone" onChange={(e) => onChange(e.target.name, e.target.value)} />
                                    </div>
                                    <div className="form-group col-12">
                                        <label>Notes</label>
                                        <textarea className="form-control" value={formData.notes} name="notes" onChange={(e) => onChange(e.target.name, e.target.value)} />
                                    </div>
                                </div>
                                <button className="btn btn-primary" disabled={isLoading}>{isLoading ? 'Updating...' : 'Submit'}</button>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </Fragment>
    )
}

export default TenantEdit;
