import {
    Layout,
    Calendar,
    // Clipboard,
    Home,
    Menu,
    Shield,
    Folder,
    FileText,
    MessageSquare,
    Tool,
    Mail,
    Box,
} from 'react-feather';

let MENUITEMS = [
    {
        title: 'Dashboard', icon: Layout, type: 'link', path: '/dashboard', active: false, serviceName: 'violations', action: 'all'
    },
    {
        title: 'Buildings', icon: Home, type: 'link', path: '/my-buildings', active: false, serviceName: 'violations', action: 'all'
    },
    {
        title: 'Compliance Due', icon: Menu, type: 'link', path: '/compliance-requirements', active: false, serviceName: 'complianceDue', action: 'all'
    },
    {
        title: 'Calendar', icon: Calendar, type: 'link', path: '/calendar', active: false, serviceName: 'calendar', action: 'all'
    },
    // { 
    //     title: 'Violations', icon: Clipboard, type: 'link', path: '/violations-tab', active: false, serviceName: 'violations', action: 'all'
    // },

    {
        title: 'Permits', icon: Shield, type: 'link', path: '/permits', active: false, serviceName: 'permits', action: 'all'
    },

    {
        title: 'Documents', icon: Folder, type: 'link', path: '/uploads-and-notes', active: false, serviceName: 'violations', action: 'all'
    },

    {
        title: 'Reports', icon: FileText, type: 'link', path: '/reports', active: false, serviceName: 'violations', action: 'all'
    },
    {
        title: 'Vendors', icon: Box, type: 'link', path: '/vendors', active: false, serviceName: 'violations', action: ['list_vendors', 'create_vendor']
    },
    {
        title: 'Chats', icon: MessageSquare, type: 'link', path: '/chats', active: false, serviceName: 'violations'
    },
    {
        title: 'Compliance Portal', icon: Menu, type: 'sub', path: '/custom-compliance', active: true, serviceName: 'custom-compliance', action: 'compliance-full-access',
        children: [
            { path: '/custom-compliance/dashboard', title: 'dashboard', type: 'link', serviceName: 'custom-compliance', action: 'compliance-full-access' },
            { path: '/custom-compliance/guides', title: 'guides', type: 'link', serviceName: 'custom-compliance', action: 'compliance-full-access-guard' },
            { path: '/custom-compliance', title: 'view all', type: 'link', serviceName: 'custom-compliance', action: 'compliance-full-access' },

        ]
    },
    {
        title: 'Work Orders', path: '/work-orders', icon: Tool, type: 'sub', serviceName: 'workOrders', action: ['create', 'list', 'listSub', 'listBin'], beta: false, active: true,
        children: [
            { path: '/work-orders', title: 'Dashboard', type: 'link', serviceName: 'workOrders', action: ['list', 'listSub', 'listBin'] },
            { path: '/work-orders/list', title: 'All Work Orders', type: 'link', serviceName: 'workOrders', action: ['list', 'listSub', 'listBin'] },
            { path: '/work-orders/create', title: 'Add New Work Order', type: 'link', serviceName: 'workOrders', action: 'create' },
            { path: '/create-report?type=WorkOrders', title: 'Report Work Order', type: 'link', serviceName: 'workOrders', action: 'list' },
            { path: '/settings/sub-users', title: 'Users', type: 'link', subUserResctricted: true },
        ]
    },
    {
        title: 'Annual Safety Mailings', path: '/safety-mailings', icon: Mail, type: 'sub', serviceName: 'safetyMailing', action: 'use_safety_mailings', beta: false, active: true,
        children: [
            { path: '/safety-mailings/dashboard', title: 'Dashboard', type: 'link', serviceName: 'safetyMailing', action: 'use_safety_mailings', },
            { path: '/safety-mailings/create', title: 'Send New Batch', type: 'link', serviceName: 'safetyMailing', action: 'use_safety_mailings', },
            { path: '/safety-mailings', title: 'Safety Mailing Orders', type: 'link', serviceName: 'safetyMailing', action: 'use_safety_mailings', },
            { path: '/tenants/list', title: 'Tenants List', type: 'link', serviceName: 'safetyMailing', action: 'use_safety_mailings', },
            { path: '/fire-safety/list', title: 'Fire Safety', type: 'link', serviceName: 'safetyMailing', action: 'use_safety_mailings', },
            { path: '/safety-mailings/auto-rules', title: 'Auto Rules', type: 'link', serviceName: 'safetyMailing', action: 'use_safety_mailings', },
        ]
    },

]

export default MENUITEMS;
