import React, { Fragment, useState } from 'react';
import ReactLoading from 'react-loading';
import { UncontrolledTooltip } from 'reactstrap';
import ModalSnapshot from '../../dashboard/ModalSnapshot'
import VPUSign from '../../../common/VPUSign';

const Block = ({ comparable = 0, current, currentClickHandler, comparableClickHandler, classes, id = null, snapshotDate, snapshotDataLoading }) => {

  // comparable = comparable+1
  return <span className='d-inline' id={id}><span
    className={
      current > 0
        ? classes
        : ''
    }
    onClick={currentClickHandler}
  >
    {current}
  </span>
    {' '}
    {snapshotDate && (!snapshotDataLoading ?
      (
        <span
          className='pointer'
          onClick={comparableClickHandler}
        >
          ({' '}{comparable}{' '}){' '}
        </span>
      )
      :
      (
        <>
          {'('}<span><ReactLoading type={'bars'} color={'#DCDCDC'} width={10} height={10} /></span>{')'}
        </>
      )
    )
    }
  </span>

}


const ViolationBlock = (props) => {


  const [modalSub, setModalSub] = useState(null)
  const [modalCategory, setModalCategory] = useState(null)

  const snapshotOnClickHandler = (category, sub) => {
    setModalSub(sub)
    setModalCategory(category)
  }


  let handleClick = (bin, title, item, count) => {
    localStorage.removeItem('prev-location');
    if (count > 0) {
      if (title === 'Compliace Portal') {

        props.history.push(`/custom-compliance/${item}?bin=${bin}`);
      } else if (['ComplianceRequirements'].includes(title)) {
        props.history.push(`/compliance-requirements/${item}`);
      } else {
        props.history.push(`/violations/table/${bin}/${title}/${item}`);
      }
    }
  };

  const getValue = (category, data) => {
    if (typeof data == 'object') {
      return data?.totalAllTime || data?.totalRecords || 0
    }
    return data
  };

  const onHeaderClick = () =>
    props.history.push(`/violations/category/${props.bin}/${props.title}/Open`);

  const renderHeading = () => {
    if (props.title === 'DOB') {
      return (
        <h5 style={{ cursor: 'pointer' }} onClick={onHeaderClick}>
          DOB
        </h5>
      );
    } else if (props.title === 'ECB') {
      return (
        <h5 style={{ cursor: 'pointer' }} onClick={onHeaderClick}>
          ECB
        </h5>
      );
    } else if (props.title === 'HPD') {
      return (
        <h5
          style={{ cursor: 'pointer' }}
          onClick={onHeaderClick}
          className="d-flex justify-content-between text-center"
        >
          HPD
          <VPUSign VPU={props.addressData?.VPU} VPUHistory={props.addressData?.VPUHistory} />
        </h5>
      );
    } else {
      return (
        <h5>
          {['ComplianceRequirements'].includes(props.title)
            ? 'Compliance Due'
            : ['Compliance'].includes(props.title)
              ? 'Building Compliance'
              : props.title}
        </h5>
      );
    }
  };

  return (
    <Fragment>
      {props.doneLoading &&

        Object.keys(props.data || {}).length !== 0 && <>
          <div className="card flex-fill">
            {renderHeading()}
            <div className="violation-head mb-2"></div>
            <div className="log-content">
              {!props.doneLoading && (
                <div align="center">
                  {' '}
                  <ReactLoading type={'cylon'} color={'grey'} />{' '}
                </div>
              )}
              {props.doneLoading &&
                props.data &&
                Object.keys(props.data).map((item, index) => {
                  if (item === 'LL11') {
                    props.data[item].nextYear = props.data[item].nextYear ? `${props.data[item].nextYear} - ${props.data[item].nextYear + 2}` : props.data[item].nextYear
                  }

                  return (
                    <Fragment key={index + item}>
                      {(['Compliance', 'ComplianceRequirements'].includes(
                        props.title
                      ) ||
                        typeof props.data[item] !== 'object') && (
                          // Single Value = Number
                          <div
                            key={index}
                            className={`logs-element d-flex justify-content-between ${getValue(props.title, props.data[item]) > 0 ? 'pointer' : ''}`}

                          >
                            <span>
                              {item.replace(/__/, '/').replace(/_/g, ' ')}
                              {
                                (props.data[item].nextYear) && (
                                  <small className="ml-1">
                                    (Due {props.data[item].nextYear})
                                  </small>
                                )}


                            </span>
                            <span className="d-flex align-items-center text-center mr-2">
                              <Block
                                snapshotDate={props.snapshotDate}
                                snapshotDataLoading={props.snapshotDataLoading}
                                comparable={getValue(props.title, props.snapshotData?.[item])}
                                current={getValue(props.title, props.data[item])}
                                currentClickHandler={() =>
                                  handleClick(
                                    props.bin,
                                    props.title,
                                    item,
                                    getValue(props.title, props.data[item])
                                  )}
                                comparableClickHandler={() => { snapshotOnClickHandler(props.title, item) }}
                                classes={'text-primary pointer'}
                              />
                            </span>
                          </div>
                        )}
                      {typeof props.data[item] === 'object' &&
                        !['Compliance', 'ComplianceRequirements'].includes(
                          props.title
                        ) && (
                          <>
                            <div key={index} className={'logs-element d-flex '}>
                              <span style={{ minWidth: props.snapshotDate ? '110px' : '75%' }} className='d-flex align-items-center '>{item.replace('__', '/').replace(/_/g, ' ')}</span>
                              <span
                                className={'d-flex mr-2 flex-grow-1 justify-content-between'}
                              >
                                {props.title === 'Permits' && (
                                  <React.Fragment>
                                    <span />
                                    <span>
                                      <Block
                                        snapshotDataLoading={props.snapshotDataLoading}
                                        snapshotDate={props.snapshotDate}
                                        comparable={props.snapshotData?.[item]?.['Open']}
                                        current={props.data[item]['Open']}
                                        currentClickHandler={() =>
                                          handleClick(
                                            props.bin,
                                            props.title,
                                            `${item}__Open`,
                                            props.data[item]['Open']
                                          )}
                                        comparableClickHandler={() => { snapshotOnClickHandler(props.title, `${item}__Open`) }}
                                        classes={'text-danger pointer'}
                                      />
                                      {' '} / {' '}
                                      <Block
                                        snapshotDataLoading={props.snapshotDataLoading}
                                        snapshotDate={props.snapshotDate}
                                        comparable={props.snapshotData?.[item]?.['Closed']}
                                        current={props.data[item]['Closed']}
                                        currentClickHandler={() =>
                                          handleClick(
                                            props.bin,
                                            props.title,
                                            `${item}__Closed`,
                                            props.data[item]['Closed']
                                          )}
                                        comparableClickHandler={() => { snapshotOnClickHandler(props.title, `${item}__Closed`) }}
                                        classes={'text-success pointer'}
                                      />

                                    </span>
                                  </React.Fragment>
                                )}

                                {props.title !== 'Permits' && (
                                  <React.Fragment>

                                    <Block
                                      snapshotDate={props.snapshotDate}
                                      snapshotDataLoading={props.snapshotDataLoading}
                                      comparable={props.snapshotData?.[item]?.['Open']}
                                      current={props.data[item]['Open']}
                                      currentClickHandler={() =>
                                        handleClick(
                                          props.bin,
                                          props.title,
                                          `${item}__Open`,
                                          props.data[item]['Open']
                                        )}
                                      comparableClickHandler={() => { snapshotOnClickHandler(props.title, `${item}__Open`) }}
                                      classes={'text-danger pointer'}
                                      id={"UncontrolledTooltipOpen"}
                                    />
                                    <Block
                                      snapshotDataLoading={props.snapshotDataLoading}
                                      snapshotDate={props.snapshotDate}
                                      comparable={props.snapshotData?.[item]?.['Closed']}
                                      current={props.data[item]['Closed']}
                                      currentClickHandler={() =>
                                        handleClick(
                                          props.bin,
                                          props.title,
                                          `${item}__Closed`,
                                          props.data[item]['Closed']
                                        )}
                                      comparableClickHandler={() => { snapshotOnClickHandler(props.title, `${item}__Closed`) }}
                                      classes={'text-success pointer'}
                                      id={"UncontrolledTooltipClosed"}
                                    />

                                  </React.Fragment>
                                )}
                              </span>
                            </div>

                          </>
                        )}
                    </Fragment>
                  )
                })}

            </div>
          </div>

          <UncontrolledTooltip placement="right" target="UncontrolledTooltipOpen">
            Open
          </UncontrolledTooltip>

          <UncontrolledTooltip placement="right" target="UncontrolledTooltipClosed">
            Closed
          </UncontrolledTooltip>
          <ModalSnapshot
            selectedDate={props.snapshotDate}
            sub={modalSub}
            category={modalCategory}
            isDashboard={false}
            bin={props.bin}
          />
        </>
      }
    </Fragment>
  );
};

export default ViolationBlock;
