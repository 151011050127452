import React, { useEffect, useState } from "react";
import { useParams } from "react-router";

import { Link, useLocation } from 'react-router-dom';
import ReactLoading from "react-loading";
import { toast } from "react-toastify";
import { RedoOutlined } from '@ant-design/icons';
import { Button } from 'antd';

import creService from "../../../services/creService";
import { permissionsKey } from "../../../services/authService";
import Documents from "./documents";
import Activity from "./activity/index";
import Chat from "./chat/chat";
import Guard from "../../../common/guard";
import FieldWidget from './fieldWidget';
import DocsViewer from '../../../common/document-viewer'
import logoVisitt from '../../../../assets/images/visitt-logo-main.svg';
import appWork from '../../../../assets/images/appWork.svg';

const moment = require('moment');

const WorkOrderDetails = ({ jestData = false }) => {
    const location = useLocation();
    const [workOrder, setWorkOrder] = useState(jestData ? jestData : {});
    const [isDownloading, setIsDownloading] = useState(false);
    const [doneLoading, setDoneLoading] = useState(false);
    const [accessDeniedMessage, setAccessDeniedMessage] = useState();
    const [currentfile, setCurrentfile] = useState(null);
    const [docsViewerVisibility, setDocsViewerVisibility] = useState(false);
    const [visittSync, setVisittSync] = useState(false);
    const params = useParams();

    const getWorkOrder = async (id) => {
        const res = await creService.getWorkOrder(id);
        if (res.data.status) {
            setWorkOrder(res.data.data);
            if (accessDeniedMessage) {
                setAccessDeniedMessage('')
            }

        } else {
            setAccessDeniedMessage('Access Denied. Please check if you have permission to see that page')
        }
        if (res.data.permissions) {
            localStorage.setItem(permissionsKey, JSON.stringify(res.data.permissions));
        }
        setDoneLoading(true);
    }


    const handleUrlClick = async (document) => {
        if (document.file?.mimetype) {
            setCurrentfile(document)
            setDocsViewerVisibility(true)
        } else {
            if (document.key) {
                const result = await creService.getDocumentUrlWorkOrder(document.key);
                if (result.data.status) {
                    window.open(result.data.url);
                } else {
                    toast.error(result.data.message);
                }
            }
        }

    }

    const handleDownload = async () => {
        try {
            setIsDownloading(true);
            const { data } = await creService.getWorkOrderPdf(params.id);
            window.open(data.awsUrl);
            setIsDownloading(false);
        } catch (_err) {
            setIsDownloading(false);
            toast.error(_err.message);
        }
    }
    const syncOrder = async (source) => {

        try {
            setVisittSync(true);
            let data
            if (source === 'visitt') {
                const response = await creService.syncVisittOrder(workOrder.sourceID);
                data = response.data
            } else if (source === 'appWork') {
                const response = await creService.syncAppWorkOrder(workOrder.sourceID);
                data = response.data
            }

            if (data.status) {
                await getWorkOrder(params.id)
                toast.success('Work Order Synced');
            } else {
                toast.error(data.message);
            }

        } catch (_err) {
            toast.error(_err.message);
        }
        setVisittSync(false);
    }
    const moveToVisittOrder = async () => {

        try {
            setVisittSync(true);
            const { data } = await creService.moveToVisittOrder(workOrder._id);
            if (data.status) {
                await getWorkOrder(params.id)
                toast.success('Work Order Moved to Visitt');
            } else {
                toast.error(data.message);
            }

        } catch (_err) {
            toast.error(_err.message);
        }
        setVisittSync(false);
    }

    useEffect(() => {
        getWorkOrder(params.id)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [params.id])

    useEffect(() => {

        if (workOrder.sourceID) {

            syncOrder(workOrder.source)


        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [workOrder.sourceID])

    return (
        accessDeniedMessage ? <div className="pt-3"> <div className="alert alert-warning text-center" role="alert">  {accessDeniedMessage} </div></div> :
            <div className="container-fluid bg-light">
                <div className="row text-dark px-3">
                    <div className="col-xl-12 my-3">
                        <div className="card">
                            <div className="card-body p-0 bg-light">
                                <div className="d-flex align-items-center justify-content-lg-between flex-column flex-md-row">
                                    <div className="d-flex align-items-center mb-2">
                                        <Link to='/work-orders/list'>
                                            <button className="btn btn-light btn-sm align-self-center back-btn btn-lg" type="button">
                                                <i className="fa fa-angle-left"></i>
                                            </button>
                                        </Link>
                                        <h3 className="ml-3 mb-0 font-weight-bold text-dark">
                                            View Work Order
                                        </h3>
                                        {workOrder.safetyMailingAction && <small className="badge badge-primary ml-2">{workOrder.safetyMailingAction}</small>}
                                    </div>
                                    {!['visitt', 'appWork'].includes(workOrder.source) &&
                                        <div className="actions mb-2">
                                            <a href={`/work-orders/print/${workOrder._id}`} className="btn btn-primary btn-sm mr-2 text-white" target="_blank" rel="noreferrer">
                                                <i className="fa fa-print"></i> Print
                                            </a>

                                            <button className="btn btn-primary btn-sm mr-2 text-white" onClick={() => handleDownload()}>
                                                <i className="fa fa-download"></i> {isDownloading ? ' Downloading...' : ' PDF'}
                                            </button>
                                            <Guard
                                                serviceName="workOrders"
                                                action="create"
                                                allow={() =>
                                                    <Link to={`/work-orders/create?clone=${workOrder._id}`} className="btn btn-primary btn-sm mr-1 text-white">
                                                        <i className="fa fa-copy"></i> Clone
                                                    </Link>
                                                } />
                                            <Guard
                                                serviceName="workOrders"
                                                action={['edit', 'partial_edit']}
                                                allow={() => <span className="ml-1">
                                                    <Link className="btn btn-primary btn-sm text-white" to={{
                                                        pathname: `/work-orders/edit/${workOrder._id}`,
                                                        state: { from: location }
                                                    }}>
                                                        <i className="fa fa-pencil"></i> Edit
                                                    </Link>
                                                </span>}
                                            />
                                        </div>
                                    }
                                </div>
                            </div>
                        </div>
                        {!doneLoading && <div className="loadercenter"> <ReactLoading type={"cylon"} color={"grey"} /> </div>}
                        {doneLoading &&
                            <>
                                <DocsViewer document={currentfile} isVisible={docsViewerVisibility} setIsVisible={setDocsViewerVisibility} />
                                <div className="row">
                                    <div className="col-12 col-md-7">
                                        <div className="row">
                                            <div className="col-12 col-md-4">
                                                <div className="card">
                                                    <div className="card-body p-3 text-dark text-center">
                                                        {workOrder.source === 'visitt' && <>

                                                            <img src={logoVisitt} height='25px' alt='visitt' className="mb-2" />
                                                            {
                                                                workOrder.sourceID &&
                                                                <Button className='ml-3' type="dashed" icon={<RedoOutlined />} loading={visittSync} onClick={() => syncOrder(workOrder.source)} >
                                                                    Sync
                                                                </Button>
                                                            }
                                                        </>}
                                                        {workOrder.source === 'appWork' &&
                                                            <div  >

                                                                <img src={appWork} height='40px' alt='appWork' style={{ background: 'rgb(4, 51, 59)', borderRadius: '7px', padding: "5px" }} className="mb-2" />
                                                                {
                                                                    workOrder.sourceID &&
                                                                    <Button className='ml-3' type="dashed" icon={<RedoOutlined />} loading={visittSync} onClick={() => syncOrder(workOrder.source)} >
                                                                        Sync
                                                                    </Button>
                                                                }
                                                            </div>}


                                                        {workOrder.source === 'visitt' || workOrder.source === 'appWork' ?

                                                            <a href={workOrder.source === 'visitt' ?
                                                                `https://visitt.co.il/issue/${workOrder.sourceID}` :
                                                                `https://admin.appworkco-beta.com/orders/${workOrder.sourceID}`} target="_blank" rel="noreferrer">
                                                                <h3 className="text-blue font-weight-bold">#{workOrder.workOrderId}</h3>
                                                            </a> : <h3 className="text-blue font-weight-bold">#{workOrder.workOrderId}</h3>
                                                        }
                                                        {workOrder.source !== 'visitt' && workOrder.isVisitIntegrationEnabled && workOrder.workOrderCreateAbility.visitt && <div>
                                                            <Button type="dashed" loading={visittSync} onClick={moveToVisittOrder} >
                                                                Move To Visitt
                                                            </Button>

                                                        </div>}
                                                        <small className="text-capitalize muted">Created By: {workOrder.createdByUser?.fullName || '-'}</small>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-12 col-md-8">
                                                <div className="card">
                                                    <div className="card-body p-0 text-dark">
                                                        <ul className="list-group list-group-flush">
                                                            <li className="list-group-item d-flex">
                                                                <div className="text-blue font-weight-bold text-right" style={{ width: '35%' }}><i className="fa fa-map-marker" /> Building Address</div>
                                                                <div className="px-2" style={{ width: '65%' }}>{workOrder.source === 'visitt' ? workOrder.building.visittAddress : (workOrder.building && (workOrder.building.display_address ? workOrder.building.display_address : workOrder.building.address))}</div>
                                                            </li>
                                                            <li className="list-group-item d-flex">
                                                                <div className="text-blue font-weight-bold text-right" style={{ width: '35%' }}><i className="fa fa-building" /> Apt #</div>
                                                                <div className="px-2" style={{ width: '65%' }}>{workOrder.aptNumber || '-'}</div>
                                                            </li>
                                                        </ul>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-12">
                                                <div className="card">
                                                    <div className="card-body p-0 text-dark">
                                                        <ul className="list-group list-group-flush">
                                                            <li className="list-group-item">
                                                                <span className="text-blue font-weight-bold mr-3"><i className="fa fa-tasks" /> Title</span>
                                                                {workOrder.title}
                                                            </li>
                                                            {workOrder.violation && (
                                                                <li className="list-group-item">
                                                                    <span className="text-blue font-weight-bold mr-3"><i className="fa fa-ticket" /> {workOrder.violation.uniqueData.label}</span>
                                                                    <Link to={{
                                                                        pathname: `/violation-details/${workOrder.violationId}/${workOrder.violation.bin}/${workOrder.violation.category}/${workOrder.violation.sub}`,
                                                                        state: { from: location }
                                                                    }}>
                                                                        {workOrder.violation.uniqueData.value}
                                                                    </Link>
                                                                </li>
                                                            )}
                                                            <li className="list-group-item" style={{ whiteSpace: 'pre-wrap' }}>
                                                                <span className="text-blue font-weight-bold mr-3"><i className="fa fa-book" /> Description</span><br />
                                                                {workOrder.description}
                                                            </li>
                                                        </ul>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-12 col-md-4">
                                                <FieldWidget name="Category" value={workOrder.category} faIcon="book" />
                                            </div>
                                            {workOrder.source !== 'appWork' && <div className="col-12 col-md-4">
                                                <FieldWidget name="Due Date" value={workOrder.dueDate && moment(workOrder.dueDate).isValid() && moment.utc(workOrder.dueDate).format('MM/DD/YYYY')} faIcon="calendar" />
                                            </div>}
                                            <div className="col-12 col-md-4">
                                                <FieldWidget name="Priority" value={workOrder.priority} faIcon="list" />
                                            </div>
                                            <div className="col-12 col-md-4">
                                                <FieldWidget name="Status" value={workOrder.status} faIcon="star" />
                                            </div>
                                            {workOrder.additionalData?.vendor && <div className="col-12 col-md-4">
                                                <FieldWidget name="Vendor" value={workOrder.additionalData?.vendor?.fullName} faIcon="user" />
                                            </div>}
                                            {workOrder.assignedUsersInfo && workOrder.source !== 'appWork' && <div className="col-12 col-md-4">
                                                <FieldWidget name="Assignee" value={workOrder.assignedUsersInfo.map((user) => user.fullName).join(', ')} faIcon="user-md" />
                                            </div>}
                                            {workOrder.estimatedTime && <div className="col-12 col-md-4">
                                                <FieldWidget name="Estimated Time" value={workOrder.estimatedTime} faIcon="clock-o" />
                                            </div>}
                                            {workOrder.recurring && <div className="col-12 col-md-4">
                                                <FieldWidget name="Recurring Frequency" value={`${workOrder.recurring || '-'} ${workOrder.recurring ? workOrder.recurring === '1' ? 'time' : 'times' : ''} ${workOrder.recurringFrequency ? `in a ${workOrder.recurringFrequency}` : ''}`} faIcon="repeat" />
                                            </div>}
                                        </div>
                                        <h3 className="font-weight-bold">Activity</h3>
                                        <div className="card">
                                            <div className="card-body p-0">
                                                <div className="table-responsive">
                                                    <Activity workOrderId={params.id} />
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="col-12 col-md-5">
                                        {!['visitt', 'appWork'].includes(workOrder.source) &&
                                            <>
                                                <h3 className="font-weight-bold">Uploads</h3>
                                                <div className="card">
                                                    <div className="card-body p-0">
                                                        <Documents workOrder={workOrder} id={params.id} getWorkOrder={getWorkOrder} handleUrlClick={handleUrlClick} />
                                                    </div>
                                                </div>
                                            </>
                                        }
                                        <h3 className="font-weight-bold">Chat</h3>
                                        <div className="card">
                                            <div className="card-body p-3">
                                                <Chat workOrderId={params.id} bin={workOrder.bin} />
                                            </div>
                                        </div>
                                    </div>

                                </div>
                            </>
                        }
                    </div>
                </div>
            </div>
    )
}

export default WorkOrderDetails;
