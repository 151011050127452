import React, { useEffect } from 'react';
import ReactDOM from 'react-dom';
import 'antd/dist/antd.min.css';
import './index_sass.css';
import 'react-date-range/dist/styles.css';
import 'react-date-range/dist/theme/default.css';
import 'react-bootstrap-typeahead/css/Typeahead.css';

import Bugsnag from '@bugsnag/js'
import BugsnagPluginReact from '@bugsnag/plugin-react'

import { BrowserRouter, Route, Switch } from 'react-router-dom';
import * as serviceWorker from './serviceWorker';
import { getCurrentUser } from './components/services/authService'

// ** Import custom components for redux**
import { Provider } from 'react-redux';
import store from './store/index';
import App from "./components/app";

// sample page
import BuildingsEdit from "./components/pages/editBuildings/buildingsEdit";
import EnrollForm from "./components/none-loggedin/enroll";
import ThankYou from "./components/none-loggedin/thankYou";
import Login from "./components/none-loggedin/login";
import ProtectedRoute from "./components/common/protectedRoute";
import ResetPassword from "./components/none-loggedin/resetPassword";
import ForgotPassword from "./components/none-loggedin/forgotPassword";
import Dashboard from "./components/pages/dashboard/dashboardComponent";
import Buildings from "./components/pages/buildings/buildingsComponent";
import ViolationsAll from "./components/pages/buildings/violationsAllComponent";
import ViolationsTable from "./components/pages/buildings/violationsTableComponent";
import ViolationsTableRetained from "./components/pages/buildings/violationsTableComponentRetained";
import CateogryWiseViolations from "./components/pages/buildings/categoryWiseViolations";
import ViolationsDetails from "./components/pages/buildings/violationDetails/index";
import Calendar from "./components/pages/calendar/calendar";
import Compliance from './components/pages/requirements';
import CustomCompliance from './components/pages/customCompliance'
import CustomComplianceDashboard from './components/pages/customCompliance/dashboard'
import CustomComplianceGuides from './components/pages/customCompliance/guides'
import CustomComplianceDetails from './components/pages/customCompliance/details'
import DashboardDetails from "./components/pages/dashboard/dashboardDetails";
import ViolationsTab from './components/pages/violations/violationsTab';
import ViolationsTabDetails from './components/pages/violations/violationsTabDetails';
import Permits from "./components/pages/permits/permitsTab";
import PermitsDetails from "./components/pages/permits/permitsDetails";
import PermitsDesign from "./components/pages/permits/permitsDesign";
import LoginAs from "./components/none-loggedin/login-as";
import UploadsAndNotes from "./components/pages/uploadsAndNotes/uploadsAndNotes";
import Preferences from './components/pages/preferences';
import Reports from './components/pages/reports';
import ReportDetail from './components/pages/reports/reportDetails';
import CreateReport from './components/pages/reports/createReport';
import Chat from './components/pages/buildings/chat/chatComponent';
import WorkOrdersDashboard from './components/pages/workOrders/dashboard';
import WorkOrders from './components/pages/workOrders/index';
import WorkOrderDetails from './components/pages/workOrders/detail/index';
import WorkOrderCreate from './components/pages/workOrders/create';
import WorkOrderCreateVisitt from './components/pages/workOrders/create.visitt';
import WorkOrderCreateAppWork from './components/pages/workOrders/create.appWork';

import VendorsForm from './components/pages/vendors/form';
import Vendors from './components/pages/vendors/index';
import StaffCreate from './components/pages/workOrders/staff/create';
import SubUsers from './components/pages/settings/subUsers';
import ExcludedRespondents from './components/pages/settings/excludedRespondents';
import UserOptions from './components/pages/settings/userOptions';
import Integration from './components/pages/settings/Integration';
import RemoveMobile from './components/pages/settings/removeMobile';
import Updates from './components/pages/settings/updates';
import WorkOrderPrint from './components/pages/workOrders/print/index';
import SafetyMailingsAutoRules from './components/pages/safetyMailings/rules/index';
import SafetyMailingsAutoRulesCreate from './components/pages/safetyMailings/rules/create';
import SafetyMailingsAutoRulesEdit from './components/pages/safetyMailings/rules/edit';
import SafetyMailingDashboard from './components/pages/safetyMailings/dashboard';
import SafetyMailingsEmail from './components/pages/safetyMailings/email';
import Portfolio from './components/pages/portfolio/index';
import PortfolioForm from './components/pages/portfolio/form';
import SafetyMailingsForm from './components/pages/safetyMailings/form'

import LocalState from './components/common/localState';
import TenantsList from './components/pages/tenants';
import TenantCreate from './components/pages/tenants/create';
import TenantEdit from './components/pages/tenants/edit';
import ImportTenants from './components/pages/tenants/import';

import FireSafetyList from './components/pages/fireSafety';
import FireSafetyCreate from './components/pages/fireSafety/create';
import FireSafetyEdit from './components/pages/fireSafety/edit';
import CopyTenants from './components/pages/tenants/copyFrom';

import SafetyMailings from './components/pages/safetyMailings';
import SafetyMailingCreate from './components/pages/safetyMailings/create';
import SafetyMailingView from './components/pages/safetyMailings/view';

import ScrollToTop from './components/common/scrollToTop'

Bugsnag.start({
    apiKey: '5cd87f317da7bb065de6df96b15bf993',
    plugins: [new BugsnagPluginReact()],
    onError: function (event) {
        const currentUser = getCurrentUser();
        if (currentUser) {
            event.addMetadata('User', {
                ...currentUser,
            });
        }
    }
})

const ErrorBoundary = Bugsnag.getPlugin('react')
    .createErrorBoundary(React)

//firebase Auth
function Root() {
    useEffect(() => {
        LocalState.setItem('swithBtn', true);
        const layout = localStorage.getItem('layout_version')
        document.body.classList.add(layout);
    }, []);

    return (
        <ErrorBoundary>
            <div className="App">
                <Provider store={store}>
                    <BrowserRouter basename={'/'}>
                        <ScrollToTop />
                        <Switch>
                            {/* Public Routes */}
                            <Route path={`${process.env.PUBLIC_URL}/safety-mailings-form/:id`} component={SafetyMailingsForm} />
                            <Route path={`${process.env.PUBLIC_URL}/login`} component={Login} />
                            <Route path={`${process.env.PUBLIC_URL}/login-as/:token`} component={LoginAs} />
                            <Route path={`${process.env.PUBLIC_URL}/enroll`} component={EnrollForm} />
                            <Route path={`${process.env.PUBLIC_URL}/reset-password/:resetKey/:expire`} component={ResetPassword} />
                            <Route path={`${process.env.PUBLIC_URL}/forgot-password`} component={ForgotPassword} />
                            <Route path={`${process.env.PUBLIC_URL}/thank-you`} component={ThankYou} />

                            {/* Protected Routes */}
                            <ProtectedRoute exact path={`${process.env.PUBLIC_URL}/`} component={Dashboard} layout={App} serviceName="violations" action="all" />

                            <ProtectedRoute exact path={`${process.env.PUBLIC_URL}/dashboard`} component={Dashboard} layout={App} serviceName="violations" action="all" />
                            <ProtectedRoute exact path={`${process.env.PUBLIC_URL}/dashboard/:category`} component={Dashboard} layout={App} serviceName="violations" action="all" />
                            <ProtectedRoute exact path={`${process.env.PUBLIC_URL}/dashboard/:category/:sub`} component={DashboardDetails} layout={App} serviceName="violations" action="all" />

                            <ProtectedRoute exact path={`${process.env.PUBLIC_URL}/calendar`} component={Calendar} layout={App} serviceName="violations" action="all" />


                            <ProtectedRoute path={`${process.env.PUBLIC_URL}/my-buildings`} component={Buildings} layout={App} serviceName="violations" action="all" />
                            <ProtectedRoute path={`${process.env.PUBLIC_URL}/violations/all/:id`} component={ViolationsAll} layout={App} serviceName="violations" action="all" />
                            <ProtectedRoute path={`${process.env.PUBLIC_URL}/violations/table/:id/:category/:sub/:sortBy?/:sortType?`} component={ViolationsTable} layout={App} serviceName="violations" action="all" />
                            <ProtectedRoute path={`${process.env.PUBLIC_URL}/violations/table-retained/:id/:category/:sub/:sortBy?/:sortType?`} component={ViolationsTableRetained} layout={App} serviceName="violations" action="all" />
                            <ProtectedRoute path={`${process.env.PUBLIC_URL}/violations/table-colors/:id/:category/:sub/:sortBy?/:sortType?`} component={ViolationsTableRetained} layout={App} serviceName="violations" action="all" />
                            <ProtectedRoute path={`${process.env.PUBLIC_URL}/violation-details/:id/:tabName?`} component={ViolationsDetails} layout={App} serviceName="violations" action="all" />
                            <ProtectedRoute path={`${process.env.PUBLIC_URL}/violation-history-details/:id`} component={ViolationsDetails} layout={App} serviceName="violations" action="all" />

                            <ProtectedRoute exact path={`${process.env.PUBLIC_URL}/compliance-requirements`} component={Compliance} layout={App} serviceName="violations" action="all" />
                            <ProtectedRoute exact path={`${process.env.PUBLIC_URL}/compliance-requirements/:category`} component={Compliance} layout={App} serviceName="violations" action="all" />

                            <ProtectedRoute exact path={`${process.env.PUBLIC_URL}/custom-compliance`} component={CustomCompliance} layout={App} serviceName="violations" action="all" />
                            <ProtectedRoute exact path={`${process.env.PUBLIC_URL}/custom-compliance/guides`} component={CustomComplianceGuides} layout={App} serviceName="violations" action="all" />
                            <ProtectedRoute exact path={`${process.env.PUBLIC_URL}/custom-compliance/dashboard`} component={CustomComplianceDashboard} layout={App} serviceName="violations" action="all" />
                            <ProtectedRoute exact path={`${process.env.PUBLIC_URL}/custom-compliance/:category`} component={CustomCompliance} layout={App} serviceName="violations" action="all" />
                            <ProtectedRoute exact path={`${process.env.PUBLIC_URL}/custom-compliance/:sub/:id/:tabName?`} component={CustomComplianceDetails} layout={App} serviceName="violations" action="all" />
                            <ProtectedRoute path={`${process.env.PUBLIC_URL}/violations-tab`} component={ViolationsTab} layout={App} />
                            <ProtectedRoute path={`${process.env.PUBLIC_URL}/violations-tab-details/:category/:sub/:sortBy?/:sortType?`} component={ViolationsTabDetails} layout={App} />

                            <ProtectedRoute path={`${process.env.PUBLIC_URL}/permits`} component={Permits} layout={App} serviceName="violations" action="all" />
                            <ProtectedRoute path={`${process.env.PUBLIC_URL}/permits-details/:category/:sub`} component={PermitsDetails} layout={App} serviceName="violations" action="all" />
                            <ProtectedRoute path={`${process.env.PUBLIC_URL}/permits-design`} component={PermitsDesign} layout={App} serviceName="violations" action="all" />

                            <ProtectedRoute path={`${process.env.PUBLIC_URL}/BuildingsEdit`} component={BuildingsEdit} layout={App} serviceName="violations" action="all" />

                            <ProtectedRoute path={`${process.env.PUBLIC_URL}/uploads-and-notes`} component={UploadsAndNotes} layout={App} serviceName="violations" action="all" />
                            <ProtectedRoute path={`${process.env.PUBLIC_URL}/preferences`} component={Preferences} layout={App} />
                            <ProtectedRoute path={`${process.env.PUBLIC_URL}/reports/cancel/:id`} component={ReportDetail} layout={App} serviceName="violations" action="all" />
                            <ProtectedRoute path={`${process.env.PUBLIC_URL}/reports`} component={Reports} layout={App} serviceName="violations" action="all" />
                            <ProtectedRoute path={`${process.env.PUBLIC_URL}/create-report`} component={CreateReport} layout={App} serviceName="violations" action="all" />

                            <ProtectedRoute path={`${process.env.PUBLIC_URL}/chats`} component={Chat} layout={App} />

                            <ProtectedRoute exact path={`${process.env.PUBLIC_URL}/work-orders`} serviceName="workOrders" action={["list", "listSub", "listBin"]} component={WorkOrdersDashboard} layout={App} />
                            <ProtectedRoute exact path={`${process.env.PUBLIC_URL}/work-orders/list`} serviceName="workOrders" action={["list", "listSub", "listBin"]} component={WorkOrders} layout={App} />
                            <ProtectedRoute exact path={`${process.env.PUBLIC_URL}/work-order/create-by-violation/:violationId/:bin`} component={WorkOrderCreate} layout={App} />
                            <ProtectedRoute exact path={`${process.env.PUBLIC_URL}/work-order/create-by-violation-visitt/:violationId/:bin`} component={WorkOrderCreateVisitt} layout={App} />
                            <ProtectedRoute exact path={`${process.env.PUBLIC_URL}/work-order/create-by-violation-appWork/:violationId/:bin`} component={WorkOrderCreateAppWork} layout={App} />

                            <ProtectedRoute exact path={`${process.env.PUBLIC_URL}/work-order/create-by-safety-mailing-printed/:safetyMailingPrintedResponseId/:bin`} component={WorkOrderCreate} layout={App} />
                            <ProtectedRoute exact path={`${process.env.PUBLIC_URL}/work-orders/create`} serviceName="workOrders" action="create" component={WorkOrderCreate} layout={App} />
                            <ProtectedRoute exact path={`${process.env.PUBLIC_URL}/work-orders/create-visitt`} serviceName="workOrders" action="create" component={WorkOrderCreateVisitt} layout={App} />
                            <ProtectedRoute exact path={`${process.env.PUBLIC_URL}/work-orders/create-appWork`} serviceName="workOrders" action="create" component={WorkOrderCreateAppWork} layout={App} />
                            <ProtectedRoute exact path={`${process.env.PUBLIC_URL}/work-orders/view/:id/:tabName?`} serviceName="workOrders" action="view" component={WorkOrderDetails} layout={App} />
                            <ProtectedRoute exact path={`${process.env.PUBLIC_URL}/work-orders/edit/:id`} component={WorkOrderCreate} serviceName="workOrders" action={["edit", "partial_edit"]} layout={App} />
                            <ProtectedRoute exact path={`${process.env.PUBLIC_URL}/work-orders/print/:id`} component={WorkOrderPrint} />

                            <ProtectedRoute exact path={`${process.env.PUBLIC_URL}/vendors`} serviceName="violations" action={["list_vendors", 'create_vendor']} component={Vendors} layout={App} />
                            <ProtectedRoute exact path={`${process.env.PUBLIC_URL}/vendors/create`} serviceName="violations" action="create_vendor" component={VendorsForm} layout={App} />
                            <ProtectedRoute exact path={`${process.env.PUBLIC_URL}/vendors/edit/:id`} serviceName="violations" action="edit_vendor" component={VendorsForm} layout={App} />

                            <ProtectedRoute exact path={`${process.env.PUBLIC_URL}/staff/create`} component={StaffCreate} layout={App} masterUserOnly />
                            <ProtectedRoute exact path={`${process.env.PUBLIC_URL}/staff/edit/:id`} component={StaffCreate} layout={App} masterUserOnly />

                            <ProtectedRoute path={`${process.env.PUBLIC_URL}/settings/sub-users`} component={SubUsers} layout={App} masterUserOnly />
                            <ProtectedRoute path={`${process.env.PUBLIC_URL}/settings/user-options`} component={UserOptions} layout={App} />
                            <ProtectedRoute path={`${process.env.PUBLIC_URL}/settings/api-integration`} component={Integration} layout={App} />
                            <ProtectedRoute path={`${process.env.PUBLIC_URL}/settings/remove-mobile`} component={RemoveMobile} layout={App} />
                            <ProtectedRoute path={`${process.env.PUBLIC_URL}/settings/excluded-respondents`} component={ExcludedRespondents} layout={App} />

                            <ProtectedRoute exact path={`${process.env.PUBLIC_URL}/updates`} component={Updates} layout={App} />

                            <ProtectedRoute exact path={`${process.env.PUBLIC_URL}/safety-mailings`} component={SafetyMailings} layout={App} />
                            <ProtectedRoute exact path={`${process.env.PUBLIC_URL}/safety-mailings/email-temlate`} component={SafetyMailingsEmail} layout={App} />
                            <ProtectedRoute exact path={`${process.env.PUBLIC_URL}/safety-mailings/dashboard`} component={SafetyMailingDashboard} layout={App} />
                            <ProtectedRoute exact path={`${process.env.PUBLIC_URL}/safety-mailings/dashboard/:year`} component={SafetyMailingDashboard} layout={App} />
                            <ProtectedRoute exact path={`${process.env.PUBLIC_URL}/safety-mailings/create`} component={SafetyMailingCreate} layout={App} />
                            <ProtectedRoute exact path={`${process.env.PUBLIC_URL}/safety-mailings/view/:id/:bin?/:tabActive?/:subMenuActive?`} component={SafetyMailingView} layout={App} />
                            <ProtectedRoute exact path={`${process.env.PUBLIC_URL}/safety-mailings/auto-rules`} component={SafetyMailingsAutoRules} layout={App} />
                            <ProtectedRoute exact path={`${process.env.PUBLIC_URL}/safety-mailings/auto-rules/create`} component={SafetyMailingsAutoRulesCreate} layout={App} />
                            <ProtectedRoute exact path={`${process.env.PUBLIC_URL}/safety-mailings/auto-rules/edit/:id`} component={SafetyMailingsAutoRulesEdit} layout={App} />

                            <ProtectedRoute path={`${process.env.PUBLIC_URL}/tenants/list/:year?`} component={TenantsList} layout={App} />
                            <ProtectedRoute path={`${process.env.PUBLIC_URL}/tenants/create/:year?`} component={TenantCreate} layout={App} />
                            <ProtectedRoute path={`${process.env.PUBLIC_URL}/tenants/edit/:id`} component={TenantEdit} layout={App} />
                            <ProtectedRoute path={`${process.env.PUBLIC_URL}/tenants/import/:year?`} component={ImportTenants} layout={App} />
                            <ProtectedRoute path={`${process.env.PUBLIC_URL}/tenants/copy-from/:year?`} component={CopyTenants} layout={App} />
                            
                            <ProtectedRoute path={`${process.env.PUBLIC_URL}/fire-safety/list`} component={FireSafetyList} layout={App} />
                            <ProtectedRoute path={`${process.env.PUBLIC_URL}/fire-safety/create`} component={FireSafetyCreate} layout={App} />
                            <ProtectedRoute path={`${process.env.PUBLIC_URL}/fire-safety/edit/:id`} component={FireSafetyEdit} layout={App} />

                            <ProtectedRoute exact path={`${process.env.PUBLIC_URL}/portfolio`} component={Portfolio} layout={App} masterUserOnly />
                            <ProtectedRoute exact path={`${process.env.PUBLIC_URL}/portfolio/create`} component={PortfolioForm} layout={App} masterUserOnly />
                            <ProtectedRoute exact path={`${process.env.PUBLIC_URL}/portfolio/edit/:id`} component={PortfolioForm} layout={App} masterUserOnly />
                            <ProtectedRoute exact path={`${process.env.PUBLIC_URL}/violations/category/:id/:category/:sub`} component={CateogryWiseViolations} layout={App} serviceName="violations" action="all" />

                        </Switch>
                    </BrowserRouter>
                </Provider>
            </div>
        </ErrorBoundary>
    );
}

ReactDOM.render(<Root />, document.getElementById('root'));

serviceWorker.unregister();
