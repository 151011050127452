import React, { useState, useEffect } from 'react';
import { Checkbox, Table, Button, Typography, Modal, Input, Collapse } from 'antd';
import logoVisitt from '../../../assets/images/visitt-logo-main.svg';
import appWork from '../../../assets/images/appWork.svg';

import moment from 'moment';

import creService from '../../services/creService';
import { toast } from 'react-toastify';
import {
    PlusOutlined,
    DeleteOutlined,

} from '@ant-design/icons';
import './Integration.css'
import Breadcrumb from '../../common/breadcrumb';
import VisttModal from './visttModal'
import AppWorkModal from './appWorkModal.js'
import guard from '../../common/guard';
import { getJwt } from '../../services/authService.js'
const { Paragraph } = Typography;
const { Panel } = Collapse;

const Integration = () => {

    const [keysHide, setKeysHide] = useState({})
    const [dataSource, setDataSource] = useState([])
    const [isCreateModalOpen, setIsCreateModalOpen] = useState(false)
    const [isRemoveModalOpen, setIsRemoveModalOpen] = useState(false)
    const [deleteKey, setDeleteKey] = useState('')
    const [vissitCurrentKey, setVissitCurrentKey] = useState(null)
    const [visittBuildings, setVisittBuildings] = useState({})
    const [appWorkCurrentKey, setAppWorkCurrentKey] = useState(null)
    const [appWorkBuildings, setAppWorkBuildings] = useState({})

    const [labelName, setLabelName] = useState('')
    const [partnersConfig, setPartnersConfig] = useState({
        visitt: {
            enabled: null,
            keys: []
        },
        bcompliantWorkOrderDisable: {
            enabled: null,
        }
    })


    useEffect(() => {
        getApiKeys()
        getPartnerConfiguration()
    }, [])

    useEffect(() => {
        if (partnersConfig.visitt?.enabled) {
            getVisittBuildingPerKey()
        }
        if (partnersConfig.appWork?.enabled) {
            getAppWorkBuildingPerKey()
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [partnersConfig])

    const getVisittBuildingPerKey = async () => {
        const endData = {}
        for (let key of partnersConfig.visitt.keys) {

            if (key.valid) {
                const id = key._id
                try {
                    const result = await creService.getVisittBuilding(id)
                    if (result.data.status) {
                        endData[id] = result.data.data

                    } else {
                        toast.error(result.data.message)
                    }
                } catch (err) {
                    toast.error(err.message)
                }

            }
        }

        setVisittBuildings(endData)
    }

    const getAppWorkBuildingPerKey = async () => {
        const endData = {}
        for (let key of partnersConfig.appWork.keys) {

            if (key.valid) {
                const id = key._id
                try {
                    const result = await creService.getAppWorkBuilding(id)
                    if (result.data.status) {
                        endData[id] = result.data.data

                    } else {
                        toast.error(result.data.message)
                    }
                } catch (err) {
                    toast.error(err.message)
                }

            }
        }

        setAppWorkBuildings(endData)
    }

    const setVisittBuilding = (newBuidlings, _id) => {
        setVisittBuildings({ ...visittBuildings, [_id]: newBuidlings })
    }
    const setAppWorkBuilding = (newBuidlings, _id) => {
        setAppWorkBuildings({ ...appWorkBuildings, [_id]: newBuidlings })
    }



    const getApiKeys = async () => {
        try {
            const result = await creService.getApiKeys()
            if (result.data.status) {
                if (result.data.data) {
                    setDataSource(result.data.data.map(el => ({
                        key: el._id,
                        apiKey: el.value,
                        date: moment(new Date(el.addedAt), "YYYYMMDD").fromNow(),
                        name: el.name,
                    })))
                }
            } else {
                toast.error(result.data.message)
            }
        } catch (err) {
            toast.error(err.message)
        }

    }

    const getPartnerConfiguration = async () => {
        try {
            const result = await creService.getPartnerConfiguration()
            if (result.data.status) {
                if (result.data.data) {
                    setPartnersConfig(result.data.data)
                }
            } else {
                toast.error(result.data.message)
            }
        } catch (err) {
            toast.error(err.message)
        }

    }



    const cancleCreateHandler = () => {
        setLabelName('')
        setIsCreateModalOpen(false)
    }

    const okCreateHandler = async () => {
        if (labelName) {
            try {
                const result = await creService.createApiKey(labelName)
                if (result.data.status) {

                    setDataSource(result.data.data.map(el => ({
                        key: el._id,
                        apiKey: el.value,
                        date: moment(new Date(el.addedAt), "YYYYMMDD").fromNow(),
                        name: el.name,
                    })))
                    cancleCreateHandler()
                } else {
                    toast.error(result.data.message)
                }
            } catch (err) {
                toast.error(err.message)
            }
        } else {
            toast.warn('Label can not be empty')
        }
    }
    const deleteModalOpenHandler = (key) => {
        setDeleteKey(key)
        setIsRemoveModalOpen(true)
    }

    const cancleDeleteHandler = () => {
        setDeleteKey('')
        setIsRemoveModalOpen(false)
    }

    const okDeleteHandler = async () => {
        if (deleteKey) {
            try {
                const result = await creService.removeApiKey(deleteKey)
                if (result.data.status) {

                    setDataSource(dataSource.filter(el => el.key !== deleteKey))
                    cancleDeleteHandler()
                    toast.success('Key deleted')
                } else {
                    toast.error(result.data.message)
                }
            } catch (err) {
                toast.error(err.message)
            }
        } else {
            toast.warn('Key can not be empty')
        }
    }

    const toggleVisibility = (key) => {

        setKeysHide({
            ...keysHide,
            [key]: !keysHide[key]
        })
    }

    const columns = [
        {
            title: 'Label',
            dataIndex: 'name',
            key: 'name',
        },
        {
            title: 'Api Key',
            dataIndex: 'apiKey',
            key: 'apiKey',
            width: '350px',
            render: (text, record) => <div className='d-flex justify-content-between align-items-center'>
                <span className='mr-1 width-200 overflow-hidden'>{!keysHide[record.key] ?
                    '●●●●●●●●●●●●' :
                    <Paragraph ellipsis={true} className='width-200 mb-0' copyable={{ tooltips: false }} >{text}</Paragraph>}
                </span>
                <Button
                    type="dashed"
                    shape="circle"
                    icon={<i className={`fa ${!keysHide[record.key] ? 'fa-eye' : 'fa-eye-slash'}`} aria-hidden="true"></i>}
                    size={'large'}
                    onClick={() => toggleVisibility(record.key)}
                />
            </div>
        },
        {
            title: 'Date Created',
            dataIndex: 'date',
            key: 'date',
        },
        {
            title: 'Action',
            render: (text, record) =>
                <>
                    <Button onClick={() => deleteModalOpenHandler(record.key)} type="primary" shape="round" icon={<DeleteOutlined />} danger>
                        Delete
                    </Button>
                </>,
            width: '100px',
        }
    ];

    const toggleEnabledHandler = async (key) => {
        const newValue = !partnersConfig[key]?.enabled

        try {
            const result = await creService.setPartnerConfigurationEnable(key, newValue)
            if (result.data.status) {

                setPartnersConfig({ ...partnersConfig, [key]: { ...partnersConfig[key], enabled: newValue, keys: [] } })

            } else {
                toast.error(result.data.message)
            }
        } catch (err) {
            toast.error(err.message)
        }
    }

    const toggleBcompliantWorkOrderEnabledHandler = async () => {
        const newValue = !partnersConfig.bcompliantWorkOrderDisable?.enabled

        try {
            const result = await creService.setPartnerConfigurationEnable('bcompliantWorkOrderDisable', newValue)
            if (result.data.status) {
                setPartnersConfig({ ...partnersConfig, bcompliantWorkOrderDisable: { enabled: newValue } })
            } else {
                toast.error(result.data.message)
            }
        } catch (err) {
            toast.error(err.message)
        }
    }

    const toggleAppWorkBeta = async () => {
        const newValue = !partnersConfig.betaAppWork?.enabled

        try {
            const result = await creService.setPartnerConfigurationEnable('betaAppWork', newValue)
            if (result.data.status) {
                setPartnersConfig({ ...partnersConfig, betaAppWork: { enabled: newValue } })
            } else {
                toast.error(result.data.message)
            }
        } catch (err) {
            toast.error(err.message)
        }
    }

    const setIntegrationKey = async (type, id, key, remove) => {

        try {
            const result = await creService.setIntegrationKey(type, id, key, remove)
            if (result.data.status) {
                if (result.data.errMessage) {
                    toast.error(result.data.errMessage)
                }
                setPartnersConfig(result.data.data)
            } else {
                toast.error(result.data.message)
            }
        } catch (err) {
            toast.error(err.message)
        }
    }

    const setKey = (type, key, id, part) => {

        const newKeys = partnersConfig[type].keys.map(el => {
            if (el._id === id) {

                const newObj = { ...el, key }
                if (part !== undefined) {

                    newObj[`key${part}`] = key.replaceAll(':', '')
                    newObj.key = (newObj.key0 || '') + ':' + (newObj.key1 || '')
                }

                return newObj
            }
            return el

        })

        setPartnersConfig({ ...partnersConfig, [type]: { ...partnersConfig[type], keys: newKeys } })
    }

    const openCreateModalOpen = () => {

        if (guard({ serviceName: 'Integrations', action: ['partner', 'Bcompliant'] })) {
            setIsCreateModalOpen(true)
        } else {
            toast.warn('Please contact support to Enable API Integration')
        }

    }


    return (
        <>
            <Breadcrumb title="API Integration" parent="Home" />
            <div className="container-fluid overflow-auto">
                <div className='d-flex justify-content-between mb-2 p-2 '>
                    <h4><strong>Active BCompliant API Keys</strong></h4>
                    <div>
                        <Button type="primary" icon={<PlusOutlined />} onClick={openCreateModalOpen}>
                            CREATE API KEY
                        </Button>
                        <Button className='ml-2' onClick={() => {



                            window.open(`/api/api-docs?x-auth-cre-token=${getJwt()}${dataSource.length ?
                                '&api-key=' + dataSource[0].apiKey :
                                ''}
                             `, "_blank")
                        }
                        }>
                            CHECK API DOCS
                        </Button>
                    </div>
                </div>
                <Table dataSource={dataSource} columns={columns} pagination={false} />
            </div>
            <div id='partners' className='mt-4'>
                <div className="card" >
                    <div className="card-header p-3" >
                        <h5>
                            Partners
                        </h5>

                    </div>
                </div>
                <Collapse accordion defaultActiveKey={['1', '2']}>
                    <Panel key='1' header={<img src={logoVisitt} height='25px' alt='visitt' className='mt-2 mb-2' />} >

                        <div className='d-flex  flex-column '>
                            <div>
                                <Checkbox className='mb-2' checked={partnersConfig.visitt.enabled} onChange={() => toggleEnabledHandler('visitt')}>
                                    Enable
                                </Checkbox>
                                {partnersConfig.visitt.enabled &&
                                    <Checkbox className='mb-2' checked={partnersConfig.bcompliantWorkOrderDisable?.enabled} onChange={toggleBcompliantWorkOrderEnabledHandler}>
                                        Disable Bcompliant orders
                                    </Checkbox>
                                }
                            </div>

                            {
                                partnersConfig.visitt.enabled &&
                                <>
                                    <div className='mb-2'>

                                        {partnersConfig.visitt.keys.map((obj, i) =>
                                            <div className='d-flex mb-3 align-items-end flex-wrap' key={i}>
                                                <div className='mr-4 mt-1'>
                                                    <Input.Group compact className='align-items-center d-flex'>
                                                        <DeleteOutlined className='mr-2' onClick={() => setIntegrationKey('visitt', obj._id, obj.key, true)} />
                                                        <Input
                                                            placeholder='Enter "Visitt" API key here...'
                                                            className={`api-key-input ${obj.valid ? 'valid' : obj.valid === false ? 'invalid' : ''}`}
                                                            value={obj.key} onChange={(event) => setKey('visitt', event.target.value, obj._id)}
                                                        />
                                                        <Button type="primary" onClick={() => setIntegrationKey('visitt', obj._id, obj.key)}>Set API key</Button>

                                                    </Input.Group>

                                                </div>
                                                {obj.valid &&
                                                    <div className='d-flex flex-column justify-content-center align-items-center'>

                                                        <span className='badge ml-2 mt-1'>
                                                            {visittBuildings[obj._id]?.filter(el => el.bcompliantBuilding).length || 0} out of {visittBuildings[obj._id]?.length || '..'} matched
                                                        </span>

                                                        <Button onClick={() => setVissitCurrentKey(obj)} type="primary" className='mt-1'>Validate Buildings</Button>
                                                    </div>
                                                }
                                            </div>
                                        )}



                                    </div>
                                    <div>
                                        <span className='pointer' onClick={() => setIntegrationKey('visitt')}><PlusOutlined /> Add Key</span>
                                    </div>
                                </>
                            }


                        </div>
                        <VisttModal
                            vissitCurrentKey={vissitCurrentKey}
                            cancleVisittHandler={() => setVissitCurrentKey(null)}
                            setVisittBuilding={setVisittBuilding}
                            buildings={visittBuildings[vissitCurrentKey?._id] || []}
                        />
                    </Panel>
                    <Panel key='2' className='appWork-panel' style={{ background: '#04333B' }} header={<img src={appWork} height='40px' alt='appWork' />} >

                        <div className='d-flex  flex-column '>
                            <div>
                                <Checkbox className='mb-2' checked={partnersConfig.appWork?.enabled} onChange={() => toggleEnabledHandler('appWork')}>
                                    Enable
                                </Checkbox>
                                {partnersConfig.appWork?.enabled &&
                                    <>
                                        <Checkbox className='mb-2' checked={partnersConfig.bcompliantWorkOrderDisable?.enabled} onChange={toggleBcompliantWorkOrderEnabledHandler}>
                                            Disable Bcompliant orders
                                        </Checkbox>
                                        <Checkbox className='mb-2' checked={partnersConfig.betaAppWork?.enabled} onChange={toggleAppWorkBeta}>
                                            Beta
                                        </Checkbox>
                                    </>
                                }
                            </div>

                            {
                                partnersConfig.appWork?.enabled &&
                                <>
                                    <div className='mb-2'>

                                        {partnersConfig.appWork.keys.map((obj, i) =>
                                            <div className='d-flex mb-3 align-items-end flex-wrap' key={i}>
                                                <div className='mr-4 mt-1'>
                                                    <Input.Group compact className='align-items-center d-flex flex-wrap appWork-keys'>
                                                        <DeleteOutlined className='mr-2' onClick={() => setIntegrationKey('appWork', obj._id, obj.key, true)} />
                                                        <Input
                                                            placeholder='Enter "x-api-token" here...'
                                                            className={`api-key-input ${obj.valid ? 'valid' : obj.valid === false ? 'invalid' : ''}`}
                                                            value={obj.key0} onChange={(event) => setKey('appWork', event.target.value, obj._id, 0)}
                                                        />
                                                        <Input
                                                            placeholder='Enter "x-client-token" here...'
                                                            className={`api-key-input ${obj.valid ? 'valid' : obj.valid === false ? 'invalid' : ''}`}
                                                            value={obj.key1} onChange={(event) => setKey('appWork', event.target.value, obj._id, 1)}
                                                        />
                                                        <Button type="primary" onClick={() => setIntegrationKey('appWork', obj._id, obj.key)}>Set API key</Button>

                                                    </Input.Group>

                                                </div>
                                                {obj.valid &&
                                                    <div className='d-flex flex-column justify-content-center align-items-center'>

                                                        <span className='badge ml-2 mt-1'>
                                                            {appWorkBuildings[obj._id]?.filter(el => el.bcompliantBuilding).length || 0} out of {appWorkBuildings[obj._id]?.length || '..'} matched
                                                        </span>

                                                        <Button onClick={() => setAppWorkCurrentKey(obj)} type="primary" className='mt-1'>Validate Buildings</Button>
                                                    </div>
                                                }
                                            </div>
                                        )}



                                    </div>
                                    {(partnersConfig.appWork?.keys?.length || 0) < 1 ?
                                        <div>
                                            <span className='pointer' onClick={() => setIntegrationKey('appWork')}><PlusOutlined /> Add Key</span>
                                        </div> : null
                                    }
                                </>
                            }


                        </div>
                        <VisttModal
                            vissitCurrentKey={vissitCurrentKey}
                            cancleVisittHandler={() => setVissitCurrentKey(null)}
                            setVisittBuilding={setVisittBuilding}
                            buildings={visittBuildings[vissitCurrentKey?._id] || []}
                        />
                        <AppWorkModal
                            currentKey={appWorkCurrentKey}
                            cancleHandler={() => setAppWorkCurrentKey(null)}
                            setBuilding={setAppWorkBuilding}
                            buildings={appWorkBuildings[appWorkCurrentKey?._id] || []}
                        />
                    </Panel>
                </Collapse>

            </div >



            <Modal
                title='Create API Key'
                visible={isCreateModalOpen}
                onOk={okCreateHandler}
                onCancel={cancleCreateHandler}
                centered
            >
                Add a label to identify your API Key
                <Input value={labelName} onChange={(event) => { setLabelName(event.target.value) }} />
            </Modal>
            <Modal
                title='Are you sure?'
                visible={isRemoveModalOpen}
                onOk={okDeleteHandler}
                onCancel={cancleDeleteHandler}
                centered
            >
                API Key cannot be recovered once deleted.
            </Modal>
        </>
    );
}

export default Integration;
