import React, { useEffect, useState } from 'react';
import moment from 'moment';
import { Button, Modal, ModalHeader, ModalBody, Form, FormGroup, Label, Input, } from 'reactstrap';
import { toast } from 'react-toastify';
import creService from '../../services/creService';
import ReactLoading from "react-loading";
import { useLocation, useHistory } from "react-router-dom";
import Breadcrumb from '../../common/breadcrumb';

const ExcludedRespondent = (props) => {

    const location = useLocation();

    const history = useHistory();

    const queryParams = {
        action: new URLSearchParams(location?.search).get('action'),
        respondentName: new URLSearchParams(location?.search).get('respondentName'),
    };

    const [data, setData] = useState(props.jestData !== undefined ? props.jestData.data : []);
    const [showExcludeModal, setShowExcludeModal] = useState(false)
    const [isLoading, setIsLoading] = useState(false);
    const [isExcluding, setIsExcluding] = useState(false);

    const toggleExcludeModal = () => setShowExcludeModal(!showExcludeModal);

    const onExcludedRespondent = async (excludeRespondentName) => {
        setIsExcluding(true);
        const { data } = await creService.excludedRepondent(excludeRespondentName);
        if (data.status) {
            toast.success(data.message);
            getExcludedReponders();
            setShowExcludeModal(false);
        } else {
            if (data.errors) {
                data.errors.map((error) => toast.error(error));
            } else {
                toast.error(data.message);
            }
        }
        history.push('/settings/excluded-respondents');
        setIsExcluding(false);
    }

    const getExcludedReponders = async () => {
        setIsLoading(true);
        const { data } = await creService.getExcludedReponders();
        if (data.status) {
            setData(data.data);
        } else {
            toast.error(data.message);
        }
        setIsLoading(false);
    }

    const removeExcludedRespondent = async (respondentName) => {
        const { data } = await creService.removeExcludedRepondent(respondentName)
        if (data.status) {
            toast.success(data.message);
            getExcludedReponders();
        } else {
            if (data.errors) {
                data.errors.map((error) => toast.error(error));
            } else {
                toast.error(data.message);
            }
        }
        history.push('/settings/excluded-respondents');
    }

    useEffect(() => {
        getExcludedReponders();
        if (queryParams.action && queryParams.respondentName) {
            if (queryParams.action === 'add') {
                onExcludedRespondent(queryParams.respondentName);
            }
            if (queryParams.action === 'remove') {
                removeExcludedRespondent(queryParams.respondentName);
            }
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    return (
        <>
           {props.jestData === undefined && <Breadcrumb title="Excluded Respondents" parent="Home" />}
            <div className="container-fluid">
                <div className="table-responsive">
                    <table className="table table-borderless">
                        <thead>
                            <tr>
                                <th scope="col">#</th>
                                <th scope="col">Respondent Name</th>
                                <th scope="col">Added At</th>
                                <th scope="col">Added By</th>
                                <th scope="col" className="text-right">Actions</th>
                            </tr>
                        </thead>
                        <tbody>
                            {!isLoading && data.length > 0 && data.map((respondent, index) => {
                                return (
                                    <tr>
                                        <th scope="row">{index + 1}</th>
                                        <td>{respondent.respondentName}</td>
                                        <td>{moment(respondent.addedAt).format('MM/DD/YYYY hh:MM A')}</td>
                                        <td>{respondent.addedBy.fullName}</td>
                                        <td className="text-right">
                                            <button className="btn btn-danger btn-xs" onClick={() => removeExcludedRespondent(respondent.respondentName)}>Remove</button>
                                        </td>
                                    </tr>
                                )
                            })}
                            {!isLoading && data.length <= 0 && (
                                <tr>
                                    <td colSpan={5}>
                                        No responders are excluded yet.
                                    </td>
                                </tr>
                            )}
                            {isLoading && (
                                <tr>
                                    <td colSpan={5}>
                                        <ReactLoading type={"cylon"} color={"grey"} />
                                    </td>
                                </tr>
                            )}
                        </tbody>
                    </table>
                </div>
                <Button onClick={toggleExcludeModal} className="btn btn-primary mt-4">
                    <i className="fa fa-plus" /> Add New Respondent Name
                </Button>
                <Modal isOpen={showExcludeModal} toggle={toggleExcludeModal} size="lg">
                    <ModalHeader toggle={toggleExcludeModal}>Add New Respondent Name</ModalHeader>
                    <ModalBody>
                        <Form method="POST" onSubmit={(event) => {
                            event.preventDefault();
                            onExcludedRespondent(event.target.excludeRespondentName.value)
                        }}>
                            <FormGroup>
                                <Label for="excludeRespondentName">Respondent Name <sup className="text-danger">*</sup></Label>
                                <Input type="text" name="excludeRespondentName" id="excludeRespondentName" />
                            </FormGroup>
                            <Button type="submit" disabled={isExcluding}>{isExcluding ? 'Adding...' : 'Add'}</Button>
                        </Form>
                    </ModalBody>
                </Modal>
            </div>
        </>
    );
}

export default ExcludedRespondent;
