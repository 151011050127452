import React, { Fragment, useState, useEffect } from 'react';
import { toast } from 'react-toastify';
import { Link, useHistory, useParams } from 'react-router-dom';
import ReactLoading from 'react-loading';
import moment from 'moment';
import Swal from 'sweetalert2/dist/sweetalert2.js';
import { Button } from 'antd';
import { DownloadOutlined } from '@ant-design/icons';

import creService from '../../services/creService';
import { exportViolationEvents } from '../../services/exportExcel';

const stringSorter = (_sortField, direction = -1) => (a, b) => {
  const nameA = a[_sortField].toUpperCase();
  const nameB = b[_sortField].toUpperCase();
  if (nameA < nameB) {
    return direction;
  }
  if (nameA > nameB) {
    return -1 * direction;
  }
  return 0;
}



const SafetyMailingDashboard = ({ jestData = false }) => {
  const startYear = 2022;
  const [safetyMailings, setSafetyMailings] = useState(
    jestData ? jestData : []
  );
  const [loading, setLoading] = useState(false);
  const [sortField, setSortField] = useState({
    'display_address': -1
  });
  const { year: yearParams } = useParams();
  const [year, setYear] = useState(() => {
    if (yearParams) {
      return yearParams;
    } else {
      const now = moment();
      if (now.month() >= 7) now.add(1, 'year');
      return now.format('YYYY');
    }
  });
  const [years, setYears] = useState([]);

  useEffect(() => {
    let tmpYear = moment().add(1, 'year').format('YYYY');
    let tmpYears = [];
    while (tmpYear >= startYear) {
      tmpYears.push(Number(tmpYear));
      tmpYear--;
    }
    setYears(tmpYears);
  }, []);

  const getSafetyMailings = async () => {
    setLoading(false);
    const res = await creService.getSafetyMailingsDashboard(year);
    if (res.data.status) {
      setSafetyMailings(res.data.results.sort(stringSorter('display_address')))
    } else {
      toast.error(res.data.message);
    }
    setLoading(true);
  };

  const handleSort = async (_sortField) => {
    const direction =
      typeof sortField[_sortField] != 'undefined' &&
        sortField[_sortField] === -1
        ? 1
        : -1;
    const sort = {
      [_sortField]: direction,
    };

    setSortField(sort);
    const unSortedArr = safetyMailings;

    // eslint-disable-next-line array-callback-return
    await unSortedArr.sort((a, b) => {
      if (typeof a[_sortField] === 'string') {
        return stringSorter(_sortField, direction)(a, b)
      } else {
        if (direction === 1) {
          return a[_sortField] - b[_sortField];
        } else if (direction === -1) {
          return b[_sortField] - a[_sortField];
        }
      }

    });

    setSafetyMailings(unSortedArr);
  };

  useEffect(() => {
    getSafetyMailings();
    history.push('/safety-mailings/dashboard/' + year);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [year]);

  const history = useHistory();
  const handleRowClick = (row) => {
    history.push(row);
  };

  const changeYear = (value) => {
    setYear(value);
  };

  const eventGeneratePreview = async (id) => {
    if (id) {
      const formPreview = await creService.previewFireSafety(id);
      previewModal(formPreview.data);
    }
  };

  const previewModal = (html) => {
    Swal.fire({
      showCloseButton: true,
      showCancelButton: false,
      showConfirmButton: false,
      grow: 'fullscreen',
      html: html,
    });
  };

  const exportCsv = async () => {
    const columnsWithForCSV = [
      { header: 'Address ', key: 'address' },
      { header: 'Tenants ', key: 'tenants' },
      { header: 'WindowsGuard/Lead Total Sent', key: 'totalSent' },
      { header: 'WindowsGuard/Lead Total Recived', key: 'recived' },
      { header: 'WindowsGuard/Lead Mobile Recived', key: 'mobile' },
      { header: 'WindowsGuard/Lead Mail Recived', key: 'mail' },
      { header: 'WindowsGuard/Lead Online Recived', key: 'online' },
      { header: 'Knob Covers', key: 'knob' }
    ];
    const csvData = safetyMailings.map((item) => {
      return {
        address: item.display_address,
        tenants: item.tenantListCount,
        recived: item.windowGuardAnswer,
        totalSent: item.totalSent,
        mobile: item.windowCountAnswersPhone,
        mail: item.windowCountAnswersMail,
        online: item.windowCountAnswersWeb,
        knob: item.knobCovers,
      };
    });

    await exportViolationEvents(columnsWithForCSV, csvData, `csv`);
  };

  return (
    <Fragment>
      <div className="container-fluid">
        <div className="row">
          <div className="col-xl-12 my-building-wrapper">
            <div className="card">
              <div
                className="card-header d-flex justify-content-between pl-1 pr-1"
                style={{ flexDirection: 'column' }}
              >
                <div
                  style={{ display: 'flex', justifyContent: 'space-between' }}
                >
                  <h5>Safety Mailings</h5>
                </div>

                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    marginTop: '20px',
                  }}
                >
                  <div className="pr-2">
                    <Button
                      onClick={exportCsv}
                      style={{ cursor: 'pointer' }}
                      className="hide-btn-wrapper"
                    >
                      <DownloadOutlined className="pr-2" />
                      Export to CSV
                    </Button>
                  </div>
                  <select
                    className="form-control"
                    style={{ width: '250px' }}
                    required
                    name="year"
                    value={year}
                    onChange={(e) => changeYear(e.target.value)}
                  >
                    {years.map((year, index) => (
                      <option key={index} value={year}>
                        {year}
                      </option>
                    ))}
                  </select>
                </div>
              </div>
              <div className="my-building">
                <div className="card-body pl-1 pr-1">
                  <div className="table-responsive">
                    <table className="table table-borderless">
                      <thead>
                        <tr>
                          <th
                            scope="col"
                            style={{
                              whiteSpace: 'pre',
                              width: '30%',
                              textAlign: 'left',
                            }}
                          >
                            Address{' '}
                            <i
                              className={`fa ml-1 fa-sort-numeric-${sortField['display_address'] === -1 ? 'desc' : 'asc'
                                }`}
                              onClick={() => handleSort('display_address')}
                              style={{ cursor: 'pointer' }}
                            />
                          </th>
                          <th
                            scope="col"
                            style={{ width: '10%', textAlign: 'center' }}
                          >
                            <Link to={`/tenants/list`} className="text-dark">
                              Tenants
                            </Link>
                            <i
                              className={`fa ml-1 fa-sort-numeric-${sortField['tenantListCount'] === -1 ? 'desc' : 'asc'
                                }`}
                              onClick={() => handleSort('tenantListCount')}
                              style={{ cursor: 'pointer' }}
                            />
                          </th>
                          <th
                            scope="col"
                            style={{ width: '14%', textAlign: 'center' }}
                          >
                            <Link
                              to={`/fire-safety/list`}
                              className="text-dark"
                            >
                              FireSafety Form
                            </Link>

                          </th>
                          <th
                            scope="col"
                            style={{ width: '14%', textAlign: 'center' }}
                          >
                            <Link to={`/safety-mailings`} className="text-dark">
                              WindowsGuard/Lead
                            </Link>
                            <i
                              className={`fa ml-1 fa-sort-numeric-${sortField['windowsGuard'] === -1 ? 'desc' : 'asc'
                                }`}
                              onClick={() => handleSort('windowsGuard')}
                              style={{ cursor: 'pointer' }}
                            />
                          </th>
                          <th
                            scope="col"
                            style={{ width: '16%', textAlign: 'center' }}
                          >
                            <Link to={`/safety-mailings`} className="text-dark">
                              Knob Covers
                            </Link>
                            <i
                              className={`fa ml-1 fa-sort-numeric-${sortField['knobCovers'] === -1 ? 'desc' : 'asc'
                                }`}
                              onClick={() => handleSort('knobCovers')}
                              style={{ cursor: 'pointer' }}
                            />
                          </th>
                        </tr>
                      </thead>
                      {safetyMailings.length ? (
                        <tbody>
                          {safetyMailings.map((item, index) => {
                            const safetyMailing = item;
                            if (!safetyMailing) return null;
                            return (
                              <tr key={index}>
                                <td
                                  style={{
                                    textAlign: 'left',
                                    cursor: 'pointer',
                                    verticalAlign: 'middle',
                                  }}
                                  className="pl-0"
                                  onClick={() =>
                                    handleRowClick(
                                      `/safety-mailings/view/${safetyMailing._id}/${safetyMailing.bin}/window-guard/total_received`
                                    )
                                  }
                                >
                                  <span className="text-dark">
                                    <i className="fa fa-building" />{' '}
                                    {safetyMailing.display_address
                                      ? safetyMailing.display_address
                                      : safetyMailing.address}
                                  </span>
                                </td>
                                {safetyMailing.tenantListCount > 0 && (
                                  <td
                                    style={{
                                      textAlign: 'center',
                                      verticalAlign: 'middle',
                                    }}
                                  >
                                    <span className="text-black-50">
                                      {safetyMailing.tenantListCount}
                                    </span>
                                  </td>
                                )}
                                {safetyMailing.tenantListCount <= 0 && (
                                  <td
                                    style={{
                                      textAlign: 'center',
                                      verticalAlign: 'middle',
                                    }}
                                  >
                                    <span className="text-black-50">0</span>
                                  </td>
                                )}

                                <td
                                  style={{
                                    textAlign: 'center',
                                    verticalAlign: 'middle',
                                  }}
                                >
                                  {safetyMailing.fireSafetyForm ? (
                                    <i
                                      className="fa fa-file-text-o"
                                      style={{ cursor: 'pointer' }}
                                      onClick={() =>
                                        eventGeneratePreview(
                                          safetyMailing.fireSafetyFormId
                                        )
                                      }
                                    />
                                  ) : (
                                    ''
                                  )}
                                </td>

                                <td
                                  style={{
                                    display: 'flex',
                                    flexDirection: 'column',
                                    justifyContent: 'center',
                                    width: '100%',
                                    gap: '5px',
                                  }}
                                >
                                  <div
                                    style={{
                                      display: 'flex',
                                      justifyContent: 'center',
                                      gap: '5px',
                                    }}
                                  >
                                    <span
                                      className="badge badge-wo-open"
                                      style={{ cursor: 'pointer' }}
                                      onClick={() =>
                                        handleRowClick(
                                          `/safety-mailings/view/${safetyMailing._id}/${safetyMailing.bin}/window-guard/total_received`
                                        )
                                      }
                                    >
                                      {safetyMailing.windowsGuard
                                        ? safetyMailing.windowsGuard
                                        : 0}
                                    </span>
                                    <small>/</small>
                                    <span
                                      className="badge"
                                      style={{
                                        backgroundColor: 'rgb(233, 233, 233)',
                                        marginLeft: 0,
                                        cursor: 'pointer',
                                      }}
                                      onClick={() =>
                                        handleRowClick(
                                          `/safety-mailings/view/${safetyMailing._id}/${safetyMailing.bin}/window-guard/total_sent`
                                        )
                                      }
                                    >
                                      {safetyMailing.totalSent}
                                    </span>
                                  </div>
                                  <div
                                    style={{
                                      display: 'flex',
                                      justifyContent: 'center',
                                      gap: '10px',
                                      cursor: 'pointer',
                                    }}
                                    onClick={() =>
                                      handleRowClick(
                                        `/safety-mailings/view/${safetyMailing._id}/${safetyMailing.bin}/window-guard/total_received`
                                      )
                                    }
                                  >
                                    <span
                                      className="badge"
                                      style={{
                                        display: 'flex',
                                        alignItems: 'center',
                                        gap: '5px',
                                        backgroundColor: 'rgb(233, 233, 233)',
                                      }}
                                    >
                                      <i className="fa fa-desktop" />
                                      {safetyMailing.windowCountAnswersWeb
                                        ? safetyMailing.windowCountAnswersWeb
                                        : 0}
                                      <i className="fa fa-mobile" />
                                      {safetyMailing.windowCountAnswersPhone
                                        ? safetyMailing.windowCountAnswersPhone
                                        : 0}
                                      <i className="fa fa-envelope-o" />
                                      {safetyMailing.windowCountAnswersMail
                                        ? safetyMailing.windowCountAnswersMail
                                        : 0}
                                    </span>
                                  </div>
                                </td>

                                {safetyMailing.knobCovers > 0 && (
                                  <td
                                    style={{
                                      textAlign: 'center',
                                      verticalAlign: 'middle',
                                    }}
                                  >
                                    <span
                                      className="badge badge-wo-open"
                                      style={{ cursor: 'pointer' }}
                                      onClick={() =>
                                        handleRowClick(
                                          `/safety-mailings/view/${safetyMailing._id}/${safetyMailing.bin}/stove-safety/total_received`
                                        )
                                      }
                                    >
                                      {safetyMailing.knobCovers}
                                    </span>
                                  </td>
                                )}
                                {safetyMailing.knobCovers <= 0 && (
                                  <td
                                    style={{
                                      textAlign: 'center',
                                      verticalAlign: 'middle',
                                    }}
                                  >
                                    <span
                                      className="text-black-50"
                                      style={{ cursor: 'pointer' }}
                                      onClick={() =>
                                        handleRowClick(
                                          `/safety-mailings/view/${safetyMailing._id}/${safetyMailing.bin}/stove-safety/total_received`
                                        )
                                      }
                                    >
                                      0
                                    </span>
                                  </td>
                                )}
                              </tr>
                            );
                          })}
                          {safetyMailings.length <= 0 && !loading && (
                            <tr>
                              <td colSpan={7}>No work order available</td>
                            </tr>
                          )}
                          {!loading && (
                            <tr>
                              <td colSpan={7}>
                                <div className="loadercenter">
                                  {' '}
                                  <ReactLoading
                                    type={'cylon'}
                                    color={'grey'}
                                  />{' '}
                                </div>
                              </td>
                            </tr>
                          )}
                        </tbody>
                      ) : (
                        <tbody>
                          <tr>
                            <td colSpan="5">No data</td>
                          </tr>
                        </tbody>
                      )}
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default SafetyMailingDashboard;
