import React from 'react';

const Footer = (props) => {
  const reactVersion = localStorage.getItem('x-react-version');
  return (
    <footer className="footer">
      <div className="container-fluid">
        <div className="row">
          <div className="col-md-7 footer-copyright">
            <p className="mb-0 ml-5">
              Copyright 2021 © BCompliant All rights reserved.{' '}
              <a
                href="https://www.bcompliant.com/privacy-policy"
                target="_blank"
                rel="noreferrer"
              >
                Privacy Policy
              </a>{' '}
              |{' '}
              <a
                href="https://www.bcompliant.com/terms-of-use"
                target="_blank"
                rel="noreferrer"
              >
                Terms of Use
              </a>
              <br/>
              <small>V.{reactVersion}</small>
            </p>
          </div>
          <div className="col-md-5">
            <p className="pull-left mb-0"></p>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
