import React, { useState, useRef } from 'react';
import { Modal, ModalBody, } from 'reactstrap';
import { toast } from 'react-toastify';
import { CloseOutlined } from '@ant-design/icons'
import creService from '../../../../services/creService';

const ModalVendors = ({
    modalVendorColumn,
    setModalVendorColumn,
    handleAddCreatedVendor
}) => {
    const formRef = useRef();
    const [isCreating, setIsCreating] = useState(false);
    const [formData, setFormData] = useState({});
    const handleInput = (e) => {
        const { name, value, type } = e.target;
        setFormData(state => ({
            ...state,
            [name]: type === 'date' ? new Date(value) : value
        }));
    };
    const handleOnSubmit = async (e) => {

        e.preventDefault();
        setIsCreating(true);
        const res = await creService.createVendor(formData);
        if (res.data.status) {
            formRef.current.reset();
            toast.success(res.data.message);
            handleAddCreatedVendor(modalVendorColumn, res.data.vendor)
        } else {
            toast.error(res.data.message);
        }
        setModalVendorColumn(null)

        setIsCreating(false);
    }

    return (
        <div>
            <Modal isOpen={!!modalVendorColumn} backdrop="static">
                <ModalBody>
                    <div className='d-flex justify-content-end '>
                        <CloseOutlined
                            className='pointer'
                            onClick={() => { setModalVendorColumn(null) }}
                        />
                    </div>
                    <form ref={formRef} onSubmit={handleOnSubmit}>
                        <div className="row">
                            <div className="form-group col-12">
                                <label>Full Name <sup className="text-danger">*</sup></label>
                                <input type="text" name="fullName" className="form-control" onChange={handleInput} required ></input>
                            </div>
                            <div className="form-group col-12">
                                <label>Address <sup className="text-danger">*</sup></label>
                                <input type="text" name="address" className="form-control" onChange={handleInput} required ></input>
                            </div>
                            <div className="form-group col-12">
                                <label>Phone <sup className="text-danger">*</sup></label>
                                <input type="text" name="phone" className="form-control" maxLength={20} onChange={handleInput} required ></input>
                            </div>
                            <div className="form-group col-12">
                                <label>Email <sup className="text-danger">*</sup></label>
                                <input type="email" name="email" className="form-control" onChange={handleInput} required ></input>
                            </div>
                            <div className="form-group col-12">
                                <label>Status <sup className="text-danger">*</sup></label>
                                <select className="form-control" name="status" required onChange={handleInput}>
                                    <option value="">-- Select --</option>
                                    <option value="Active" >Active</option>
                                    <option value="NotActive" >Not Active</option>
                                </select>
                            </div>
                            <div className="form-group col-12 mb-0 d-flex justify-content-end">
                                {/* <button className="btn btn-primary" onClick={(event) => { event.preventDefault(); setModalVendorColumn(null) }}>Close</button> */}
                                <button type="submit" className="btn btn-primary" disabled={isCreating} >{isCreating ? 'Creating ...' : 'Add'}</button>
                            </div>
                        </div>
                    </form>
                </ModalBody>

            </Modal>
        </div>
    );
};

export default ModalVendors;
