import http from "./httpService";
import config from "../../config.json";
import { settingsKey } from './authService'

const apiUrl = config.apiUrl;

function register(data) {
  return http.post(`${apiUrl}/api/enroll`, {
    "fullName": data.fullName,
    "email": data.email,
    "phone": data.phone,
    "numberOfBuildings": data.numberOfBuildings,
    "comments": data.comments
  });
}

function activityExcel(name) {
  return http.downloadFile(`${apiUrl}/api/activity-snapshot/list/excel`, name)
}

function createExcel(name, excelData, displayNames) {
  return http.downloadFile(`${apiUrl}/api/dashboard/general-excel`, name, 'post', {
    excelData,
    displayNames,
  })
}

function resetPassword(password, resetId) {
  return http.post(`${apiUrl}/api/change-password`, { password, resetId });
}

function sendPasswordReset(id) {
  return http.get(`${apiUrl}/api/staff/resend-password-set/${id}`);
}
function forgotPassword(email) {
  return http.post(`${apiUrl}/api/forgot-password`, { email });
}

function getUserData(token) {
  return http.post(`${apiUrl}/api/get-user-data`, { token });
}

function getBuildingsList(detailResponse = false) {
  return http.post(`${apiUrl}/api/buildings/getBuildingsList`, { detailResponse });
}

function getBuildingsListOnly(addParams = null, tenantsYear) {
  return http.post(`${apiUrl}/api/buildings/get-building-list-only`, { addParams, tenantsYear });
}

function getPropertyInfo(bin = 0) {
  return http.post(`${apiUrl}/api/buildings/getPropertyInfo`, { bin });
}
function getRecentActivity(bin = 0, category, sub) {
  let post = { bin };
  if (category) post.category = category;
  if (sub) post.sub = sub;

  return http.post(`${apiUrl}/api/buildings/getRecentActivity`, post);
}

function getViolationDetails(id = 0) {
  return http.post(`${apiUrl}/api/buildings/getViolationById`, { id });
}
function getRecentActivityAll(bin = null) {
  let payload = {};
  if (bin) {
    payload = {
      ...payload,
      bin,
    }
  }
  return http.post(`${apiUrl}/api/buildings/getRecentActivityAll`, payload);
}

function getViolationHistoryDetails(id = 0) {
  return http.post(`${apiUrl}/api/buildings/getViolationHistoryById`, { id });
}

function getViolationsData(bin = 0) {
  return http.post(`${apiUrl}/api/buildings/getViolations`, { bin });
}

function getViolationsDataSnapshot(bin = 0, snapshotDate) {
  return http.post(`${apiUrl}/api/buildings/getViolations/snapshot`, { bin, snapshotDate });
}

function getViolationsForAllBuilding(violationCategory, sub, filter, sortBy, sortType, page = null, dashboard = false) {
  return http.post(`${apiUrl}/api/buildings/getViolation`, { bin: null, violationCategory, sub, filter, totalOnly: false, sortBy, sortType, page, dashboard });
}

function getAdditionalDataMainChart(category, sub, bin = null, dashboard = false) {
  return http.post(`${apiUrl}/api/buildings/additional-data-main-chart`, { bin, category, sub, dashboard });
}

function getViolationData(bin, violationCategory, sub, filter, sortBy, sortType) {
  return http.post(`${apiUrl}/api/buildings/getViolation`, { bin, violationCategory, sub, filter, totalOnly: false, sortBy, sortType });
}

function getHPDViolationCodesDesc(codes) {
  return http.post(`${apiUrl}/api/hpd-violation-codes-desc`, { codes });
}

function getDashboardData(dashboard, sub, filters) {
  return http.post(`${apiUrl}/api/dashboard`, { dashboard, sub, filters });
}
function getDashboardDataCustom(category, sub) {
  return http.post(`${apiUrl}/api/dashboard/custom`, { category, sub });
}


function getDashboardDataSnapshot(dashboard, subs, snapshotDate, detailView, isDashboard = true, bin) {
  return http.post(`${apiUrl}/api/dashboard/snapshots`, { dashboard, subs, snapshotDate, detailView, isDashboard, bin });
}


function getComplianceRequirements(category, year) {
  return http.post(`${apiUrl}/api/complianceRequirements/${category}${year ? `?year=${year}` : ''}`, {});
}

function getCalendar(startDate, endDate) {
  return http.post(`${apiUrl}/api/calendar/combined`, { startDate, endDate });
}

function createEventCalendar(event) {
  return http.post(`${apiUrl}/api/calendar/add-custom`, event);
}

function getViolationsAllTab() {
  return http.post(`${apiUrl}/api/violations`, {});
}
function getViolationsAllTabDetails(category, sub) {
  return http.post(`${apiUrl}/api/violations/details`, { category, sub });
}
function updateViolationColor(id, color) {
  return http.post(`${apiUrl}/api/violations/update-color`, { id, color });
}
function updateColorLabel(color, label) {
  return http.post(`${apiUrl}/api/violations/update-color-label`, { color, label });
}
function generatePDF(headers, data, title) {
  return http.post(`${apiUrl}/api/violations/generate-pdf`, { headers, data, title });
}
function getPermitsAllTab() {
  return http.post(`${apiUrl}/api/permits`, {});
}
function getPermitsAllTabDetails(category, sub) {
  return http.post(`${apiUrl}/api/permits/details`, { category, sub });
}
function search(search) {
  return http.post(`${apiUrl}/api/buildings/search`, { search });
}
function getRecentActivityPerViolation(violationId, saveOn = 'Violation') {
  return http.post(`${apiUrl}/api/buildings/getRecentActivityByViolationId`, { violationId, saveOn });
}
function getElevatorsInspections(bin, deviceNumber) {
  return http.post(`${apiUrl}/api/buildings/getElevatorsInspections`, { bin, deviceNumber });
}
function getElevatorsElevatorsTracking(bin, deviceNumber) {
  return http.post(`${apiUrl}/api/buildings/getElevatorsTracking`, { bin, deviceNumber });
}
function getECB_ID(ECBNumber) {
  return http.post(`${apiUrl}/api/buildings/getECB_ID`, { ECBNumber });
}
function getHistoryUpdates(page) {
  return http.post(`${apiUrl}/api/buildings/getHistoryUpdatesV3`, { page });
}

function getClientUpdateHistory(clientId, page) {
  return http.get(`${apiUrl}/api/staff/update-activity/${clientId}?page=${page}`);
}

function searchV2(search) {
  return http.post(`${apiUrl}/api/buildings/searchV2`, { search });
}
function searchV3(search) {
  return http.post(`${apiUrl}/api/buildings/searchV3`, { search });
}
function updateAlertPreferences(preferences) {
  return http.post(`${apiUrl}/api/preferences/alert-preferences/update`, { preferences });
}
function getAllAlertPreferences() {
  return http.get(`${apiUrl}/api/preferences/alert-preferences`);
}
function getAllReports(page = 1, getOnly = "ALL") {
  return http.get(`${apiUrl}/api/reports?page=${page}&getOnly=${getOnly}`);
}
function getRespondent(filters, bins) {
  return http.post(`${apiUrl}/api/reports/get-respondent`, { filters, bins });
}
function generateReport(payload) {
  return http.post(`${apiUrl}/api/reports/generate`, payload);
}
function reGenerateReport(id, type) {
  return http.get(`${apiUrl}/api/reports/regenerate/${id}/${type}`);
}
function getReportById(id) {
  return http.get(`${apiUrl}/api/reports/get-details/${id}`);
}
function setReportDisplayName(id, name) {
  return http.put(`${apiUrl}/api/reports/set-name/${id}`, {
    name
  });
}
function getReportDownloadUrl(reportId, type) {
  return http.post(`${apiUrl}/api/reports/get-download-link`, {
    reportId,
    type,
  });
}
function getReportFiltersConfig() {
  return http.get(`${apiUrl}/api/reports/get-filter-config`, {});
}
function getAllNotificationPreferences() {
  return http.get(`${apiUrl}/api/preferences/notification-preferences`);
}
function updateNotificationsPreferences(preferences) {
  return http.post(`${apiUrl}/api/preferences/notification-preferences/update`, { preferences });
}
function getAllWorkOrderNotificationPreferences() {
  return http.get(`${apiUrl}/api/preferences/work-order/notification`);
}
function updateWorkOrderNotificationPreferences(preferences) {
  return http.post(`${apiUrl}/api/preferences/work-order/notification/update`, { preferences });
}
function getAllChatsPreferences() {
  return http.get(`${apiUrl}/api/preferences/chats-preferences`);
}
function updateChatsPreferences(preferences) {
  return http.post(`${apiUrl}/api/preferences/chats-preferences/update`, { preferences });
}
function updateUserPageView(viewType) {
  let settings = JSON.parse(localStorage.getItem(settingsKey));
  if (settings && settings.viewType && typeof settings.viewType[viewType.page] !== 'undefined') {
    settings.viewType[viewType.page] = viewType.type;
    localStorage.setItem(settingsKey, JSON.stringify(settings));
  } else {
    const prevSettings = settings !== null && typeof settings === 'object' ? settings : { viewType: {} };
    localStorage.setItem(settingsKey, JSON.stringify({
      ...prevSettings,
      viewType: {
        ...prevSettings.viewType,
        [viewType.page]: viewType.type,
      }
    }));
  }
  return http.post(`${apiUrl}/api/preferences/user-settings/view-type/update`, { viewType });
}

function updateUserSortBy(sortByType, category, sub) {
  let settings = JSON.parse(localStorage.getItem(settingsKey));
  if (settings && settings.sortBy && settings.sortBy[sortByType.page] && settings.sortBy[sortByType.page][category] && settings.sortBy[sortByType.page][category][sub]) {
    settings.sortBy[sortByType.page][category][sub] = {
      field: sortByType.field,
      direction: sortByType.direction,
    };
    localStorage.setItem(settingsKey, JSON.stringify(settings));
  } else {

    let prevSettings = settings !== null && typeof settings === 'object' ? settings : {};
    if (!prevSettings.sortBy) {
      prevSettings.sortBy = {};
    }
    if (!prevSettings.sortBy[sortByType.page]) {
      prevSettings.sortBy = {
        ...prevSettings.sortBy,
        [sortByType.page]: {}
      };
    }
    if (!prevSettings.sortBy[sortByType.page][category]) {
      prevSettings.sortBy = {
        [sortByType.page]: {
          ...prevSettings.sortBy[sortByType.page],
          [category]: {}
        }
      };
    }

    const initialSettingsOfSortBy = {
      [sortByType.page]: {
        ...(prevSettings.sortBy[sortByType.page] || {}),
        [category]: {
          ...(prevSettings.sortBy[sortByType.page][category] || {}),
          [sub]: {
            field: sortByType.field,
            direction: sortByType.direction,
          }
        }
      }
    };

    localStorage.setItem(settingsKey, JSON.stringify({
      ...prevSettings,
      sortBy: {
        ...initialSettingsOfSortBy
      }
    }));
  }

  return http.post(`${apiUrl}/api/preferences/user-settings/sort-by/update`, {
    sortBy: {
      ...sortByType
    }, category, sub
  });
}

function updateAccountSetting(settings) {
  return http.post(`${apiUrl}/api/preferences/user-settings`, { settings });
}

function requestDataRemoveMobileApp() {
  return http.get(`${apiUrl}/api/preferences/data-remove-mobile-app`);
}

function getChatHistory(violationId) {
  return http.get(`${apiUrl}/api/chat/history?violationId=${violationId}`);
}
function sendChatMessage(data, source = 'Violation') {
  return http.post(`${apiUrl}/api/chat/send-message`, { ...data, source });
}
function getChatList() {
  return http.get(`${apiUrl}/api/chat/chats`);
}

function getUploaderName(type, id) {
  return http.get(`${apiUrl}/api/documents/uploader-name/${type}/${id}`);
}

function uploadDocument(data, option) {
  return http.post(`${apiUrl}/api/documents/upload`, data, option);
}
function getBuidingDocuments(bin) {
  return http.get(`${apiUrl}/api/documents/building/${bin}`);
}

function getAllDocuments(type, sortBy, sortDirection, isPrivate, page = 0) {
  if (sortBy) {
    return http.get(`${apiUrl}/api/documents/get/${type.toLowerCase()}?sortBy=${sortBy}&sortDirection=${sortDirection}&page=${page}&isPrivate=${isPrivate}`);
  }
  return http.get(`${apiUrl}/api/documents/get/${type.toLowerCase()}?page=${page}&isPrivate=${isPrivate}`);
}
function getDocumentsByViolationId(type, violationId, fieldId) {
  return http.get(`${apiUrl}/api/documents/get/${type.toLowerCase()}`, {
    params: {
      violationId,
      fieldId
    }
  });
}
function deleteDocument(documentId) {
  return http.post(`${apiUrl}/api/documents/delete`, { documentId });
}
function changeDocumentVisibility(documentId, isPrivate) {
  return http.post(`${apiUrl}/api/documents/change-visibility`, { documentId, isPrivate });
}
function saveNotes(violationId, payload, source = 'Violation') {
  return http.post(`${apiUrl}/api/documents/save-notes`, { violationId, ...payload, source });
}
function getAwsUrlByDocumentId(documentId) {
  return http.post(`${apiUrl}/api/documents/get/aws-s3-url`, { documentId });
}

function getCustomComplianceGetAll() {
  return http.get(`${apiUrl}/api/custom-compliance/get-all`, {});
}
function getCustomComplianceDashboard(bin) {
  return http.get(`${apiUrl}/api/custom-compliance/dashboard${bin ? `?bin=${bin}` : ''}`, {});
}

function createCustomCategoryCompliance(payload) {
  return http.post(`${apiUrl}/api/custom-compliance/create-category`, { ...payload });
}
function updateCustomCompliance(id, payload) {
  return http.put(`${apiUrl}/api/custom-compliance/update/${id}`, { ...payload });
}

function getCustomCompliancesNew(id, currentArchive = '') {
  return http.get(`${apiUrl}/api/custom-compliance/list/${id}?archive=${currentArchive}`, {});
}

function getCustomComplianceNew(id) {
  return http.get(`${apiUrl}/api/custom-compliance/get/${id}`, {});
}

function createCustomComplianceNew(payload) {
  return http.post(`${apiUrl}/api/custom-compliance/create`, { ...payload });
}

function deleteCustomComplianceNew(complianceId) {
  return http.post(`${apiUrl}/api/custom-compliance/delete`, { complianceId });
}
function deleteCustomComplianceCategory(id) {
  return http.post(`${apiUrl}/api/custom-compliance/delete-category`, { id });
}
function getComplianceInfo(category) {
  return http.post(`${apiUrl}/api/complianceRequirements/compliance-info`, { category });
}
function getAllGuides() {
  return http.get(`${apiUrl}/api/complianceRequirements/compliance-info/all`);
}
function createWorkOrder(payload, type) {
  return http.post(`${apiUrl}/api/work-order/create`, { ...payload, type });
}
function getWorkOrdersByBuildings() {
  return http.get(`${apiUrl}/api/work-order/dashboard`, {});
}
function getWorkOrders(sort, search, filter, page, rest, groupByStatus = false) {
  return http.post(`${apiUrl}/api/work-order/list?groupByStatus=${groupByStatus}`, { sort, search, filter, page, ...rest });
}
function getWorkOrderPdf(workOrderId) {
  return http.get(`${apiUrl}/api/work-order/generate-pdf/${workOrderId}`, {});
}
function getWorkOrder(id) {
  return http.get(`${apiUrl}/api/work-order/details/${id}`, {});
}
function getWorkOrderHistory(id) {
  return http.get(`${apiUrl}/api/work-order/history/${id}`, {});
}
function editWorkOrder(id) {
  return http.get(`${apiUrl}/api/work-order/edit/${id}`, {});
}
function updateWorkOrder(payload) {
  return http.post(`${apiUrl}/api/work-order/update`, { ...payload });
}
function updateWorkOrderStatus(id, status) {
  return http.post(`${apiUrl}/api/work-order/update-status`, { id, status });
}
function getDocumentUrlWorkOrder(key) {
  return http.post(`${apiUrl}/api/work-order/get-document-url`, { key });
}
function getChatUsers(workOrderId) {
  return http.get(`${apiUrl}/api/work-order/get-chat-users/${workOrderId}`, {});
}
function addDocumentWorkOrder(workOrderId, document) {
  return http.post(`${apiUrl}/api/work-order/add-document`, { workOrderId, document });
}
function deleteDocumentWorkOrder(workOrderId, documentId) {
  return http.post(`${apiUrl}/api/work-order/delete-document`, { workOrderId, documentId });
}
function getAssignees() {
  return http.get(`${apiUrl}/api/work-order/get-assignee-list`, {});
}
function createVendor(payload) {
  return http.post(`${apiUrl}/api/vendors/create`, { ...payload });
}
function getVendors() {
  return http.get(`${apiUrl}/api/vendors/list`, {});
}
function editVendor(id) {
  return http.get(`${apiUrl}/api/vendors/edit/${id}`, {});
}
function updateVendor(payload) {
  return http.post(`${apiUrl}/api/vendors/update`, { ...payload });
}
function deleteVendor(id) {
  return http.get(`${apiUrl}/api/vendors/delete/${id}`, {});
}
function getUserBuildingList() {
  return http.get(`${apiUrl}/api/staff/get-user-building-list`, {});
}
function createStaff(payload) {
  return http.post(`${apiUrl}/api/staff/create`, { ...payload });
}
function deleteStaff(id) {
  return http.get(`${apiUrl}/api/staff/delete/${id}`, {});
}
function editSubUser(id) {
  return http.get(`${apiUrl}/api/staff/edit/${id}`, {});
}
function updateSubUser(payload) {
  return http.post(`${apiUrl}/api/staff/update`, { ...payload });
}
function getServicePermissions() {
  return http.get(`${apiUrl}/api/staff/get-service-pemissions`, {});
}
function getSubUsers() {
  return http.get(`${apiUrl}/api/settings/get/sub-users`, {});
}
function getExcludedReponders() {
  return http.get(`${apiUrl}/api/settings/excluded-responders`, {});
}
function excludedRepondent(respondentName) {
  return http.post(`${apiUrl}/api/settings/exclude-respondent`, { respondentName });
}
function removeExcludedRepondent(respondentName) {
  return http.post(`${apiUrl}/api/settings/remove-excluded-respondent`, { respondentName });
}
function sendWorkOrderChatMessage(data) {
  return http.post(`${apiUrl}/api/work-order/chat/send-message`, data);
}
function getWorkOrderChatHistory(workOrderId) {
  return http.get(`${apiUrl}/api/work-order/chat/history?workOrderId=${workOrderId}`);
}
function getWorkOrderChatList() {
  return http.get(`${apiUrl}/api/work-order/chat/chats`);
}
function deleteReport(reportId) {
  return http.get(`${apiUrl}/api/reports/delete/${reportId}`);
}
function updateReportLabel(reportId, reportLabel) {
  return http.post(`${apiUrl}/api/reports/update-label`, { reportId, reportLabel });
}
function getWorkOrderViolation(violationId, integration = '') {
  return http.get(`${apiUrl}/api/work-order/get-violation/${violationId}?integration=${integration}`);
}
function renewSession() {
  return http.get(`${apiUrl}/api/renew-session`);
}
function getWorkOrderCategories(workOrderId) {
  if (workOrderId) {
    return http.get(`${apiUrl}/api/work-order/get-all-categories/${workOrderId}`);
  }
  return http.get(`${apiUrl}/api/work-order/get-all-categories`);
}
function getWorkOrderStatus(workOrderId) {
  if (workOrderId) {
    return http.get(`${apiUrl}/api/work-order/get-all-status/${workOrderId}`);
  }
  return http.get(`${apiUrl}/api/work-order/get-all-status`);
}
function getWorkOrderVendors(editView, workOrderId) {
  if (editView && workOrderId) {
    return http.get(`${apiUrl}/api/vendors/list-for-work-orders/${workOrderId}`, {});
  }
  return http.get(`${apiUrl}/api/vendors/list-for-work-orders/`, {});
}
function getStaffAllocationForWorkOrders(startDate, endDate) {
  return http.post(`${apiUrl}/api/work-order/get-staff-allocation`, { startDate, endDate });
}
function getAllTenants(filter, year) {
  return http.post(`${apiUrl}/api/tenants/list`, { ...filter, year });
}
function getOneTenant(id) {
  return http.get(`${apiUrl}/api/tenants/view/${id}`);
}
function createTenant(data) {
  return http.post(`${apiUrl}/api/tenants/create`, data);
}
function uploadBulkTenants(data) {
  return http.post(`${apiUrl}/api/tenants/upload-bulk`, data);
}
function updateTenant(data) {
  return http.post(`${apiUrl}/api/tenants/update`, data);
}
function deleteTenant(ids) {
  return http.post(`${apiUrl}/api/tenants/delete`, { ids });
}
function getSafetyMailingsBuildings(orderYear = null, year = null) {
  return http.get(`${apiUrl}/api/safety-mailings/list-builings?notHaveOrderForYear=${orderYear}&year=${year}`);
}
function getAllSafetyMailingsOrder(filters) {
  return http.post(`${apiUrl}/api/safety-mailings/list-orders`, filters);
}
function getSafetyMailingsOrder(id) {
  return http.get(`${apiUrl}/api/safety-mailings/view/${id}`);
}
function createSafetyMailingOrder(payload) {
  return http.post(`${apiUrl}/api/safety-mailings/create-order`, payload);
}
function deleteSafetyMailingOrder(id) {
  return http.post(`${apiUrl}/api/safety-mailings/delete/${id}`);
}
function getSafetyMailingsOrderResponses(mailingOrderId, filters) {
  return http.post(`${apiUrl}/api/safety-mailings/responses/${mailingOrderId}`, filters);
}
function exportSafetyMailingsOrderResponses(mailingOrderId, responseType, onlyReceived = false, ids = []) {
  // type [windowSummary, stoveReceived]
  return http.post(`${apiUrl}/api/safety-mailings/export-responses/${mailingOrderId}`, {
    responseType,
    onlyReceived,
    ids,
  });
}
function getSafetyMailingPrintedRow(printedId) {
  return http.get(`${apiUrl}/api/safety-mailings/printed-row/${printedId}`);
}
function getSafetyMailingPrintedResponse(responseId) {
  return http.get(`${apiUrl}/api/safety-mailings/printed-row-response/${responseId}`);
}

function setSafetyMailingEmail(email, printedId) {
  return http.post(`${apiUrl}/api/safety-mailings/set-email/${printedId}`, { email });
}

function getSafetyMailingEmailTemplate() {
  return http.get(`${apiUrl}/api/safety-mailings/safety-mailing-template`);
}
function saveSafetyMailingEmailTemplate(payload) {
  return http.post(`${apiUrl}/api/safety-mailings/safety-mailing-template`, payload);
}
function sendSafetyMailingEmail(payload) {
  return http.post(`${apiUrl}/api/safety-mailings/safety-mailing-send-email`, payload);
}
function submitSafetyMailingPrintedResponse(responseId, payload) {
  return http.post(`${apiUrl}/api/safety-mailings/submit-printed-row-response/${responseId}`, payload);
}

function safetyMailingsMarkCompletedResponseAction(payload) {
  return http.post(`${apiUrl}/api/safety-mailings/response-action/mark-as-completed`, payload);
}
function getSafetyMailingsDashboard(year) {
  return http.get(`${apiUrl}/api/safety-mailings/dashboard/${year}`);
}
function getAllFireSafety(filter) {
  return http.post(`${apiUrl}/api/fire-safety/list`, filter);
}
function createFireSafety(data) {
  return http.post(`${apiUrl}/api/fire-safety/create`, data);
}
function getOneFireSafety(id) {
  return http.get(`${apiUrl}/api/fire-safety/view/${id}`);
}
function updateFireSafety(data) {
  return http.post(`${apiUrl}/api/fire-safety/update`, data);
}
function deleteFireSafety(id) {
  return http.post(`${apiUrl}/api/fire-safety/delete/${id}`);
}
function getFireSafetyBinListOnly() {
  return http.post(`${apiUrl}/api/fire-safety/get-bin-list-only`);
}
function previewFireSafety(id) {
  return http.get(`${apiUrl}/api/fire-safety/preview/${id}`);
}
function getActivitySnapshot() {
  return http.get(`${apiUrl}/api/activity-snapshot/list`);
}
function getS3UrlByKey(key) {
  return http.get(`${apiUrl}/api/get-s3-url?key=${key}`);
}
function getRecentWorkActivity(page) {
  return http.post(`${apiUrl}/api/work-order/recent-activity`, { page });
}
function getSafetyMailingAutoRules() {
  return http.get(`${apiUrl}/api/safety-mailings-rules/list`);
}
function getSafetyMailingAutoRule(ruleId) {
  return http.get(`${apiUrl}/api/safety-mailings-rules/view/${ruleId}`);
}
function createSafetyMailingAutoRules(data) {
  return http.post(`${apiUrl}/api/safety-mailings-rules/create`, data);
}
function updateSafetyMailingAutoRules(ruleId, data) {
  return http.post(`${apiUrl}/api/safety-mailings-rules/update/${ruleId}`, data);
}
function deleteSafetyMailingAutoRules(ruleId) {
  return http.post(`${apiUrl}/api/safety-mailings-rules/delete`, { ruleId });
}
function previewSafetyMailingAutoRules(data) {
  return http.post(`${apiUrl}/api/safety-mailings-rules/preview`, data);
}
function getSafetyMailingYears() {
  return http.get(`${apiUrl}/api/safety-mailings-rules/safety-mailing-years`);
}
function getPortfolioData() {
  return http.get(`${apiUrl}/api/portfolio`);
}
function getLocationEnvs() {
  return http.get(`${apiUrl}/api/locations-envs`);
}
function setLocationEnv(locationEnv) {
  return http.post(`${apiUrl}/api/set-location-env`, { locationEnv });
}

function createPortfolio(payload) {
  return http.post(`${apiUrl}/api/portfolio/create`, payload)
}
function editPortfolio(id) {
  return http.get(`${apiUrl}/api/portfolio/edit/${id}`, {});
}
function updatePortfolio(payload) {
  return http.post(`${apiUrl}/api/portfolio/edit/`, payload);
}
function deletePortfolio(id) {
  return http.get(`${apiUrl}/api/portfolio/delete/${id}`)
}
function getNotes(violationId = null, page, sortBy, sortDirection) {
  if (violationId) {
    return http.get(`${apiUrl}/api/documents/notes/get/${violationId}`);
  }
  return http.post(`${apiUrl}/api/documents/notes/get`, { page, sortBy, sortDirection });
}

function setCustomCategory(category, sub, type, name, data, bcType) {
  return http.post(`${apiUrl}/api/violations/update-custom-categories`, { category, sub, type, name, data, bcType });
}

function getAgencyGroups() {
  return http.get(`${apiUrl}/api/violations/get-agency-groups`);
}
function getCustomFields(category, sub) {
  return http.get(`${apiUrl}/api/violations/get-custom-fields/${category}/${sub}`);
}

function updateCustomViolationField(violationId, data, saveOn = 'Violation') {
  return http.post(`${apiUrl}/api/violations/update-custom-field/${violationId}`, { ...data, saveOn });
}

function updateSearchTerms(category, sub, searchTerms) {
  return http.post(`${apiUrl}/api/violations/update-search-terms/${category}/${sub}`, { searchTerms });
}

function updateCustomViolationFieldBatch(data, saveOn = 'Violation') {
  return http.post(`${apiUrl}/api/violations/update-custom-field-batch`, { data, saveOn });
}

function updateCustomCategoryField(id, name) {
  return http.post(`${apiUrl}/api/violations/update-category-field/column-label`, { id, name });
}

function updateCustomCategoryFieldColorLabel(columnId, labels) {
  return http.post(`${apiUrl}/api/violations/update-category-field/color-label`, { columnId, labels });
}

function updateCustomCategoryFieldClearColor(columnId, colorCode, uniqueViolationId) {
  return http.post(`${apiUrl}/api/violations/update-category-field/clear-color`, { columnId, colorCode, uniqueViolationId });
}

function updateCustomFieldOptions(categoriesCustomOptions, category, sub) {
  return http.post(`${apiUrl}/api/violations/update-category-fields/options`, { categoriesCustomOptions, category, sub })
}

function toggleCustomCategoryVisibility(fieldId, show) {
  return http.post(`${apiUrl}/api/violations/update-category-fields/toggle`, { fieldId, show })
}

function isCustomFieldHasData(fieldId) {
  return http.post(`${apiUrl}/api/violations/custom-category-fields/is-has-data`, { fieldId })
}

function getCustomColors() {
  return http.get(`${apiUrl}/api/violations/custom-category-fields/get-custom-colors`)
}

function addColorScheme(id, data) {
  return http.post(`${apiUrl}/api/violations/update-category-fields/add-color-scheme`, { id, data })
}

function deleteCustomCategoryField(fieldId) {
  return http.post(`${apiUrl}/api/violations/update-category-fields/delete`, { fieldId })
}

function updateUnitsInBuilding(units, bin) {
  return http.post(`${apiUrl}/api/buildings/updateUnits`, { units, bin })
}
function updateDisplayAddress(displayAddress, bin) {
  return http.post(`${apiUrl}/api/buildings/update-display-address`, { displayAddress, bin })
}

function getVersionUpdates(limit = 100) {
  return http.get(`${apiUrl}/api/version-updates?limit=${limit}`);
}
function getPriority(sub, category) {
  return http.get(`${apiUrl}/api/violations/get-priority/${category}/${sub}`);
}
function setPriority(id, value, sub, category) {
  return http.post(`${apiUrl}/api/violations/set-priority`, {
    id,
    value,
    category,
    sub
  });
}

function getAllPriority() {
  return http.get(`${apiUrl}/api/violations/get-priority/all`);
}

function updatePriority(id, payload) {
  return http.put(`${apiUrl}/api/violations/update-priority/${id}`, payload);
}

function createApiKey(name = '') {
  return http.post(`${apiUrl}/api/api-key`, { name });
}
function removeApiKey(key) {
  return http.delete(`${apiUrl}/api/api-key/${key}`);
}
function getApiKeys() {
  return http.get(`${apiUrl}/api/api-key`);
}

function getPartnerConfiguration() {
  return http.get(`${apiUrl}/api/settings/partners-api-configuration`);
}

function setPartnerConfiguration(name, newConfig) {
  return http.post(`${apiUrl}/api/settings/partners-api-configuration/${name}`, newConfig);
}

function setPartnerConfigurationEnable(name, value) {
  return http.post(`${apiUrl}/api/settings/partners-api-configuration/${name}/enable`, { enabled: value });
}

function setIntegrationKey(type, _id, key, remove) {
  return http.post(`${apiUrl}/api/settings/partners-api-configuration/${type}/set-key`, { _id, key, remove });
}


function getVisittBuilding(_id) {
  return http.get(`${apiUrl}/api/visitt-api/bildings/${_id}`);
}

function setVisittAddress(bin, visittAddress, visittKeyID, visittAddressID) {
  return http.post(`${apiUrl}/api/visitt-api/set-building`, { bin, visittAddress, visittKeyID, visittAddressID });
}
function getVisittCategories(bin) {
  return http.get(`${apiUrl}/api/visitt-api/get-categories/${bin}`);
}
function getVisittSpaces(bin) {
  return http.get(`${apiUrl}/api/visitt-api/get-sites/${bin}`);
}
function syncVisittOrder(id) {
  return http.get(`${apiUrl}/api/visitt-api/sync/${id}`);
}

function getAppWorkApts(bin) {
  return http.get(`${apiUrl}/api/appWork-api/get-units/${bin}`);
}
function getAppWorkBuilding(_id) {
  return http.get(`${apiUrl}/api/appWork-api/bildings/${_id}`);
}

function setAppWorkAddress(bin, address, adressID, keyID) {
  return http.post(`${apiUrl}/api/appWork-api/set-building`, { bin, address, adressID, keyID });
}



function moveToVisittOrder(id) {
  return http.post(`${apiUrl}/api/work-order/move-to-visitt/${id}`);
}

function syncAllVisittOrders() {
  return http.get(`${apiUrl}/api/visitt-api/sync-all`);
}
function checkWorkOrderCreateAbility() {
  return http.get(`${apiUrl}/api/work-order/create-ability`);
}
function copyFromYearTenants(filter, year) {
  return http.post(`${apiUrl}/api/tenants/copyFrom`, { ...filter, year });
}
function getDocumentByKey(key, payload) {
  return http.post(`${apiUrl}/api/documents/get-by-key`, { key, ...payload });
}
function removeNote(id) {
  return http.delete(`${apiUrl}/api/documents/remove-note/${id}`);
}

function syncAppWorkOrder(id) {
  return http.get(`${apiUrl}/api/appWork-api/sync/${id}`);
}
function modifyArchives(archives, complianceId) {
  return http.post(`${apiUrl}/api/custom-compliance/modify-archives/${complianceId}`, { archives });
}
function moveToArchive(ids, archiveId) {
  return http.post(`${apiUrl}/api/custom-compliance/move-to-archive/${archiveId}`, { ids });
}


function getArchives(complianceId) {
  return http.get(`${apiUrl}/api/custom-compliance/get-archives/${complianceId}`);
}



// eslint-disable-next-line import/no-anonymous-default-export
export default {
  register,
  getDocumentByKey,
  resetPassword,
  forgotPassword,
  getBuildingsList,
  getBuildingsListOnly,
  getViolationsData,
  getViolationData,
  getViolationsForAllBuilding,
  getPropertyInfo,
  getViolationDetails,
  getHPDViolationCodesDesc,
  getRecentActivity,
  getViolationHistoryDetails,
  getRecentActivityAll,
  getDashboardData,
  getComplianceRequirements,
  getCalendar,
  getViolationsAllTab,
  getViolationsAllTabDetails,
  getPermitsAllTab,
  getPermitsAllTabDetails,
  search,
  getRecentActivityPerViolation,
  getElevatorsInspections,
  getECB_ID,
  getHistoryUpdates,
  searchV2,
  searchV3,
  updateAlertPreferences,
  getAllAlertPreferences,
  getAllChatsPreferences,
  updateChatsPreferences,
  getAllReports,
  generateReport,
  getReportDownloadUrl,
  getAllNotificationPreferences,
  getAllWorkOrderNotificationPreferences,
  updateWorkOrderNotificationPreferences,
  updateNotificationsPreferences,
  getChatHistory,
  sendChatMessage,
  getChatList,
  uploadDocument,
  getAllDocuments,
  getDocumentsByViolationId,
  deleteDocument,
  changeDocumentVisibility,
  saveNotes,
  getAwsUrlByDocumentId,
  updateUserPageView,
  updateUserSortBy,
  createWorkOrder,
  getWorkOrdersByBuildings,
  getWorkOrders,
  getWorkOrder,
  getDocumentUrlWorkOrder,
  addDocumentWorkOrder,
  deleteDocumentWorkOrder,
  getSubUsers,
  getExcludedReponders,
  excludedRepondent,
  removeExcludedRepondent,
  createVendor,
  getVendors,
  editVendor,
  updateVendor,
  deleteVendor,
  getUserBuildingList,
  createStaff,
  getAssignees,
  getServicePermissions,
  getReportFiltersConfig,
  getRespondent,
  editWorkOrder,
  updateWorkOrder,
  deleteStaff,
  editSubUser,
  updateSubUser,
  getWorkOrderHistory,
  sendWorkOrderChatMessage,
  getWorkOrderChatHistory,
  getWorkOrderChatList,
  getChatUsers,
  updateWorkOrderStatus,
  deleteReport,
  updateReportLabel,
  getWorkOrderViolation,
  updateViolationColor,
  updateColorLabel,
  getUserData,
  getWorkOrderPdf,
  renewSession,
  getWorkOrderStatus,
  getWorkOrderCategories,
  getWorkOrderVendors,
  getStaffAllocationForWorkOrders,
  getAllTenants,
  getOneTenant,
  createTenant,
  uploadBulkTenants,
  updateTenant,
  deleteTenant,
  getSafetyMailingsBuildings,
  getAllSafetyMailingsOrder,
  getSafetyMailingsOrder,
  createSafetyMailingOrder,
  deleteSafetyMailingOrder,
  getSafetyMailingPrintedResponse,
  getSafetyMailingPrintedRow,
  safetyMailingsMarkCompletedResponseAction,
  exportSafetyMailingsOrderResponses,
  getSafetyMailingsOrderResponses,
  getActivitySnapshot,
  getS3UrlByKey,
  getRecentWorkActivity,
  getComplianceInfo,
  getSafetyMailingAutoRules,
  getSafetyMailingAutoRule,
  updateSafetyMailingAutoRules,
  createSafetyMailingAutoRules,
  deleteSafetyMailingAutoRules,
  previewSafetyMailingAutoRules,
  getSafetyMailingYears,
  getPortfolioData,
  createPortfolio,
  editPortfolio,
  deletePortfolio,
  updatePortfolio,
  getNotes,
  setCustomCategory,
  getCustomFields,
  updateCustomViolationField,
  updateCustomCategoryField,
  updateCustomCategoryFieldColorLabel,
  updateCustomCategoryFieldClearColor,
  updateCustomFieldOptions,
  toggleCustomCategoryVisibility,
  isCustomFieldHasData,
  deleteCustomCategoryField,
  createFireSafety,
  getOneFireSafety,
  updateFireSafety,
  getAllFireSafety,
  deleteFireSafety,
  previewFireSafety,
  getFireSafetyBinListOnly,
  getCustomColors,
  addColorScheme,
  getSafetyMailingsDashboard,
  updateUnitsInBuilding,
  getElevatorsElevatorsTracking,
  getAdditionalDataMainChart,
  getCustomComplianceGetAll,
  createCustomCategoryCompliance,
  deleteCustomComplianceCategory,
  getCustomCompliancesNew,
  createCustomComplianceNew,
  deleteCustomComplianceNew,
  updateAccountSetting,
  updateCustomViolationFieldBatch,
  getVersionUpdates,
  activityExcel,
  getCustomComplianceNew,
  getCustomComplianceDashboard,
  getDashboardDataSnapshot,
  getViolationsDataSnapshot,
  generatePDF,
  createExcel,
  requestDataRemoveMobileApp,
  sendPasswordReset,
  updateCustomCompliance,
  getPriority,
  setPriority,
  getAllPriority,
  updatePriority,
  getAllGuides,
  reGenerateReport,
  setReportDisplayName,
  createApiKey,
  removeApiKey,
  getApiKeys,
  getPartnerConfiguration,
  setPartnerConfiguration,
  getVisittBuilding,
  setVisittAddress,
  setPartnerConfigurationEnable,
  setIntegrationKey,
  getVisittCategories,
  syncVisittOrder,
  syncAllVisittOrders,
  checkWorkOrderCreateAbility,
  moveToVisittOrder,
  submitSafetyMailingPrintedResponse,
  getSafetyMailingEmailTemplate,
  saveSafetyMailingEmailTemplate,
  sendSafetyMailingEmail,
  setSafetyMailingEmail,
  copyFromYearTenants,
  removeNote,
  getBuidingDocuments,
  getUploaderName,
  getVisittSpaces,
  getDashboardDataCustom,
  updateDisplayAddress,
  getClientUpdateHistory,
  getAppWorkBuilding,
  setAppWorkAddress,
  getAppWorkApts,
  createEventCalendar,
  syncAppWorkOrder,
  getReportById,
  getAgencyGroups,
  modifyArchives,
  getArchives,
  moveToArchive,
  updateSearchTerms,
  getLocationEnvs,
  setLocationEnv
}
