import moment from 'moment';
import {
  UncontrolledTooltip,
  UncontrolledPopover,
  PopoverBody,
} from 'reactstrap';
import { Select, InputNumber, DatePicker, Checkbox, Button, Divider } from 'antd';
import { PlusOutlined, FilePdfOutlined } from '@ant-design/icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCopy } from '@fortawesome/free-solid-svg-icons'
import DocsViewerWrap from '../../document-viewer-wrap';
// TODO: @ check this component, if need redo
import ColumnLabelInput from '../components/ColumnLabelInput';
import LinkPicker from '../components/LinkPicker';
import ColorPicker from '../components/ColorPicker';
import LoadingInput from '../components/LoadingInput';
import ViolationFilesButton from '../components/ViolationFilesButton';
import ViolationChat from '../components/Chat';
import ViolationNotes from '../components/Notes';
import ViolationUploads from '../components/Uploads';
import guard from '../../../common/guard'

const compare = (a, b) => {
  if (a === b) {
    return 0
  }
  if (a.length > b.length) {
    return 1
  }
  if (a.length === b.length) {
    return a > b ? 1 : -1
  }

  return -1
}

const modifyColumnForTable = (
  column,
  handleColumnRemove,
  handleColumnHide,
  handleColorLabelsUpdate,
  handleCustomDataChange,
  handleShowPivotTable,
  handleSubUserRestriction,
  handleSort,
  setModalVendorColumn,
  handleColumnToggle,
  handlePriorityChange,
  copyTextFunc,
  setDataSource
) => {
  column.dataIndex = column.key;
  column.description = column.title;

  if (column.isCustom) {
    column.title = (
      <ColumnLabelInput
        onClick={(event) => event.stopPropagation()}
        className="dragHandler"
        defaultValue={column.data.name}
        field={column.data}
        columnKey={column.key}
        subUserRestriction={column.subUserRestriction}
        handleToggleVisibility={handleColumnHide}
        handleColumnDelete={handleColumnRemove}
        showPivotTableHandler={handleShowPivotTable}
        isPivotMasterProperty={column.isPivotMasterProperty}
        handleSubUserRestriction={handleSubUserRestriction}
        handleMultiSort={handleSort}
        handleColumnToggle={handleColumnToggle}
        column={column}
      />
    );
    column.showSorterTooltip = false;
    column.className = 'drag custom-columns';
    column.render = getRenderFunctionForCustomColumns(column, {
      handleCustomDataChange,
      handleColorLabelsUpdate,
      setModalVendorColumn,
      handlePriorityChange,
      setDataSource
    });
  } else {
    column.title = <ColumnLabelInput
      defaultValue={column.title}
      field={{
        cannotBeRenamed: true,
        cannotBeDeleted: true
      }}
      handleToggleVisibility={handleColumnHide}
      handleColumnToggle={handleColumnToggle}
      isPivotMasterProperty={column.isPivotMasterProperty}
      columnKey={column.key}
      column={column}
      showPivotTableHandler={handleShowPivotTable}
      handleMultiSort={handleSort}
    />;
    column.ellipsis = true;
    column.className = `default-columns ${column.fixed ? '' : 'dragHandler drag'}`;
    column.render = getRenderFunction(column, {
      copyTextFunc
    });
  }
  if (column.data?.bcType !== 'priority') {
    column.sorter = {
      compare: (a, b, order) => {
        if (a.isPriority || b.isPriority) {
          return (order === 'ascend' ? 1 : -1) * (b.isPriority ? 1 : 0) - (a.isPriority ? 1 : 0)
        }
        return getSorterFunc(column)?.(a, b)
      },
      multiple: 2,
    }
    column.sortOrder = column.sortOrder || null
  }


  column.onHeaderCell = (column) => ({ onClick: () => handleSort(column.key) })

  return column;
};

function getSorterFunc(column, columnkey = column.key) {
  if (column.data?.type === 'number') {
    return (a, b) => a[columnkey] - b[columnkey];
  } else if (
    column.data?.type === 'date' ||
    (!column.isCustom &&
      (columnkey.toLowerCase().includes('date') ||
        column.description.toLowerCase().includes('date')))
  ) {
    return (a, b) => {

      return (a[columnkey] ? moment(a[columnkey]).unix() : 0) - (b[columnkey] ? moment(b[columnkey]).unix() : 0)
    }

  } else if (column.data?.type === 'checkBox') {
    return (a, b) => (a[columnkey] ? 1 : 0) - (b[columnkey] ? 1 : 0);
  } else if (column.data?.bcType === 'files') {
    return;
  } else if (column.data?.bcType === 'chat') {
    return (a, b) => a.chats.length - b.chats.length;
  } else if (column.data?.bcType === 'notes') {
    return (a, b) => a.notes.length - b.notes.length;
  } else if (column.data?.bcType === 'uploads') {
    return (a, b) => a.documents.length - b.documents.length;
  } else if (column.data?.bcType === 'links') {
    return (a, b) => compare((a[columnkey]?.value || ''), (b[columnkey]?.value || ''))
  }
  return (a, b) => compare((a[columnkey] || ''), (b[columnkey] || ''))
}

function getRenderFunctionForCustomColumns(
  column,
  { handleCustomDataChange, handleColorLabelsUpdate, setModalVendorColumn, handlePriorityChange, setDataSource }
) {
  if (column.data?.bcType === 'colors') {
    return (text, record, index, rowKey) => {
      return (
        <ColorPicker
          data={text}
          clientDataId={record.clientDataId}
          record={record}
          field={column.data}
          handleColorLabelSelect={handleCustomDataChange}
          handleColorLabelsUpdate={handleColorLabelsUpdate}
        />
      );
    };
  } else if (column.data?.bcType === 'users') {
    return (text, record) => {
      return (
        <Select
          value={text}
          onChange={(value, row) =>
            handleCustomDataChange(record._id, {
              fieldId: column.key,
              violationFieldId: record[`${column.key}_id`],
              violationClientDataId: record.clientDataId,
              data: value,
            })
          }
          showSearch
          placeholder="Select User"
          optionFilterProp="children"
          filterOption={(input, option) =>
            option?.children?.toLowerCase()?.includes(input?.toLowerCase())
          }
          style={{ width: '100%' }}
        >
          <Select.Option name="Sub Users" value={''}></Select.Option>
          {column.subUsers?.filter((item) => column.subUserRestriction ? record.validUsers?.includes(item._id) : true).map((item) => {
            return (
              <Select.Option key={item._id} value={item._id} name="Sub Users">
                {item.fullName}
              </Select.Option>
            );
          })}
        </Select>
      );
    };
  } else if (column.data?.bcType === 'vendors') {
    return (text, record, index, rowKey) => {

      return (
        <Select
          value={text}
          onChange={(value, row) => {
            handleCustomDataChange(record._id, {
              fieldId: column.key,
              violationFieldId: record[`${column.key}_id`],
              violationClientDataId: record.clientDataId,
              data: value,
            })
          }
          }
          showSearch
          placeholder="Select Vendor"
          optionFilterProp="children"
          filterOption={(input, option) =>
            option?.children?.toLowerCase()?.includes(input?.toLowerCase())
          }
          style={{ width: '100%' }}
          dropdownRender={(menu) => (
            <>
              {menu}
              {guard({ serviceName: 'violations', action: 'create_vendor' }) && <><Divider style={{ margin: '8px 0' }} />
                <Button type="text" icon={<PlusOutlined />} onClick={() => setModalVendorColumn({
                  key: column.key, _id: record._id, updateData: {
                    fieldId: column.key,
                    violationFieldId: record[`${column.key}_id`],
                    violationClientDataId: record.clientDataId,
                  }
                })} className='w-100'>
                  Add Vendor
                </Button>
              </>
              }
            </>
          )}
        >
          <Select.Option value={''} name="Vendors"></Select.Option>
          {column.vendors?.sort((a, b) => a.fullName > b.fullName ? 1 : -1).map((item) => {
            return (
              <Select.Option key={item._id} value={item._id} name="Vendors">
                {item.fullName}
              </Select.Option>
            );
          })}
        </Select>
      );
    };
  } else if (column.data?.type === 'text') {
    return (text, record, index) => {
      return (
        <LoadingInput
          onChange={async (value) => {
            await handleCustomDataChange(record._id, {
              fieldId: column.key,
              violationFieldId: record[`${column.key}_id`],
              violationClientDataId: record.clientDataId,
              data: value,
            });
          }}
          data={text}
        />
      );
    };
  } else if (column.data?.type === 'number') {
    return (text, record, index) => (
      <InputNumber
        style={{ width: '100%' }}
        onChange={async (value) => {
          await handleCustomDataChange(record._id, {
            fieldId: column.key,
            violationFieldId: record[`${column.key}_id`],
            violationClientDataId: record.clientDataId,
            data: value,
          });
        }}
        value={text}
      />
    );
  } else if (column.data?.type === 'date' || column.data?.type === 'date_reminders') {
    return (text, record, index) => {
      const value = text ? moment(text, ['YYYY-MM-DD', 'MM/DD/YYYY']) : ''
      return <DatePicker
        value={value}
        format={['MM/DD/YYYY', 'M/D/YYYY']}
        style={{ width: '100%' }}

        onChange={async (date, dateString) => {
          await handleCustomDataChange(record._id, {
            fieldId: column.key,
            violationFieldId: record[`${column.key}_id`],
            violationClientDataId: record.clientDataId,
            data: dateString,
          });
        }}
      />
    }

  } else if (column.data?.type === 'checkBox') {
    column.className += ' checkbox-violation-table p-0'
    return (text, record, index) => {
      return (
        <Checkbox
          className="py-1"
          onChange={async (event) => {
            await handleCustomDataChange(record._id, {
              fieldId: column.key,
              violationFieldId: record[`${column.key}_id`],
              violationClientDataId: record.clientDataId,
              data: event.target.checked,
            });
          }}
          checked={text}
        />
      );
    };
  } else if (column.data?.bcType === 'priority') {
    column.className += ' checkbox-violation-table p-0'
    return (text, record, index) => {
      return (
        <Checkbox
          onChange={async (event) => {
            await handlePriorityChange(record._id, event.target.checked);
          }}
          checked={record.isPriority ? true : false}
        />
      );
    };
  } else if (column.data?.bcType === 'files') {
    return (text, record, index, rowKey) => (
      <ViolationFilesButton
        setDataSource={setDataSource}
        record={record}
        fieldId={column.key}
        category={column.category}
        source={column.sourceUpload}
      />
    );
  } else if (column.data?.bcType === 'chat') {
    return (text, record, index, rowKey) => {
      return <ViolationChat record={record} source={column.sourceUpload} />;
    };
  } else if (column.data?.bcType === 'notes') {
    return (text, record, index, rowKey) => {
      return <ViolationNotes record={record} source={column.sourceUpload} />;
    };
  } else if (column.data?.bcType === 'uploads') {
    return (text, record, index, rowKey) => {
      return <ViolationUploads setDataSource={setDataSource} record={record} category={column.category} source={column.sourceUpload} />;
    };
  } else if (column.data?.bcType === 'links') {
    column.className += ' link-column'
    return (text, record, index, rowKey) => {
      return <LinkPicker data={text} onChange={(newValue) => {
        handleCustomDataChange(record._id, {
          fieldId: column.key,
          violationFieldId: record[`${column.key}_id`],
          violationClientDataId: record.clientDataId,
          data: newValue,
        })
      }} />;
    };
  }

  return;
}

function getRenderFunction(column, { copyTextFunc }) {
  if (column.linkFunc) {
    return function (text, record, index) {
      return (
        <span title={text} className='noselect'>
          <a href={column.linkFunc(record)}>
            <span
              className="color-mark-badge-table"
              style={
                record.colorMark ? { background: `${record.colorMark}` } : {}
              }
              id={`table_colorMarkToolTip_${record._id}`}
            />
            {record.color && record.color.length > 0 && (
              <UncontrolledTooltip
                placement="right"
                autohide={true}
                target={`table_colorMarkToolTip_${record._id}`}
              >
                {record.color}
              </UncontrolledTooltip>
            )}

            {record.retained ? (
              <>
                <div>{text}</div>
                <span
                  className="badge"
                  style={{ background: '#c36c00', color: 'white' }}
                >
                  Retained
                </span>
              </>
            ) : (
              <>{text}</>
            )}
          </a>

          <FontAwesomeIcon icon={faCopy} size="sm" className='ml-2' style={{ color: "rgb(190 190 190)" }} onClick={
            (event) => {
              copyTextFunc(text, event.pageX, event.pageY);
            }
          } />

        </span>
      );
    };
  } else if (column.key === 'ordernumber') {
    return (text, record, index) => (
      <div
        key={record._id}
        className="position-relative hoverEffect"
        style={{
          display: 'flex',
          justifyContent: 'center',
          borderLeft: `5px solid ${record?.codeColor}`,
        }}
      >
        <div className="btn p-0 text-dark" id={`${column.key}${index}`}>
          <UncontrolledPopover
            placement="left"
            trigger="hover"
            target={`${column.key}${index}`}
          >
            <PopoverBody>{record?.codeName || '-'}</PopoverBody>
          </UncontrolledPopover>
          <span>{text}</span>
        </div>
      </div>
    );
  } else if (column.title === 'Description') {
    return (text, record, index) => (
      <div key={record._id} className="position-relative hoverEffect">
        <button
          className="btn btn-link p-0 text-dark"
          id={`${column.title}${index}`}
        >
          <UncontrolledPopover
            placement="left"
            trigger="hover"
            target={`${column.title}${index}`}
          >
            <PopoverBody>{text || '-'}</PopoverBody>
          </UncontrolledPopover>
          {text?.substring(0, 10)}...
        </button>
      </div>
    );
  } else if (
    !column.isCustom &&
    (column.key.toLowerCase().includes('date') ||
      column.description.toLowerCase().includes('date'))
  ) {
    return (text, record, index) => {

      let dateJS = new Date(text)
      if (dateJS.getTimezoneOffset() > 0) {
        dateJS.setHours(dateJS.getHours() + new Date().getTimezoneOffset() / 60);
      }
      const date = moment.utc(dateJS);

      return <>{date.isValid() && text ? date.format('MM/DD/YYYY') : text}</>;
    };
  } else {

    return (text, record, index) => {
      if (column.key === 'savedSummonPdf') {


        if (!text && record.ticket_number) {
          return <DocsViewerWrap
            document={{ file: { mimetype: 'pdf' }, awsUrl: 'https://a820-ecbticketfinder.nyc.gov/GetViolationImage?violationNumber=' + record.ticket_number }}
            useAWSURL={true}
            disableChat={true}
            customName={'Summon PDF'}
          ><FilePdfOutlined style={{ fontSize: '28px' }} /></DocsViewerWrap>
        }
        return <DocsViewerWrap customName={'Summon PDF'} currentKey={text} useAWSURL={true}><FilePdfOutlined style={{ fontSize: '28px', color: '#08c' }} /></DocsViewerWrap>
      }
      return <span title={text}>
        {column.key === 'address' && (
          <a className='pr-1' href={`/violations/all/${record.bin}`}>
            <i className="fa fa-building" aria-hidden="true"></i>
          </a>)
        }
        {text || '-'}
      </span>
    }
  }
}

export { modifyColumnForTable, getRenderFunctionForCustomColumns, getSorterFunc };
