import React, { useEffect, useState } from 'react';
import {
  PlusCircleOutlined,
  UserAddOutlined,
  FileTextOutlined,
  CalendarOutlined,
  BgColorsOutlined,
  MessageOutlined,
  FileOutlined,
  UploadOutlined,
  CheckSquareOutlined,
  FolderOpenOutlined,
  LinkOutlined,
  HighlightOutlined
} from '@ant-design/icons';
import { Button, Menu, Dropdown, Switch } from 'antd';

//Dropdown to add custom columns to violation table
const AddCustomColumnDropdown = ({
  columns,
  addColumn,
  addColumnSwitchHandler,
  isShortVersion
}) => {
  const [switchValues, setSwitchValues] = useState({});

  useEffect(() => {
    let chatExist;
    let uploadExist;
    let notesExist;
    let priorityExist;
    columns.forEach((customColumn) => {
      if (customColumn.isCustom) {
        if (customColumn.data.bcType === 'chat' && !customColumn.hide) {
          chatExist = true;
        }
        if (customColumn.data.bcType === 'uploads' && !customColumn.hide) {
          uploadExist = true;
        }
        if (customColumn.data.bcType === 'notes' && !customColumn.hide) {
          notesExist = true;
        }
        if (customColumn.data.bcType === 'priority' && !customColumn.hide) {
          priorityExist = true
        }
      }
    });
    setSwitchValues({ chatExist, uploadExist, notesExist, priorityExist });
  }, [columns]);

  return (
    <Dropdown
      overlay={
        <Menu
          items={[
            {
              label: (
                <div>
                  <div className="d-flex align-items-center ">
                    <UserAddOutlined />
                    <div className="ml-1">Sub Users</div>
                  </div>
                </div>
              ),
              key: 'subUsers',
              onClick: () => addColumn('Sub Users', null, 'bcType', 'users'),
            },
            {
              label: (
                <div>
                  <div className="d-flex align-items-center">
                    <UserAddOutlined />
                    <div className="ml-1">Vendors</div>
                  </div>
                </div>
              ),
              key: 'vendors',
              onClick: () => addColumn('Vendors', null, 'bcType', 'vendors'),
            },
            {
              label: (
                <div>
                  <div className="d-flex align-items-center">
                    <FileTextOutlined />
                    <div className="ml-1">Text</div>
                  </div>
                </div>
              ),
              key: 'textField',
              onClick: () => addColumn('Text', null, 'text', null),
            },
            {
              label: (
                <div>
                  <div className="d-flex align-items-center">
                    <FileTextOutlined />
                    <div className="ml-1">Number</div>
                  </div>
                </div>
              ),
              key: 'numberField',
              onClick: () => addColumn('Number', null, 'number', null),
            },
            {
              label: (
                <div>
                  <div className="d-flex align-items-center">
                    <LinkOutlined />
                    <div className="ml-1">Link</div>
                  </div>
                </div>
              ),
              key: 'link',
              onClick: () => addColumn('Link', null, 'bcType', 'links'),
            },
            {
              label: (
                <div>
                  <div className="d-flex align-items-center">
                    <CalendarOutlined />
                    <div className="ml-1">Date</div>
                  </div>
                </div>
              ),
              key: 'datePicker',
              onClick: () => addColumn('Date', null, 'date', null),
            },
            {
              label: (
                <div>
                  <div className="d-flex align-items-center">
                    <CalendarOutlined />
                    <div className="ml-1"> Reminders</div>
                  </div>
                </div>
              ),
              key: 'dateReminders',
              onClick: () => addColumn('Reminders', null, 'date_reminders', null),
            },
            {
              label: (
                <div>
                  <div className="d-flex align-items-center">
                    <BgColorsOutlined />
                    <div className="ml-1">Color</div>
                  </div>
                </div>
              ),
              key: 'color',
              onClick: () => addColumn('Color', null, 'bcType', 'colors'),
            },
            {
              label: (
                <div>
                  <div className="d-flex align-items-center">
                    <CheckSquareOutlined />
                    <div className="ml-1">Checkbox</div>
                  </div>
                </div>
              ),
              key: 'checkBox',
              onClick: () => addColumn('CheckBox', null, 'checkBox', null),
            },
            {
              label: (
                <div>
                  <div className="d-flex align-items-center justify-content-between">
                    <div className="d-flex align-items-center">
                      <MessageOutlined />
                      <div className="ml-1">Chat</div>
                    </div>
                    <Switch checked={switchValues.chatExist} size="small" />
                  </div>
                </div>
              ),
              onClick: () =>
                addColumnSwitchHandler('Chat', null, 'bcType', 'chat'),

              key: 'chat',
            },
            {
              label: (
                <div>
                  <div className="d-flex align-items-center justify-content-between">
                    <div className="d-flex align-items-center">
                      <FileOutlined />
                      <div className="ml-1">Notes</div>
                    </div>
                    <Switch
                      className="ml-2"
                      checked={switchValues.notesExist}
                      size="small"
                    />
                  </div>
                </div>
              ),
              onClick: () =>
                addColumnSwitchHandler('Notes', null, 'bcType', 'notes'),
              key: 'notes',
            },
            {
              label: (
                <div>
                  <div className="d-flex align-items-center justify-content-between">
                    <div className="d-flex align-items-center justify-content-between">
                      <UploadOutlined />
                      <div className="ml-1">Uploads</div>
                    </div>
                    <Switch
                      className="ml-2"
                      checked={switchValues.uploadExist}
                      size="small"
                    />
                  </div>
                </div>
              ),
              key: 'uploads',
              onClick: () =>
                addColumnSwitchHandler('Uploads', null, 'bcType', 'uploads'),
            },
            {
              label: (
                <div>
                  <div className="d-flex align-items-center justify-content-between">
                    <div className="d-flex align-items-center">
                    <HighlightOutlined />
                      <div className="ml-1">Priority</div>
                    </div>
                    <Switch
                      className="ml-2"
                      checked={switchValues.priorityExist}
                      size="small"
                    />
                  </div>
                </div>
              ),
              onClick: () =>
                addColumnSwitchHandler('Priority', null, 'bcType', 'priority'),
              key: 'priority',
            },
            {
              label: (
                <div>
                  <div className="d-flex align-items-center justify-content-between">
                    <div className="d-flex align-items-center justify-content-between">
                      <FolderOpenOutlined />
                      <div className="ml-1">Files</div>
                    </div>
                  </div>
                </div>
              ),
              key: 'files',
              onClick: () => addColumn('Files', null, 'bcType', 'files'),
            },

          ]}
        />
      }
      placement="bottom"
      arrow
    >
        {isShortVersion
            ? <Button><PlusCircleOutlined /></Button>
            : <Button className="hide-btn-wrapper">Add Column<PlusCircleOutlined /></Button>}

    </Dropdown>
  );
};

export default AddCustomColumnDropdown;
