import React, { Fragment } from "react";
import ReactLoading from "react-loading";


const ViolationsTabEachBlock = (props) => {

  function onClick(currentValue) {
    if(currentValue[1] > 0) {
      localStorage.setItem('prev-location', '/permits');
      props.history.push(`/permits-details/${props.title}/${currentValue[0]}`)
    }
  }

  return (
    <Fragment>
      <div className="col-6 col-xl-6 col-md-6 col-sm-6 d-flex">
        <div className="card fix-card flex-fill">
          <h4>{props.title}</h4>
          <div className="card-body">
            <div className="media-body">
              {!props.loading ?
                    Object.entries(props.data).map((value, index) =>{

                      return <h6 key={ index } className={(value[1] > 0 ? 'pointer' : '')} onClick={() => onClick(value)}>{
                        (typeof value[0] === "string") ? value[0].replace('_', ' ').replace('__', ' ') : value[0]
                      }: <span>{value[0] === "Fines" ? "$" : ''}{ value[1] } </span> </h6>
                    })

                  : <div align="center"> <ReactLoading type={"cylon"} color={"grey"} /> </div>}
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default ViolationsTabEachBlock;
