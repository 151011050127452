import React, { Fragment, useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';
import { useHistory } from 'react-router-dom';

import creService from "../../services/creService";
import { isTenantEditingAvaliable } from "../../services/helperService";
import localState, { SAFETY_MAILING_CART_KEY } from '../../common/localState';
import SafetyMailingCart from './cart';
import ControlledLoader from "../../common/controlledLoader";

const getBuildingColor = (building) => {
    if (building.withoutTenant || building.noFireSafe) {
        return 'red'
    } else if (building.orderExist) {
        return 'green'
    }
}

const SafetyMailingCreate = ({ jestData = false }) => {
    const [cartData, setCartData] = useState(jestData ? jestData.cartData : []);

    const [buildings, setBuildings] = useState(jestData ? jestData.buildings : []);
    const [formData, setFormData] = useState(jestData ? jestData.formData : { building: {} });
    const [selectedAptNumbers, setSelectedAptNumbers] = useState(jestData ? jestData.selectedAptNumbers : []);
    const [year] = useState(() => {
        const currentDate = new Date();
        if (currentDate.getMonth() < 3) {
            return currentDate.getFullYear();
        } else {
            return currentDate.getFullYear() + 1;
        }
    });
    const [isLoading, setLoading] = useState(false);
    const history = useHistory();

    const building = buildings?.find((_b) => parseInt(_b.bin) === parseInt(formData?.building?.bin)) || {};
    const aptNumbers = building?.aptNumbers || [];
    const cartBins = cartData?.map((_item) => parseInt(_item.building.bin));
    const [isSendingAvaliable] = useState(isTenantEditingAvaliable(year));

    const fetchBuildings = async () => {
        try {
            setLoading(true);
            const { data: fireSafety } = await creService.getAllFireSafety({ bins: [] });
            const { data } = await creService.getSafetyMailingsBuildings(year);

            for (let building of [...data.withoutTenants, ...data.data]) {

                if (!fireSafety.data.some(fireSafe => building.bin === fireSafe.bin)) {
                    building.noFireSafe = true
                }
            }


            if (data.status) {
                setBuildings([...data.data, ...data.orderExisit, ...data.withoutTenants]);
            } else {
                toast.error(data.message);
            }
        } catch (_err) {
            toast.error(_err.message);
        } finally {
            setLoading(false);
        }
    }

    const onChange = (field, value, event = null) => {
        let element = null;
        if (event) {
            const index = event.target.selectedIndex;
            element = event.target.childNodes[index];
        }
        const isbuilding = field === 'building'
        if (isbuilding) {
            const currentBuilding = buildings.find(el => el.bin === Number(value))

            if (currentBuilding.noFireSafe) {
                history.push({ pathname: "/fire-safety/create", search: `?bin=${value}` })
                return
            } else if (currentBuilding.withoutTenant) {
                history.push({ pathname: `/tenants/list/${year}` })
                return
            } else if (currentBuilding.orderExist) {
                history.push({ pathname: "/safety-mailings" })
                return
            }

        }
        setFormData((prev) => ({
            ...prev,
            [field]: field === 'building' ? {
                bin: value,
                address: element?.getAttribute('data-address'),
            } : value,
        }));
    };

    const onSubmit = async (event) => {
        event.preventDefault();
        if (selectedAptNumbers.length > 0) {
            const payload = {
                ...formData,
                aptNumbers: selectedAptNumbers,
            }
            const alreadyExists = cartData.findIndex((_item) => String(_item.building.bin) === String(payload.building.bin));
            console.log('alreadyExists', alreadyExists);
            if (alreadyExists === -1) {
                const updatedCart = [
                    ...cartData,
                    payload,
                ];
                localState.setItem(SAFETY_MAILING_CART_KEY, updatedCart)
                setCartData(updatedCart);
            } else {
                const updatedCart = [
                    ...cartData,
                ];
                updatedCart[alreadyExists] = payload;
                localState.setItem(SAFETY_MAILING_CART_KEY, updatedCart)
                setCartData(updatedCart);
            }
            setFormData({ building: {} });
            toast.success('This order has been added to your Safety Mailing Orders Cart.');
        } else {
            toast.error('Please select atleast one Unit/Apt#.');
        }
    };

    const onAptNumberSelect = (state, aptNumber) => {
        if (state) {
            setSelectedAptNumbers((prev) => [...prev, aptNumber]);
        } else {
            setSelectedAptNumbers((prev) => prev.filter((_aptNumber) => _aptNumber !== aptNumber));
        }
    }

    const selectAllAptNumbers = (state, aptNumbers) => {
        if (state) {
            setSelectedAptNumbers(aptNumbers);
        } else {
            setSelectedAptNumbers([]);
        }
    }

    const onEditCartItem = (item) => {
        setFormData(item);
        setSelectedAptNumbers(item.aptNumbers);
    }

    const parseAddress = (address) => {
        const addressArr = address?.replace(/_/, '')?.replace(/-/, '')?.split(' ') || [];
        const buildingNumber = typeof addressArr[0] !== 'undefined' ? addressArr[0].trim() : '';
        const city = typeof addressArr[addressArr.length - 2] !== 'undefined' ? addressArr[addressArr.length - 2].trim() : '';
        const zipcode = typeof addressArr[addressArr.length - 1] !== 'undefined' ? addressArr[addressArr.length - 1].trim() : '';
        addressArr.splice(0, 1); // remove building number
        addressArr.splice((addressArr.length - 2), 1); // remove city
        addressArr.splice((addressArr.length - 1), 1); // remove zipcode
        const streetAddress = addressArr.join(' ') || '';
        const parsedMailingAddress = {
            buildingNumber,
            streetAddress,
            city,
            zipcode,
            state: 'NY',
        }
        return parsedMailingAddress;
    }

    useEffect(() => {

        fetchBuildings();
        const currentCart = localState.getItem(SAFETY_MAILING_CART_KEY) || [];
        setCartData(currentCart);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if ((formData.addressLine1 || '').trim().length <= 0 || (formData.zipcode || '').trim().length <= 0) {
            const address = formData.building.display_address ? formData.building.display_address : formData.building.address;
            const mailingAddress = parseAddress(address);
            setFormData((prev) => ({
                ...prev,
                addressLine1: `${mailingAddress.buildingNumber} ${mailingAddress.streetAddress}`.replace(/(^,)|(,$)/g, ""),
                city: mailingAddress.city,
                state: mailingAddress.state,
                zipcode: mailingAddress.zipcode,
            }))
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [formData.building])

    return (
        <Fragment>
            {isLoading && <ControlledLoader show={isLoading} />}
            <div className="row violation-sec">
                <div className="col-xl-12 my-3">
                    <div className="card">
                        <div className="card-header">
                            <Link to='/safety-mailings'>
                                <button className="btn btn-light btn-lg align-self-center back-btn mx-2" type="button">
                                    <i className="fa fa-angle-left" />
                                </button>
                            </Link>
                            <h5 style={{ flexBasis: '100%' }}>Send New Mailing</h5>
                            <h5>{year}</h5>
                        </div>
                        <div className="card-body row">
                            <form method="post" className="col-12 col-md-8" onSubmit={onSubmit}>
                                <div className="row">
                                    <div className="form-group col-12">
                                        <label>Building</label> 
                                        <select className="form-control" name="building" onChange={(e) => onChange(e.target.name, e.target.value, e)}>
                                            <option value="">-- Select Building --</option>
                                            {isSendingAvaliable && buildings && buildings.map((building) => {
                                                if (!cartBins.includes(building.bin) || String(formData?.building?.bin) === String(building.bin)) {
                                                    return (
                                                        <option style={{ color: getBuildingColor(building) }} key={`building_${building.bin}`} data-address={building.display_address ? building.display_address : building.address} value={building.bin} selected={String(formData?.building?.bin) === String(building.bin)}>
                                                            {building.display_address ? building.display_address : building.address}

                                                            {building.noFireSafe && ' - [Information Missing: Fire Safety]'}
                                                            {building.withoutTenant && ' - [Information Missing: Tenants]'}
                                                            {building.orderExist && ' - [Order Complited]'}
                                                        </option>);
                                                }
                                                return null;
                                            })}
                                        </select>
                                    </div>
                                </div>
                                {/* {!isBuildingAvailable && <div className="alert alert-info">
                                    You have already created the orders for this years for the all the buildings with tenant information provided
                                </div>}*/}
                                {!isSendingAvaliable && <div className="alert alert-info">
                                    You unable to send new mailing right now
                                </div>}
                                {formData.building.bin && (
                                    <>
                                        <h5 className="font-weight-bold">
                                            Mailing Address
                                        </h5>
                                        <div className="row">
                                            <div className="form-group col-4">
                                                <label>Address Line 1</label>
                                                <input type="text" className="form-control" name="addressLine1" value={formData.addressLine1} onChange={(e) => onChange(e.target.name, e.target.value, e)} />
                                            </div>
                                            <div className="form-group col-3">
                                                <label>City</label>
                                                <input type="text" className="form-control" name="city" value={formData.city} onChange={(e) => onChange(e.target.name, e.target.value, e)} />
                                            </div>
                                            <div className="form-group col-3">
                                                <label>State</label>
                                                <input type="text" className="form-control" name="state" value={formData.state} onChange={(e) => onChange(e.target.name, e.target.value, e)} />
                                            </div>
                                            <div className="form-group col-2">
                                                <label>Zipcode</label>
                                                <input type="text" className="form-control" name="zipcode" value={formData.zipcode} onChange={(e) => onChange(e.target.name, e.target.value, e)} />
                                            </div>
                                        </div>

                                        <div className="d-flex">
                                            <h5 className="font-weight-bold">
                                                Unit/Apt#
                                            </h5>
                                            <div className="form-group ml-5">
                                                <input type="checkbox" className="form-check-input mt-1" checked={selectedAptNumbers && (aptNumbers.length === selectedAptNumbers.length)} onChange={(event) => selectAllAptNumbers(event.target.checked, aptNumbers)} id="apt_num_check_select_all" />
                                                <label htmlFor="apt_num_check_select_all">Select/Deselect All</label>
                                            </div>
                                        </div>
                                        <div className="row">
                                            {aptNumbers.map((aptNumber) => {
                                                return (
                                                    <div className="col-6 col-md-2" key={`${aptNumber}_apt_num_check`}>
                                                        <div className="form-check">
                                                            <input type="checkbox" className="form-check-input mt-1" checked={selectedAptNumbers && selectedAptNumbers.includes(aptNumber)} onChange={(event) => onAptNumberSelect(event.target.checked, aptNumber)} id={`${aptNumber}_apt_num_check`} />
                                                            <label className="form-check-label" htmlFor={`${aptNumber}_apt_num_check`}>{aptNumber}</label>
                                                        </div>
                                                    </div>
                                                )
                                            })}
                                        </div>
                                        <button className="btn btn-info mt-4">Add to Cart</button>
                                    </>
                                )}
                            </form>
                            <div className="col-12 col-md-4">
                                <SafetyMailingCart cartData={cartData} setCartData={setCartData} onEdit={onEditCartItem} />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </Fragment>
    );
}

export default SafetyMailingCreate;
