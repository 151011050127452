import jwtDecode from "jwt-decode";
import http from "./httpService";
import config from "../../config.json";
import {toast} from "react-toastify";

const apiUrl = config.apiUrl;

const apiEndpoint = apiUrl + "/api/login";
const tokenKey = "cre-token";
export const settingsKey = "cre-settings";
export const servicesAccessKey = "cre-services-access";
export const permissionsKey = "cre-user-permissions";
export const workOrderExpireMessageKey = "cre-work-orders-expire-message";


http?.setJwt(getJwt());

export async function login(email, password, platform = "web", jwt) {
  try {
    const { data, status } = await http.post(apiEndpoint, { email, password, platform, jwt }, {
      validateStatus: function (status) {
        return status < 500;
      }
    });
    if (status !== 200) {
      toast.error(data);
      return false;
    }
    localStorage.setItem(tokenKey, data.token);
    if (data.settings) {
      localStorage.setItem(settingsKey, JSON.stringify(data.settings));
    }
    if (data.servicesAccess) {
      localStorage.setItem(servicesAccessKey, JSON.stringify(data.servicesAccess));
      const dayRemaining = data.servicesAccess.workOrders?.dayRemaining;
      if (dayRemaining && dayRemaining <= 14) {
        localStorage.setItem(workOrderExpireMessageKey, true);
      }
    }
    if (data.permissions) {
      localStorage.setItem(permissionsKey, JSON.stringify(data.permissions));
    }

    if (data.portfolioId) {
      localStorage.setItem('portfolioId', data.portfolioId);
    }
    return true;
  } catch (e) {
    return false;
  }
}

export async function isLoggedIn() {
  return await http.post(apiUrl + "/api/getMenu", {});
}

export async function loginWithJwt(jwt) {
  logout()
  await login(undefined, undefined, undefined, jwt)
}

export function logout(socket, user, force = false) {
  localStorage.removeItem(tokenKey);
  localStorage.removeItem(settingsKey);
  localStorage.removeItem(servicesAccessKey);
  localStorage.removeItem(permissionsKey);
  localStorage.removeItem(workOrderExpireMessageKey);
  localStorage.removeItem('portfolioId');
  if (socket && user) {
    socket.emit('leave', user);
    socket.disconnect();
  }
  if (force) {
    sessionStorage.removeItem('lastPath');
  }
}

export function getCurrentUser() {
  try {
    const jwt = localStorage.getItem(tokenKey);
    const jwtDecoded = jwtDecode(jwt);
    if (jwtDecoded.exp < (new Date().getTime() + 1) / 1000) {
      return false;
    }
    return jwtDecoded;
  } catch (ex) {
    return null;
  }
}

export const isMasterClient = !getCurrentUser()?.isSubUser;

export function getJwt() {
  return localStorage.getItem(tokenKey);
}

// eslint-disable-next-line import/no-anonymous-default-export
export default {
  login,
  loginWithJwt,
  logout,
  getCurrentUser,
  getJwt,
  isLoggedIn,
  isMasterClient
};
