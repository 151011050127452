import React, { useState, useEffect, useContext } from "react";
import {
    Button,
    Form,
    FormGroup,
    Label,
    Input,
} from 'reactstrap';
import { useLocation } from 'react-router-dom';
import './chat.css';
import creApi from '../../../../services/creService';
import auth from '../../../../services/authService';
import { toast } from "react-toastify";
import moment from "moment";
import ReactLoading from "react-loading";
import { SocketContext } from '../../../../../context/socket';

const Message = ({ user, type, senderName, message, date }) => {
    return (
        <div className={`chat-message ${type.toLowerCase()} ${message.isPrivate ? 'private' : ''}`} style={{ whiteSpace: 'pre-wrap' }}>
            {message.message}
            <div className="author">
                Sent by {senderName} at {moment(message.date).format('LT')} on {moment(message.date).format('MM/DD/YYYY')}
            </div>
            {message.isPrivate && (<span className="chat-badge chat-badge-private"><i className="fa fa-key" /> Private</span>)}
            {(!message.viewers.includes(user._id)) &&
                <span className="chat-badge chat-badge-new" style={message.isPrivate ? { marginRight: 65 } : {}}>New</span>
            }
        </div>
    );
};

const Chat = ({ workOrderId, bin }) => {

    const user = auth.getCurrentUser();
    const location = useLocation();
    const socket = useContext(SocketContext);

    const [sending, setSending] = useState(false);
    const [message, setMessage] = useState("");
    const [chats, setChats] = useState([]);
    const [loading, setLoading] = useState(false);
    const [chatUsers, setChatUsers] = useState([]);
    const [receivers, setReceivers] = useState([]);
    const [sentTo, setSendTo] = useState(null);

    useEffect(() => {
        if (workOrderId) {
            chatHistoryData();
            getChatUsers(workOrderId);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const chatHistoryData = async () => {
        setLoading(true);
        try {
            let results = await creApi.getWorkOrderChatHistory(workOrderId);
            setChats(results.data.messages);
            if (location.hash === "#chatMessage") {
                window.scrollTo(0, document.body.scrollHeight);
            }
        } catch (e) {
            toast.error(e);
        }
        setLoading(false);
    }

    const getChatUsers = async (workOrderId) => {
        setLoading(true);
        try {
            const res = await creApi.getChatUsers(workOrderId);
            if (res.data.status) {
                setChatUsers(res.data.data);
            } else {
                toast.error(res.data.message);
            }
        } catch (e) {
            toast.error(e);
        }
        setLoading(false);
    }

    const handleSelectUser = (subUserId) => {
        if (subUserId === "ALL") {
            setReceivers([]);
            setSendTo(null);
        } else {
            setSendTo(subUserId);
            setReceivers([subUserId]);
        }
    }

    const handleSubmit = async (e) => {
        e.preventDefault();
        let data = {
            workOrderId,
            message,
            isPrivate: sentTo !== null,
            bin,
            receivers,
        }
        setSending(true);
        try {
            const res = await creApi.sendChatMessage(data);
            if (res.data.status === true) {
                setMessage('');
                document.getElementById("send-message-from-thread").reset();
                console.log(socket)
                // Adding New Message
                if (!socket?.connected) {
                    addNewChatMessage(res.data.chatMessage);
                }
            } else {
                toast.error(res.data.message);
            }
            setSending(false);
        } catch (_e) {
            toast.error(_e);
            setSending(false);
        }
    }

    const addNewChatMessage = (chatMessage) => {
        setChats((oldChats) => [
            ...oldChats,
            chatMessage,
        ]);
        setTimeout(() => {
            window.scrollTo(0, document.body.scrollHeight);
        }, 100)
    }

    useEffect(() => {
        if (socket) {
            socket.on('work-order-chat-message', (message) => {
                if (message.newMessage.workOrderId === workOrderId) {
                    addNewChatMessage(message.newMessage)
                }
            });
            return () => socket.off('work-order-chat-message', (message) => addNewChatMessage(message.newMessage));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [socket]);

    return (
        <div className="chat-wrapper mt-3">
            {loading ? (<div className="loadercenter"> <ReactLoading type={"cylon"} color={"grey"} /> </div>) :
                (
                    <>
                        <div className="messages-wrapper">
                            {chats.length > 0 && chats.map((chat, index) => <Message
                                key={index}
                                user={user}
                                senderName={chat.senderType === 'admin' ? `${chat.sender.name} (support)` : chat.sender.fullName}
                                type={chat.sender._id === user._id ? 'sent' : 'received'}
                                message={chat}
                            />)}
                            {chats.length <= 0 &&
                                <div className="bg-gray">No Chat Yet</div>
                            }
                        </div>
                        <Form className="send-message" id="send-message-from-thread" onSubmit={(e) => handleSubmit(e)}>
                            {
                                chatUsers.length > 0 && (
                                    <FormGroup>
                                        <Label htmlFor="userSelect">Sent to</Label>

                                        <Input type="select" name="select" onChange={(e) => handleSelectUser(e.target.value)} id="userSelect">
                                            <option value="ALL">All (Public)</option>
                                            {chatUsers.length > 0 && chatUsers.map((subUser) => {
                                                return (
                                                    user._id !== subUser._id.toString() &&
                                                    <option value={subUser._id}>{subUser.fullName} (Private)</option>
                                                )
                                            })}
                                        </Input>

                                    </FormGroup>
                                )}
                            <FormGroup>
                                <Label htmlFor="chatMessage">Enter your message <sup className="text-danger">*</sup></Label>
                                <Input type="textarea" required name="text" id="chatMessage" onKeyUp={(e) => setMessage(e.target.value)} />
                            </FormGroup>
                            <FormGroup className="mt-3">
                                <Button type="submit" color="primary" disabled={sending}>{sending ? "Sending..." : "Send"}</Button>
                            </FormGroup>
                        </Form>
                    </>
                )}
        </div>
    )
}

export default Chat;
