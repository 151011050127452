import React from 'react';

const FieldWidget = ({ name, value, faIcon }) => {
    return (
        <div className="card">
            <div className="card-body p-0 text-dark d-flex">
                <div className="icon d-flex align-items-center rounded-left bg-blue text-white p-3">
                    <i className={`fa fa-${faIcon || 'book'} fa-2x`} />
                </div>
                <div className="content d-flex flex-column w-100 text-left p-2">
                    <label className="font-weight-bold text-blue">{name || '-'}</label>
                    <span style={{ wordBreak: 'break-all' }}>
                        {value ?
                            typeof value === 'string' ? value.charAt(0).toUpperCase() + value.slice(1) : value
                            : '-'}
                    </span>
                </div>
            </div>
        </div>
    )
}

export default FieldWidget;
