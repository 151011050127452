import React, { Fragment, useEffect, useState } from "react";
import { Table } from "reactstrap";
import { toast } from "react-toastify";
import creService from "../../services/creService";
import BuidlingBasedCompilance from "./partials/buidlingBasedCompilance";
import ElevatorBasedCompilance from "./partials/elevatorBasedCompilance";
import HPDRegistrationCompilance from "./partials/hpdRegistrationCompilance";
import DepBoilerCompilance from "./partials/depBoilerCompilance";
import { Link } from 'react-router-dom';
import moment from 'moment';
import { DatePicker } from 'antd';
import ReactLoading from "react-loading";

const Compliance = (props) => {

    const [dataTable, setDataTable] = useState([]);
    const [loading, setLoading] = useState(true);
    const [info, setInfo] = useState("");
    const [year, setYear] = useState()

    const loadingComponent = async (category) => {
        try {
            setLoading(true);
            const api = await creService.getComplianceRequirements(category, year);
            const data = await creService.getComplianceInfo(category);
            setInfo(data?.data?.complianceInfo?.text);
            setDataTable(api.data);
            setLoading(false);
        } catch (e) {
            toast.error(e.message)
        }
    };

    useEffect(() => {
        if (props.board) {
            loadingComponent(props.board);
        }
        // eslint-disable-next-line    
    }, [props.board, year])

    return (
        <Fragment>
            {info && !props.noGuide && <div className="custom_tab tab-sec" style={{ padding: '10px 30px' }}>
                <div dangerouslySetInnerHTML={{ __html: info }} />
            </div>}
            <div className="table-responsive-sm">
                {['LL152', 'LL87', 'LL11'].includes(props.board) &&
                    <div className="d-flex justify-content-end">
                        <DatePicker picker='year' onChange={(value) => setYear(value?.format('YYYY'))} />
                    </div>
                }

                <Table className="sec-table">
                    <thead>
                        <tr>
                            <th>Address</th>
                            {['Water_Tank', 'PBS'].includes(props.board) && <th>ID</th>}
                            {(props.board === 'LL11') && <th>Start Date</th>}
                            <th>Due</th>

                        </tr>
                    </thead>
                    <tbody>
                        {
                            (loading) ? <tr align="center"><td colSpan={3}> <ReactLoading type={"bubbles"} color={"grey"} /> </td></tr> :
                                dataTable.length === 0 ? <tr><td colSpan="3">Everything looks compliant!</td></tr> :

                                    dataTable.map((value, key) => {


                                        if (value.hasOwnProperty('elevators')) {
                                            return <ElevatorBasedCompilance addedDevices={props.addedDevices} value={value} key={key} history={props.history} />
                                        } else if (props.board.toUpperCase() === 'HPD_REGISTRATION') {
                                            return <HPDRegistrationCompilance addedDevices={props.addedDevices} board={props.board} value={value} key={key} />
                                        } else if (props.board.toUpperCase() === 'DEP_BOILER') {
                                            return <DepBoilerCompilance addedDevices={props.addedDevices} board={props.board} value={value} key={key} />
                                        } else if (['Water_Tank', 'PBS'].includes(props.board)) {
                                            return <>
                                                {value.violationsDetails?.map((violation) => {

                                                    const date = violation.violationData.expirationDate ? violation.violationData.expirationDate : violation.violationData.inspectionDate
                                                    return <tr key={violation._id}>
                                                        <td>
                                                            <div>
                                                                <Link to={`/violations/all/${value.bin}`}>
                                                                    <i className="fa fa-building"></i>
                                                                </Link>
                                                                {value.fullAddress}
                                                            </div>
                                                        </td>
                                                        <td>
                                                            <div>
                                                                <Link to={`/violation-details/${violation._id}`}>{violation.violationData.cre_generated_id}</Link>
                                                            </div>
                                                        </td>

                                                        <td>{(date && moment(date).isValid()) ? moment(date).format('MM/DD/YYYY') : date}</td>

                                                    </tr>
                                                })}
                                            </>

                                        } else {

                                            return <BuidlingBasedCompilance
                                                key={key}
                                                addedDevices={props.addedDevices}
                                                value={value}
                                                board={props.board}
                                                setYear={setYear}
                                            />
                                        }
                                    })
                        }
                    </tbody>
                </Table>
            </div>
        </Fragment>
    )
}

export default Compliance
