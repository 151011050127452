import React from 'react';
import moment from 'moment';
import { Link } from 'react-router-dom';

const HPDRegistrationCompilance = ({ value, addedDevices = [] }) => {

    const registrations = value.violationsDetails?.filter(el => !addedDevices.includes(el.violationData.registrationid))
    if (!registrations?.length) {
        return null
    }

    return (
        <>
            <tr>
                <td colSpan={3}>
                    <i className="fa fa-building"></i> {value.fullAddress}
                </td>
            </tr>
            {registrations?.map((violation, key) => {
                return <tr key={key}>
                    <td>
                        <div>
                            Registration Id# <Link to={`/violation-details/${violation._id}`}>{violation.violationData.registrationid}</Link>
                        </div>

                        {violation.violationData.lastregistrationdate && <small>Last Registration Date: {moment(violation.violationData.lastregistrationdate).format('MM/DD/YYYY')}</small>}
                    </td>
                    <td>{(violation.violationData.registrationenddate && moment(violation.violationData.registrationenddate).isValid()) ? moment(violation.violationData.registrationenddate).format('MM/DD/YYYY') : violation.violationData.registrationenddate}</td>
                    <td></td>
                </tr>
            })}
        </>
    );
};

export default HPDRegistrationCompilance;
