import React from 'react';
import { Pie } from 'react-chartjs-2';

import './summaryPie.css';
import { random_rgb } from '../../../services/helperService';

const SummaryPie = ({ totalSent, totalResponseData, displayData }) => {
  if (!totalSent || !totalResponseData || !displayData) {
    return null;
  }
  const firstPieData = {
    labels: ['RECEIVED', 'NOT RECEIVED'],
    datasets: [
      {
        data: [totalResponseData.length, totalSent - totalResponseData.length],
        backgroundColor: ['rgba(75, 192, 192, 0.4)', 'rgba(255, 99, 132, 0.4)'],
        borderColor: ['rgba(75, 192, 192, 1)', 'rgba(255, 99, 132, 1)'],
      },
    ],
  };

  const secondPieData = {
    labels: ['PHONE', 'MAIL', 'WEB','EMAIL', 'NOT RECEIVED'],
    datasets: [
      {
        data: [
          ...totalResponseData.reduce(
            (ac, a) => {
              switch (a.responseData.windowsSummaryReport.Received) {
                case 'Mail':
                  ac[1]++;
                  break;
                case 'Web':
                  ac[2]++;
                  break;
                case 'Email':
                  ac[3]++;
                  break;
                default:
                  ac[0]++;
              }

              return ac;
            },
            [0, 0, 0, 0]
          ),
          totalSent - totalResponseData.length,
        ],
        backgroundColor: [
          'rgba(75, 192, 192, 0.4)',
          'rgba(153, 102, 255, 0.4)',
          'rgba(255, 159, 64, 0.4)',
          'rgba(255, 99, 132, 0.4)',
          'rgba(143, 188, 143, 0.4)',
        ],
        borderColor: [
          'rgba(75, 192, 192, 1)',
          'rgba(153, 102, 255, 1)',
          'rgba(255, 159, 64, 1)',
          'rgba(255, 99, 132, 1)',
          'rgba(143, 188, 143, 1)',
        ],
      },
    ],
  };

  const buildingData = displayData.reduce((ac, a) => {
    const address = a.building?.address?.split(',')[0];
    if (ac[address]) {
      ac[address]++;
    } else {
      ac[address] = 1;
    }
    return ac;
  }, {});

  const labels = Object.keys(buildingData);
  const values = labels.map((el) => buildingData[el]);
  const backgroundColor = labels.map((el, i) => random_rgb(0.4, i));
  const borderColor = backgroundColor.map((el) => el.replace('0.4', '1'));

  const thirdPieData = {
    labels,
    datasets: [
      {
        data: values,
        backgroundColor,
        borderColor,
      },
    ],
  };
  const persantageOption = {
    tooltips: {
      callbacks: {
        label: function (tooltipItem, data) {
          var dataset = data.datasets[tooltipItem.datasetIndex];
          var meta = dataset._meta[Object.keys(dataset._meta)[0]];
          var total = meta.total;
          var currentValue = dataset.data[tooltipItem.index];
          var percentage = parseFloat(
            ((currentValue / total) * 100).toFixed(1)
          );
          return currentValue + ' (' + percentage + '%)';
        },
        title: function (tooltipItem, data) {
          return data.labels[tooltipItem[0].index];
        },
      },
    },
  };
  return (
    <div className="d-flex justify-content-between">
      <div className="pie-wrapper">
        <Pie data={firstPieData} options={persantageOption} />
      </div>
      <div className="pie-wrapper">
        <Pie data={secondPieData} options={persantageOption} />
      </div>
      <div className={`pie-wrapper ${labels.length >= 5 ? 'mt-4' : ''}`}>
        <Pie
          data={thirdPieData}
          options={{
            legend: {
              display: labels.length < 5,
            },
            ...persantageOption,
          }}
        />
      </div>
    </div>
  );
};

export default SummaryPie;
