import moment from 'moment';
import { getColorLabel } from './colorService';

const uniquekeys = [
  'complaint_number',  'violation_id',
  'ticket_number',     'unique_key',
  'violationid',       'complaintid',
  'litigationid',      'cre_generated_id',
  'omoid',             'vio_id',
  'tracking_number',   'applicationid',
  'tr6_no',            'DeviceID',
  'TrackingNumber',    '_10_digit_bbl',
  'job_filing_number', 'acct_id',
  'cof_num',           'recordNumber',
  'omonumber'
]
// Common Helpe
const defaultColors = [
  (opacity) => `rgba(237,197,54,${opacity})`,
  (opacity) => `rgba(206,83,112,${opacity})`,
  (opacity) => `rgba(163,67,123,${opacity})`,
  (opacity) => `rgba(246,151,70,${opacity})`,
  (opacity) => `rgba(235,111,94,${opacity})`,
  (opacity) => `rgba(138,129,99,${opacity})`,
  (opacity) => `rgba(53,158,237,${opacity})`,
  (opacity) => `rgba(124,180,197,${opacity})`,
  (opacity) => `rgba(255,99,132,${opacity})`,
  (opacity) => `rgba(54,162,235,${opacity})`,
  (opacity) => `rgba(255,206,86,${opacity})`,
  (opacity) => `rgba(61,113,239,${opacity})`,
  (opacity) => `rgba(50,168,82,${opacity})`,
  (opacity) => `rgba(26,68,99,${opacity})`,
  (opacity) => `rgba(255,192,203,${opacity})`
]

function random_rgb(opacity, i) {

  let o = Math.round;
  let r = Math.random;

  if (defaultColors[i]) {
    return defaultColors[i](opacity)
  } else {

    if (i || i === 0) {
      r = () => {
        i += 3
        return 1 / (i % 5 + 0.4)
      }
    }
  }


  const s = 255;

  return (
    'rgba(' +
    o(r() * s) +
    ',' +
    o(r() * s) +
    ',' +
    o(r() * s) +
    ',' +
    opacity +
    ')'
  );
}

const formatter = new Intl.NumberFormat('en-US', {
  style: 'currency',
  currency: 'USD',
  minimumFractionDigits: 2,
});

function capSentence(text) {
  if (!text) return;
  let wordsArray = text.toLowerCase().split(' ');

  // eslint-disable-next-line
  let capsArray = wordsArray.map((word) => {
    if (word[0]) return word.replace(word[0], word[0].toUpperCase());
  });

  return capsArray.join(' ');
}

function getDropDownYears() {
  const startYear = 2022;
  let tmpYear = moment().format('YYYY');
  let tmpYears = [];
  while (tmpYear >= startYear) {
    tmpYears.push(Number(tmpYear));
    tmpYear--;
  }
  tmpYears.unshift(moment().add(1, 'year').format('YYYY'));
  return tmpYears;
}

function isTenantEditingAvaliable(tenatsYear) {
  const now = moment();
  tenatsYear = Number(tenatsYear)
  const current = Number(now.format('YYYY'))

  return tenatsYear === current
    ? now.format('M') <= 4
    : tenatsYear === (current + 1)
}

const generateViolationTableData = (rawColumns, rawData, withAddressColumn, sub) => {
  let tmpColumns = [];
  let tmpDataSource = [];
  let tmpCustomFieldData = {};
  const category = rawData[0]?.category

  for (const rawItem of rawData) {
    let retained = false;
    if (rawItem.clientAdditionalData.retained) {
      for (let prop in rawItem.clientAdditionalData.retained) {
        if (rawItem.clientAdditionalData.retained[prop].isRetained) {
          retained = true;
          break;
        }
      }
    }
    const label = getColorLabel(rawItem.clientAdditionalData?.colorMark);
    tmpDataSource.push({
      ...rawItem.violationData,
      _id: rawItem._id,
      colorMark: rawItem.clientAdditionalData?.colorMark,
      color: label,
      retained: retained,
      bin: rawItem.building.bin,
      documents: rawItem.documents || [],
      notes: rawItem.notes || [],
      chats: rawItem.chats || [],
      validUsers: rawItem.building.client,
      savedSummonPdf: rawItem.savedSummonPdf,
      address: rawItem.building.display_address
        ? rawItem.building.display_address
        : rawItem.building.address,
    });

    if (
      rawItem?.customFieldData &&
      Object.keys(rawItem.customFieldData).length !== 0
    ) {
      tmpCustomFieldData[rawItem._id] = { ...rawItem?.customFieldData };
    }
  }

  for (const rawColumn of rawColumns) {
    if (tmpDataSource.some((el) => el[rawColumn.apiField])) {
      // do not show colomns with empty data
      tmpColumns.push({
        title: rawColumn.displayName,
        key: rawColumn.apiField,
      });
    }
  }
  if (withAddressColumn) {
    tmpColumns.push({
      title: 'Address',
      key: 'address',
    });
  }

  if (category === 'ECB' && !sub?.includes?.('Complaint')) {
    tmpColumns.push({
      title: 'Summon',
      key: 'savedSummonPdf',
    });
  }

  return {
    columns: [...tmpColumns],
    dataSource: [...tmpDataSource],
    customFieldData: tmpCustomFieldData,
  };
};

// eslint-disable-next-line import/no-anonymous-default-export
export default {
  formatter,
};

export {
  formatter,
  capSentence,
  getDropDownYears,
  isTenantEditingAvaliable,
  generateViolationTableData,
  random_rgb,
  uniquekeys
};
