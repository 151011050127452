import SignatureCanvas from 'react-signature-canvas'
import React, { Fragment, useState, useRef, useEffect } from 'react';
import creService from "../../services/creService";
import { ToastContainer } from 'react-toastify';
import { toast } from 'react-toastify';
import ReactLoading from 'react-loading';
import './form.css'


const questions1 = [
    {
        question: 'A child 5 years or younger lives in or routinely spends 10 or more hours each week in my home or apartment.',
        answers: [],
        id: 'Child Under 6',
        clearID: ['No Child Under 11'],
    },
    {
        question: 'A child 10 years or younger lives in my home and:',
        id: 'Child Under 11',
        clearID: ['No Child Under 11'],
        // addID: ['Child Under 6'],
        answerRequired: true,
        answers: [
            {
                text: 'Window guards are installed in all windows as required.',
                id: 'Under 11 - Guards Installed'
            },
            {
                text: 'Window guards need repair.',
                id: 'Under 11 - Need Repair'
            },
            {
                text: 'Window guards are NOT installed in all windows as required.',
                id: 'Under 11 - No Guards'
            },
        ]
    },
    {
        question: 'No child 10 years or younger lives in my home',
        id: 'No Child Under 11',
        clearID: ['Child Under 6', 'Child Under 11'],
        answers: [
            {
                text: 'I want window guards installed anyway.',
                id: 'Over 10 - Want Guards'
            },
            {
                text: 'I have window guards, but they need repair.',
                id: 'Over 10 - Need Repair'
            }
        ]
    },
    {
        question: 'Please provide your signature',
        id: 'signature',
        signature: true,
        required: true,
    }
]

const questions2 = [
    {
        question: 'Yes, I want stove covers or replacement stove knob covers for my stove.',
        answers: [],
        id: 'Need Knob Covers',
    },
    {
        question: 'Yes, I want permanent stove safety knobs with integrated locking mechanisms for my stove.',
        answers: [],
        id: 'Need Lockable Knobs',
    },
    {
        question: 'There is a child under age six residing in my apartment',
        answers: [],
        id: 'Child Under 6',
    },
]

const requireCheck = (answers) => {
    const wasChecked = Object.keys(answers).some(key => answers[key].checked)

    return !wasChecked || Object.keys(answers).some(key => {
        if (!answers[key].text) {
            const question = questions1.find(el => el.question === key)
            if (question?.required) {
                return true
            }
            if (question?.answerRequired && answers[key].checked) {
                return true
            }
        }

        return false

    })
}

const Form = (props) => {

    const [answers, setAnswers] = useState([...questions1, ...questions2].reduce((ac, a) => {
        ac[a.question] = {
            text: '',
            id: a.id,
            clearID: a.clearID,
            checked: false
        };
        return ac
    }, {}))
    const sigRef = useRef();
    const [loading, setLoading] = useState(true)
    const [record, setRecord] = useState(null)
    const [formNumber, setFormNumber] = useState(0)

    const submit = async () => {

        if (requireCheck(answers)) {
            toast.warn('Please complite the form');
        } else {

            if(props.match.params.id === 'test') {
                setRecord({...record, responseData: true})
                setLoading(false)
                return 
            }

            const payload = Object.keys(answers).reduce((ac, key) => {
                const question = [...questions1, ...questions2].find(el => el.question === key)
                if (question.signature) {
                    ac.signature = answers[key].text
                } else if (answers[key].checked) {
                    ac.answers.push(question.id)
                    if (answers[key].text) {
                        ac.answers.push(question.answers.find(el => el.text === answers[key].text).id)
                    }
                }
                return ac
            }, {
                signature: '',
                answers: []
            })
         
            const result = await creService.submitSafetyMailingPrintedResponse(props.match.params.id, payload)
            if (result.data.status) {
                toast.success('Response sent');
                intilize()
            } else {
                toast.error('Response did not sent, Something go wrong');
            }
        }
    }
    const clearSignature = () => {

        sigRef.current.clear();

        const key = questions1.find(el => el.signature).question
        setAnswers({ ...answers, [key]: { ...answers[key], text: '' } })
    }

    const handleSignatureEnd = () => {
        const key = questions1.find(el => el.signature).question
        setAnswers({ ...answers, [key]: { ...answers[key], text: sigRef.current.toDataURL() } })
    }
    const intilize = async () => {
        setLoading(true)
        if(props.match.params.id === 'test') {
            setRecord({accountNumber: 'Test', aptNumber: 'Test'})
            setLoading(false)
            return 
        }
        const result = await creService.getSafetyMailingPrintedRow(props.match.params.id)
        if (result.data.status && result.data.printedRow) {
            setRecord(result.data.printedRow)
            setLoading(false)
        } else {
            toast.error('Invalid Identification Number');
        }


    }
    const setAnswersResult = (answer, { question }, checked = true) => {
        const id = answers[question].id

        setAnswers(Object.keys(answers).reduce((ac, quest) => {
            if (quest === question) {
                ac[quest] = { ...answers[quest], text: answer, checked }
            } else if (answers[quest].clearID?.includes(id)) {
                ac[quest] = { ...answers[quest], text: '', checked: false }
            } else if (answers[quest].addID?.includes(id)) {
                ac[quest] = { ...answers[quest], text: checked ? answers[quest].text : '', checked }
            } else {
                ac[quest] = answers[quest]
            }
            return ac
        }, {}))
    }

    useEffect(() => {
        intilize()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    return (
        <div className='wrapper'>
            <div className={`${formNumber ? 'blue_top' : 'orange_top'}`}>
                <h1>Safety Notice FastResponse</h1>
                {formNumber ?
                    <h2>Peeling Lead Paint &amp; Window Guards</h2>
                    :
                    <h2>Stove Knob Covers</h2>
                }
            </div>

            {
                loading ?
                    <div className='d-flex justify-content-center'>
                        <ReactLoading type={'cylon'} color={'grey'} />
                    </div>
                    :
                    <>

                        <div className='questions'>
                            <div><strong>Building Address: </strong> {record.building?.display_address ? record.building?.display_address : record.building?.address }<br /><strong>Apartment Number: </strong> {record.aptNumber}<br /> <strong>Account Number: </strong> {record.accountNumber}</div>
                            <hr />
                            {record.responseData ? <div> {formNumber ? 'Thank you! Response successfully saved.' : 'Response already sent'} </div> :
                                <>
                                    {(formNumber ? questions1 : questions2).map((el, i) => {
                                        return <div className="card m-1" key={el.question}>
                                            <div className='d-flex align-items-center'>
                                                {!el.signature && < input type="checkbox" checked={answers[el.question].checked} onChange={(event) =>
                                                    setAnswersResult(
                                                        '',
                                                        el,
                                                        event.target.checked
                                                    )
                                                } />}
                                                <strong className='ml-1'>{el.question}</strong >
                                            </div>

                                            <div className="card-body p-1">

                                                {el.signature ?
                                                    <>
                                                        <SignatureCanvas
                                                            ref={sigRef}
                                                            canvasProps={{ width: 300, height: 100, className: 'sigCanvas' }}
                                                            onEnd={handleSignatureEnd}
                                                        />
                                                        <br />
                                                        <button onClick={clearSignature}>Clear</button>
                                                    </> :
                                                    el.answers.map(answer =>
                                                        <div className="form-check" key={el.question + answer.text}>
                                                            <label
                                                                className="form-check-label"
                                                                htmlFor="list"

                                                            >
                                                                <input
                                                                    className="form-check-input  mt-1"
                                                                    type="radio"
                                                                    id={el.question + answer}
                                                                    checked={answers[el.question].text === answer.text}
                                                                    name={el.question}
                                                                    onChange={() => setAnswersResult(answer.text, el)}
                                                                />
                                                                {answer.text}
                                                            </label>
                                                        </div>)}
                                            </div>

                                        </div>
                                    })}
                                    {formNumber !== 0 && <button
                                        type="submit"
                                        className="btn btn-primary mt-3 mr-2"
                                        onClick={() => setFormNumber(formNumber - 1)}
                                    >
                                        Back
                                    </button>
                                    }
                                    <button
                                        type="submit"
                                        className={`btn btn-${formNumber ? "primary" : "warning"} mt-3`}
                                        onClick={() => formNumber === 0 ? setFormNumber(formNumber + 1) : submit()}
                                        disabled={formNumber === 0 ? false : requireCheck(answers)}
                                    >
                                        {formNumber === 0 ? 'Next' : 'Submit'}
                                    </button>

                                </>
                            }
                        </div>


                    </>
            }
            <ToastContainer />
        </div >

    )
}

export default Form;
