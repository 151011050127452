import React, { Fragment, useEffect, useState } from 'react';
import { Link, useHistory, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import creService from '../../../services/creService';
import SafetyMailingsAutoRulesForm from "./form";
import RuleExistsMessage from './ruleNotExistsMessage'

const SafetyMailingsAutoRulesEdit = ({ jestData = false }) => {

    const history = useHistory();

    const params = useParams();

    const [isProcessing, setIsProcessing] = useState(false);

    const [autoRule, setAutoRule] = useState(jestData ? jestData.autoRule:{});

    const [isPreviewLoading, setIsPreviewLoading] = useState(false);

    const [isPreviewLoaded, setIsPreviewLoaded] = useState(false);

    const [isPreview, setIsPreview] = useState(false);

    const [previewData, setPreviewData] = useState(jestData ? jestData.previewData:[]);
    
    const [formData, setFormData] = useState(jestData ? jestData.previewData:{});

    const onSubmit = async () => {
        setIsProcessing(true)
        try {
            const { data } = await creService.updateSafetyMailingAutoRules(params.id, formData);
            if (data.success) {
                toast.success(data.message);
                setFormData({});
                history.push('/safety-mailings/auto-rules');
            } else {
                if (data.code === 'EXISTS') {
                    toast.warn(<RuleExistsMessage {...data} />);
                } else {
                    toast.error(data.message);
                }
            }

            setIsProcessing(false)
        } catch (_err) {
            toast.error(_err.message);
            setIsProcessing(false);
        }
    }

    const onPreview = async () => {
        setIsPreviewLoading(true)
        try {
            const { data } = await creService.previewSafetyMailingAutoRules(formData);
            if (data.success) {
                setPreviewData(data.data);
                setIsPreviewLoaded(true);
            } else {
                toast.error(data.message);
            }

            setIsPreviewLoading(false)
        } catch (_err) {
            toast.error(_err.message);
            setIsPreviewLoading(false);
            setIsPreviewLoaded(true);
        }
    }

    const fetchSafetyMailingAutoRule = async () => {
        try {
            const { data } = await creService.getSafetyMailingAutoRule(params.id);
            if (data.success) {
                toast.success(data.message);
                setAutoRule(data.data);
            } else {
                toast.error(data.message);
            }

            setIsProcessing(false)
        } catch (_err) {
            toast.error(_err.message);
            setIsProcessing(false);
        }
    }

    useEffect(() => {
        setFormData({
            year: autoRule.year,
            action: autoRule.action,
            addToTitle: autoRule.addToTitle,
            category: autoRule.category,
            assignedTo: autoRule.assignedTo,
            vendor: autoRule.vendor,
            dueDate: String(autoRule.dueDate),
            vendorNotification: autoRule.vendorNotification,
            bins: autoRule.bins,
            isAllBuilding: autoRule.isAllBuilding,
        });
    }, [autoRule])

    useEffect(() => {
        fetchSafetyMailingAutoRule()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    useEffect(() => {
        setIsPreview(false);
    }, [formData]);
    
    return (
        <Fragment>
            <div className="row violation-sec">
                <div className="col-xl-12 my-3">
                    <div className="card">
                        <div className="card-header row">
                            <div className="col-6">
                                <h5>[ Edit ] Safety Mailings Auto Rule</h5>
                            </div>
                            <div className="col-6 text-right">
                                <Link to='/safety-mailings/auto-rules'>
                                    <button className="btn btn-primary text-white" type="button">
                                        <i className="fa fa-arrow-left mr-1"></i>
                                        Back
                                    </button>
                                </Link>
                            </div>
                        </div>
                        <div className="card-body">
                            <SafetyMailingsAutoRulesForm
                                formData={formData}
                                setFormData={setFormData}
                                onSubmit={onSubmit}
                                onPreview={onPreview}
                                isPreviewLoading={isPreviewLoading}
                                isProcessing={isProcessing}
                                isPreview={isPreview}
                                setIsPreview={setIsPreview}
                                previewData={previewData}
                                isPreviewLoaded={isPreviewLoaded}
                                edit={true}
                            />
                        </div>
                    </div>
                </div>
            </div>
        </Fragment>
    );
}

export default SafetyMailingsAutoRulesEdit;
