import React, { Component, Fragment } from 'react';
import ReactLoading from 'react-loading';
import Moment from 'moment';
import { UncontrolledTooltip } from 'reactstrap';

import creApi from '../../services/creService';
import Helper, {
  generateViolationTableData,
  uniquekeys
} from '../../services/helperService';
import { toast, ToastContainer } from 'react-toastify';
import RecentActivity from './partials/recentActivity';
import AddressHeaderBlock from './partials/addressHeaderBlock';
import SmallCalendar from '../calendar/smallCalendar';
import LocalState from '../../common/localState';
import { settingsKey } from '../../services/authService';
import { exportViolationEvents } from '../../services/exportExcel';
import HPDViolationBarChart from './partials/charts/hpdViolationBarChart';
import TableViolation from '../../common/table-violation/tableViolation';
import VPUSign from '../../common/VPUSign';

class ViolationsTable extends Component {
  locationState = this.props.location?.state;
  codesData = [];

  state =
    this.props.jestData !== undefined
      ? this.props.jestData.state
      : {
        displayName: [],
        doneLoading: false,
        doneReLoading: true,
        doneLoadingActivity: true,
        tableData: [],
        violationData: [],
        dollarAmount: [],
        addressData: {},
        filter: {},
        viewType: LocalState.getViewTypeSetting(
          'violationsTableComponent',
          'list'
        ),
        sortBy: LocalState.getSortBySetting(
          'violationsTableComponent',
          this.props.match.params.category,
          this.props.match.params.sub
        ),
        newData: [],
        tableHeaders: [],
        codes: [],
        dataForTable: [],
      };

  getViolationData = async (bin, category, sub, sortBy, sortType) => {
    try {
      let filter = [];
      if (this.props.location.state !== undefined)
        filter = this.props.location.state?.ids || [];

      let propertyInfo = await creApi.getPropertyInfo(bin);

      if (propertyInfo.data?.status === false) {
        this.props.history.push('/my-buildings');
        toast.error(propertyInfo.data?.message);
      } else {
        this.setState({ addressData: propertyInfo.data });

        let violationsData = await creApi.getViolationData(
          bin,
          category,
          sub,
          filter,
          sortBy,
          sortType
        );
        // Deleting 'location' object that conflicts with React
        if (this.props.match.params.sub === 'Complaints__Closed') {
          violationsData.data.violationsData.Complaints__Closed.forEach((v) => {
            delete v.violationData['location'];
          });
        }
        if (this.props.match.params.sub === 'Complaints__Open') {
          violationsData.data.violationsData.Complaints__Open.forEach((v) => {
            delete v.violationData['location'];
          });
        }

        const tableData =
          violationsData.data.violationsData[this.props.match.params.sub]?.map(
            (r) => r.violationData
          ) || [];
        let tableHeaders = [];
        tableData?.map((value) => {
          Object.keys(value).map((value2) => {
            if (!tableHeaders.includes(value2)) tableHeaders.push(value2);
            return true;
          });
          return true;
        });
        let violationData =
          violationsData.data.violationsData[this.props.match.params.sub]?.map(
            (r) => r.violationData
          ) || [];
        let orgTableData =
          violationsData.data.violationsData[this.props.match.params.sub];

        let codes = violationData.map((item) => {
          if (item?.ordernumber) {
            return item.ordernumber.replace(' *', '');
          }
          return null;
        });

        if (codes.length) {
          const { data } = await creApi.getHPDViolationCodesDesc(codes);
          this.codesData = data.data;
          if (this.codesData) {
            orgTableData.map((item) => {
              this.codesData.forEach((data) => {
                if (data.code.includes(item.violationData?.ordernumber)) {
                  item.violationData.codeColor = data.colorCode;
                  item.violationData.codeName = data.name;
                }
              });
              return item;
            });
          }
        }
        let dataForTable = {};
        if (
          'displayName' in violationsData.data &&
          'violationsData' in violationsData.data &&
          violationsData.data.displayName.length
        ) {
          dataForTable = generateViolationTableData(
            violationsData.data.displayName,
            violationsData.data.violationsData[this.props.match.params.sub],
            false,
            this.props.match.params.sub
          );
        }

        // Deleting 'location' object that conflicts with React
        this.setState({
          doneLoading: true,
          doneReLoading: true,
          tableData,
          violationData,
          newData:
            violationsData.data.violationsData[
              this.props.match.params.sub
            ]?.map((r) => ({
              violationData: r.violationData,
              clientAdditionalData: r.clientAdditionalData,
              notes: r.notes,
              documents: r.documents,
            })) || [],
          orgTableData,
          violationsData: {
            [this.props.match.params.sub]:
              violationsData.data.violationsData[
                this.props.match.params.sub
              ]?.map((r) => ({
                violationData: r.violationData,
                clientAdditionalData: r.clientAdditionalData,
                notes: r.notes,
                documents: r.documents,
              })) || [],
          },
          identifierField: {
            displayAs: 'Complaint#',
            field: 'unique_key',
          },
          displayName: violationsData.data.displayName,
          dollarAmount: violationsData.data.dollarAmountFields,
          availableSortByFields: {
            ...violationsData.data.availableSortByFields,
            color: 1,
          },
          filter: {
            category: this.props.match.params.category,
            sub: this.props.match.params.sub.replace(/_/g, ' '),
          },
          tableHeaders,
          dataForTable,
        });
      }
    } catch (e) {
      toast.error(e.message);
    }
  };

  async componentDidMount() {
    let { sortBy = '', sortType = '' } = this.props.match.params;
    if (!this.props.jestData) {
      if (sortBy !== this.state.sortBy.field) {
        this.props.history.push(
          `/violations/table/${this.props.match.params.id}/${this.props.match.params.category}/${this.props.match.params.sub}/${this.state.sortBy.field}/${sortType}`
        );
      }
      if (
        parseInt(sortType) !== parseInt(this.state.sortBy.type) &&
        (this.state.sortBy.field || this.state.sortBy.direction)
      ) {
        this.props.history.push(
          `/violations/table/${this.props.match.params.id}/${this.props.match.params.category}/${this.props.match.params.sub}/${this.state.sortBy.field}/${this.state.sortBy.direction}`
        );
      }
    }
    await this.getViolationData(
      this.props.match.params.id,
      this.props.match.params.category,
      this.props.match.params.sub,
      this.state.sortBy.field,
      this.state.sortBy.direction
    );
  }

  updateData = async () => {
    await this.switchToggle();
    if (this.state.viewType === 'list') {
      this.setState({ viewType: 'list' }); // todo
      // await this.getViolationData(this.props.match.params.id, this.props.match.params.category, this.props.match.params.sub, this.state.sortBy.field, this.state.sortBy.direction);
    }
  };

  handleBack = () => {
    const prev = localStorage.getItem('prev-location');
    if (prev) {
      this.props.history.push(prev);
    } else {
      if (this.state.addressData.bin) {
        this.props.history.push(
          `/violations/all/${this.state.addressData.bin}`
        );
      } else {
        this.props.history.goBack();
      }
    }
  };

  handleViolationDetailsClick = (id, tabName = null) => {
    const historyState = { from: this.props.location.pathname };
    if (tabName) {
      this.props.history.push(
        `/violation-details/${id}/${tabName}`,
        historyState
      );
    } else {
      this.props.history.push(
        `/violation-details/${id}`,
        historyState
      );
    }
  };

  handleCategoryUrl = () => {
    this.props.history.push(`/violations/all/${this.state.addressData.bin}`);
    //this.props.history.push(`/violations/table/${this.state.addressData.bin}/${this.props.match.params.category}/${this.props.match.params.sub}`)
    //console.log(`/violations/table/${this.state.addressData.bin}/${this.props.match.params.category}/${this.props.match.params.sub}`);
  };

  handleSortClick = async (sortBy, sortType) => {
    this.setState({ doneReLoading: false });
    await creApi.updateUserSortBy(
      {
        page: 'violationsTableComponent',
        field: sortBy,
        direction: sortType,
      },
      this.props.match.params.category,
      this.props.match.params.sub
    );
    this.props.history.push(
      `/violations/table/${this.props.match.params.id}/${this.props.match.params.category}/${this.props.match.params.sub}/${sortBy}/${sortType}`
    );
    this.getViolationData(
      this.props.match.params.id,
      this.props.match.params.category,
      this.props.match.params.sub,
      sortBy,
      sortType
    );
    this.setState({
      sortBy: {
        page: 'violationsTableComponent',
        field: sortBy,
        direction: sortType,
      },
    });
  };

  Capitalize(str) {
    return str.charAt(0).toUpperCase() + str.slice(1);
  }

  convertToDisplayName(value) {
    let convertedDisplayName = this.state.displayName.find(
      (v) => v.apiField.toLowerCase() === value || v.apiField === value
    );
    return convertedDisplayName
      ? convertedDisplayName.displayName
      : value.charAt(0).toUpperCase() + value.slice(1);
  }

  switchToggle = async () => {
    const type = this.state.viewType === 'grid' ? 'list' : 'grid';
    await creApi.updateUserPageView({
      page: 'violationsTableComponent',
      type,
    });
    this.setState({ viewType: type });
  };

  getColorMarkLabel = (hexCode) => {
    const userSettings = JSON.parse(localStorage.getItem(settingsKey));
    if (userSettings?.colors?.[hexCode]) {
      return userSettings.colors[hexCode].label;
    }
    return '';
  };

  exportCsv = () => {
    let column = this.state.tableHeaders.map((element) => ({
      header: this.convertToDisplayName(element),
      key: this.convertToDisplayName(element),
    }));
    column = [...column, { header: 'Retained', key: 'retainedType' }];

    let data = [];
    this.state.newData.map((value, key) => {
      const { retained } = value.clientAdditionalData || {};
      let retainedTypes = [];
      if (retained?.hearing?.isRetained) {
        retainedTypes.push('hearing');
      }
      if (retained?.cofc?.isRetained) {
        retainedTypes.push('cofc');
      }
      if (retained?.dob?.isRetained) {
        retainedTypes.push('dob');
      }
      const voilationObject = {};
      Object.keys(value.violationData).map((field) => {
        let displayValue = this.state.tableData[key][field];

        // Converting Title
        let displayName = this.convertToDisplayName(field);

        // Converting Date
        if (
          (field.search(/date/i) !== -1 ||
            displayName.search(/date/i) !== -1) &&
          Moment(displayValue).isValid()
        ) {
          displayValue = Moment.utc(displayValue).format('MM/DD/YYYY');
        }
        // Check if we need to add $ sign
        if (this.state.dollarAmount.includes(field) && displayValue) {
          displayValue = `${Helper.formatter.format(parseFloat(displayValue))}`;
        }
        voilationObject[`${displayName}`] = displayValue;
        return field;
      });
      voilationObject.retainedType = retainedTypes?.join(', ').toUpperCase();
      data.push(voilationObject);
      return value;
    });

    exportViolationEvents(
      column,
      data,
      `${this.props.match.params.category}-${this.props.match.params.sub}-${this.state.addressData.bin}`
    );
  };

  render() {
    const { viewType } = this.state;
    const isHPDOpen =
      this.props.match.params.category === 'HPD' &&
      this.props.match.params.sub === 'Violations__Open';
    return (
      <Fragment>
        <ToastContainer />
        {/*<Breadcrumb title="Add/Edit Buildings" parent="Dashboard" />*/}

        <div className="container-fluid">
          {/*Bubble View*/}
          <div className="row">
            <div className="col-xl-9 violation-detail">
              <div className="row position-relative">
                <div className="col-md-12 violation-head">
                  {this.state.addressData && (
                    <AddressHeaderBlock
                      data={this.state.addressData}
                      category={this.props.match.params.category}
                      sub={this.props.match.params.sub}
                      handleGoBack={this.handleBack}
                      handleCategoryUrl={this.handleCategoryUrl}
                    />
                  )}
                </div>
                <div className="filter-sec d-flex pt-4 ">
                  {viewType === 'grid' && (
                    <button
                      className="btn text-danger font-weight-bold"
                      onClick={() => this.exportCsv()}
                    >
                      Export To CSV
                    </button>
                  )}
                  <div className="onhover-dropdown sort-by-side-btn ">
                    {viewType === 'grid' && (
                      <button
                        className="btn btn-primary btn-pill mr-3"
                        type="button"
                      >
                        SortBy
                        <span className="ml-1 pr-0">
                          <i className="fa fa-angle-down" />
                        </span>
                      </button>
                    )}
                    <button
                      className={`btn button ${viewType === 'grid' ? 'active' : ''
                        }`}
                      onClick={() => this.updateData()}
                    >
                      <i className="fa fa-th-large"></i>
                    </button>
                    <button
                      className={`btn button ${viewType === 'list' ? 'active' : ''
                        }`}
                      onClick={() => this.updateData()}
                    >
                      <i className="fa fa-bars"></i>
                    </button>
                    <div className="onhover-show-div right-header-dropdown list-group p-0">
                      {this.state.availableSortByFields &&
                        Object.keys(this.state.availableSortByFields).map(
                          (value, key) => {
                            let displayName = this.convertToDisplayName(value);
                            let sortType = 1;
                            let icon = 'down';
                            let bg = 'text-info';
                            // eslint-disable-next-line
                            if (value === this.props.match.params.sortBy) {
                              // eslint-disable-next-line
                              if (this.props.match.params.sortType == 1) {
                                sortType = -1;
                                icon = 'up';
                              } else {
                                sortType = 1;
                                icon = 'down';
                              }
                              bg = 'text-muted';
                            }
                            return (
                              // eslint-disable-next-line
                              <a
                                href={null}
                                className={`p-2 list-group-item d-flex justify-content-between align-items-center pointer ${bg}`}
                                key={key}
                                onClick={() =>
                                  this.handleSortClick(value, sortType)
                                }
                              >
                                {displayName}{' '}
                                {value === this.props.match.params.sortBy ? (
                                  <span className="badge badge-light counter digits">
                                    <i className={`fa fa-long-arrow-${icon}`} />{' '}
                                  </span>
                                ) : (
                                  ''
                                )}
                              </a>
                            );
                          }
                        )}
                    </div>
                  </div>
                </div>
              </div>

              {isHPDOpen && (
                <>
                  <div className='d-flex justify-content-end'>
                    <VPUSign
                      bigFormat
                      VPU={this.state.addressData.VPU}
                      VPUHistory={this.state.addressData.VPUHistory}
                    />
                  </div>
                  <HPDViolationBarChart
                    tableData={this.state.tableData}
                    violationData={this.state.violationData}
                  />
                </>
              )}

              {/*New version of table */}
              {viewType === 'list' && (
                <TableViolation
                  multiChangeAction={true}
                  data={{
                    bin: this.props.match.params.id,
                    columns: this.state.dataForTable.columns,
                    dataSource: this.state.dataForTable.dataSource,
                    customFieldData: this.state.dataForTable.customFieldData,
                    category: this.props.match.params.category,
                    sub: this.props.match.params.sub,
                    options: {
                      fixedColumn: uniquekeys.reduce((res, key) => { res[key] = true; return res }, {}),
                      links: uniquekeys.reduce((res, key) => { res[key] = '/violation-details/:record._id'; return res }, {})
                    },
                  }}
                />
              )}

              {/* Old version of table end */}
              {viewType === 'grid' && (
                <div className="row violation-sec">
                  {!this.state.doneLoading ? (
                    <div align="center">
                      {' '}
                      <ReactLoading type={'cylon'} color={'grey'} />{' '}
                    </div>
                  ) : (
                    <div className="col-md-12">
                      {!this.state.doneReLoading ? (
                        <div align="center">
                          {' '}
                          <ReactLoading type={'cylon'} color={'grey'} />{' '}
                        </div>
                      ) : (
                        <div className="row custom-col permit-box">
                          {this.state.newData.map((value, key) => {
                            const { colorMark, retained } =
                              value?.clientAdditionalData || {};
                            const colorMarkLabel =
                              this.getColorMarkLabel(colorMark);
                            return (
                              <div className="col-4 mb-2 d-flex" key={key}>
                                <div className="card fix-card flex-fill">
                                  <div className="card-body">
                                    <span
                                      className="color-mark-badge"
                                      style={
                                        colorMark
                                          ? {
                                            height: '4px',
                                            background: `${colorMark}`,
                                          }
                                          : {}
                                      }
                                      id={`colorMarkToolTip_${key}`}
                                    />
                                    {colorMarkLabel &&
                                      colorMarkLabel.length > 0 && (
                                        <UncontrolledTooltip
                                          placement="top"
                                          autohide={true}
                                          target={`colorMarkToolTip_${key}`}
                                        >
                                          {colorMarkLabel}
                                        </UncontrolledTooltip>
                                      )}
                                    <div className="media-body">
                                      <ul>
                                        {(retained?.hearing?.isRetained ||
                                          retained?.cofc?.isRetained ||
                                          retained?.dob?.isRetained) && (
                                            <li className="d-flex justify-content-end m-1">
                                              <span
                                                className="badge"
                                                style={{
                                                  background: '#c36c00',
                                                  color: 'white',
                                                }}
                                              >
                                                Retained
                                              </span>
                                            </li>
                                          )}
                                        {Object.keys(value.violationData).map(
                                          (value2, key2) => {
                                            if (
                                              value2 === 'codeColor' ||
                                              value2 === 'codeName'
                                            ) {
                                              return false;
                                            }

                                            let displayValue =
                                              this.state.tableData[key][value2];

                                            // Converting Title
                                            let displayName =
                                              this.convertToDisplayName(value2);

                                            // Converting Date
                                            if (
                                              (value2.search(/date/i) !== -1 ||
                                                displayName.search(/date/i) !==
                                                -1) &&
                                              Moment(displayValue).isValid()
                                            ) {
                                              displayValue =
                                                Moment.utc(displayValue).format(
                                                  'MM/DD/YYYY'
                                                );
                                            }
                                            // Check if we need to add $ sign
                                            if (
                                              this.state.dollarAmount.includes(
                                                value2
                                              ) &&
                                              displayValue
                                            ) {
                                              let result = parseFloat(displayValue)
                                              if (!isNaN(result)) {
                                                displayValue = `${Helper.formatter.format(result)}`;
                                              }
                                            }
                                            return (
                                              displayValue && (
                                                <li
                                                  key={[key + '' + key2]}
                                                  className="pointer"
                                                  onClick={() =>
                                                    this.handleViolationDetailsClick(
                                                      this.state.orgTableData[
                                                        key
                                                      ]._id
                                                    )
                                                  }
                                                >
                                                  <h6 className="title">
                                                    {displayName
                                                      ? displayName
                                                      : this.state.tableData[
                                                      key
                                                      ][value2]}
                                                  </h6>
                                                  <h6
                                                    className={
                                                      value2.search(
                                                        /status/i
                                                      ) === -1 &&
                                                        displayName.search(
                                                          /status/i
                                                        ) === -1
                                                        ? 'content'
                                                        : 'content default-btn'
                                                    }
                                                  >
                                                    {this.state.tableData[key][
                                                      'codeColor'
                                                    ] &&
                                                      value2 === 'ordernumber' ? (
                                                      <span
                                                        style={{
                                                          borderBottom: `4px solid ${this.state.tableData[key]['codeColor']}`,
                                                        }}
                                                      >
                                                        {displayValue} /{' '}
                                                        {
                                                          this.state.tableData[
                                                          key
                                                          ]['codeName']
                                                        }
                                                      </span>
                                                    ) : (
                                                      <span>
                                                        {displayValue}
                                                      </span>
                                                    )}
                                                  </h6>
                                                </li>
                                              )
                                            );
                                          }
                                        )}
                                      </ul>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            );
                          })}
                        </div>
                      )}
                    </div>
                  )}
                </div>
              )}
            </div>
            <div className="col-xl-3 recent-activity pr-0">
              <div className="pt-3 pb-3">
                {this.props.jestData !== undefined ? null : <SmallCalendar />}
              </div>
              <RecentActivity
                type="buildingCategroySubActivity"
                history={this.props.history}
                match={this.props.match}
                bin={this.props.match.params.id}
                category={this.props.match.params.category}
                sub={this.props.match.params.sub}
              />
            </div>
          </div>
        </div>
      </Fragment>
    );
  }
}
export default ViolationsTable;
