import React, { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import Breadcrumb from '../../common/breadcrumb';
import { Button } from 'reactstrap';
import creService from '../../services/creService';
import { confirmAlert } from 'react-confirm-alert'; // Import
import { useHistory } from "react-router-dom";
import moment from 'moment';
import ReactLoading from 'react-loading';



const Portfolio = ({ jestData = false }) => {
    const history = useHistory();
    const [loading, setLoading] = useState(false);
    const [data, setData] = useState(jestData ? jestData : []);

    async function fetchData() {
        setLoading(true);
        const {data} = await creService.getPortfolioData();
        if (data.status) {
            setData(data.data);
            setLoading(false);
        } else {
            toast.error(data.message);
        }
    }

    const handleDelete = async (userId) => {
        confirmAlert({
            title: 'Confirm to delete',
            message: 'Are you sure to do this.',
            buttons: [
                {
                    label: 'Yes',
                    onClick: async () => {
                        try {
                            const res = await creService.deletePortfolio(userId);
                            if (res.data.status) {
                                toast.success(res.data.message);
                                fetchData();
                            } else {
                                toast.error(res.data.message);
                            }
                        } catch (_err) {
                            toast.error(_err.message);
                        }
                    }
                },
                {
                    label: 'No',
                    onClick: () => { }
                }
            ]
        });
    }

    const handleEdit = async (portfolioId) => {
        history.push(`/portfolio/edit/${portfolioId}`)
    }

    useEffect(() => {
        fetchData();
    }, []);

    return (
        <React.Fragment>
            <>
                <Breadcrumb title="Portfolios" parent="Home" />
                <div className="container-fluid">
                    <table className="table table-borderless table-responsive-sm">
                        <thead>
                            <tr>
                                <th scope="col">#</th>
                                <th scope="col">Portfolio Name</th>
                                <th scope="col">Added On</th>
                                <th scope="col">Bins</th>
                                <th scope="col">Enabled Sub Users</th>
                                <th scope='col'>Default </th>
                                <th scope='col'>Actions </th>
                            </tr>
                        </thead>
                        <tbody>
                            {data.map((portfolio, index) => {
                                return (
                                    <tr key={index}>
                                        <th scope="row">{index + 1}</th>
                                        <td>{portfolio.portfolioName}</td>
                                        <td>{jestData ? '' : moment(portfolio.createdAt).format('MM/DD/YYYY')}</td>
                                        <td>{portfolio.bins.map((bin) => bin).join(', ')}</td>
                                        <td>{portfolio.subUserIdsEnabledInfo.map((user) => user.fullName).join(', ')}</td>
                                        <td>{portfolio.isDefault ? 'Yes' : 'No'}</td>
                                        <td>
                                            <button className="btn btn-warning btn-xs mr-1" onClick={() => handleEdit(portfolio._id)}><i className="fa fa-edit"></i></button>
                                            <button className="btn btn-danger btn-xs" onClick={() => handleDelete(portfolio._id)}><i className="fa fa-trash"></i></button>
                                        </td>
                                    </tr>
                                )
                            })}
                            {loading && (
                                <tr>
                                    <td colSpan={5}>
                                        <ReactLoading type={"cylon"} color={"grey"} />
                                    </td>
                                </tr>
                            )}
                            {!loading && data.length <= 0 &&
                                <tr>
                                    <td colSpan={5} className="text-center">
                                        No Portfolios available.
                                    </td>
                                </tr>
                            }
                        </tbody>
                    </table>
                    <Button onClick={() => history.push('/portfolio/create')} className="btn btn-primary mt-3">
                        <i className="fa fa-plus"></i> Add Portfolio
                    </Button>
                </div>
            </>
        </React.Fragment>
    );
}

export default Portfolio;


