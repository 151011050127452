import React, { Fragment } from 'react';
import man from '../../../assets/images/dashboard/user.jpeg';
import {
  Users,
  Settings,
  List,
  LogOut,
  Tool,
  Sliders,
  BookOpen,
  Zap
} from 'react-feather';
import auth from '../../services/authService';
import { Link } from 'react-router-dom';
import { useContext } from 'react';
import { SocketContext } from '../../../context/socket';



const jwt = localStorage.getItem('cre-token');
const UserMenu = () => {
  const socket = useContext(SocketContext);

  const user = auth.getCurrentUser();

  function doLogout() {
    auth.logout(socket, user, true);
  }

  let src = man
  if (user?.imageId) {
    src = window.location.origin + `/api/documents/aws-s3-file-stream/${jwt}:${user.imageId}`
  }

  return (
    <Fragment>
      <li className="onhover-dropdown d-flex">
        <div className="media align-items-center rounded-circle" style={{
          backgroundImage: `url(${src})`,
          backgroundSize: 'cover',
          width: '40px',
          height: '41px'
        }}>
        </div>
        <div className="head-uer-text">
          <h3 className="align-items-center">
            {' '}
            {user?.isSubUser ? (
              'Account'
            ) : (
              <span className="badge badge-secondary" style={{ fontSize: '70%' }}>
                Master Account
              </span>
            )}
          </h3>
          <h4 className="align-items-center white-space-nowrap">{user ? user.name : ''} </h4>
          <i className="fa fa-chevron-down"></i>
        </div>
        <ul className="profile-dropdown onhover-show-div p-20 profile-dropdown-hover">
          <li>
            <Link to="/uploads-and-notes">
              <List /> Uploads
            </Link>
          </li>
          {!user?.isSubUser && (
            <li className="menu-border-top">
              <Link to="/portfolio">
                <BookOpen /> Portfolios
              </Link>
            </li>
          )}
          <li className="menu-border-top">
            <Link to="/preferences">
              <Sliders /> Notifications
            </Link>
          </li>
          <li>
            <Link to="/settings/excluded-respondents">
              <Users /> Excluded List
            </Link>
          </li>
          {!user?.isSubUser && (
            <li>
              <Link to="/settings/sub-users">
                <Settings /> Sub Users
              </Link>
            </li>
          )}
          <li className="menu-border-top">
            <Link to="/settings/user-options">
              <Tool />  Other Settings
            </Link>
          </li>


          <li >
            <Link to="/settings/api-integration">
              <Zap />  Api / Integration
            </Link>
          </li>

          <li className="menu-border-top">
            <a href="#javascript" onClick={doLogout}>
              <LogOut /> Log out
            </a>
          </li>
        </ul>
      </li>
    </Fragment>
  );
};

export default UserMenu;
