import React, { Component, Fragment } from "react";
import ReactLoading from "react-loading";
import { ToastContainer, toast } from "react-toastify";
import moment from 'moment'
import { Doughnut } from "react-chartjs-2";

import creApi from "../../services/creService";
import ViolationBlock from "./partials/violationBlock";
import RecentActivity from "./partials/recentActivity";
import AddressHeaderBlock from "./partials/addressHeaderBlock";
import SnapshotDataMenu from '../dashboard/snapshotDataMenu'
import SmallCalendar from '../calendar/smallCalendar';
import Marker from './maps/Marker';
import GoogleMap from './maps/GoogleMap';
import FileAttachments from './fileAttachments'
import guard from '../../common/guard';

import Chart from 'chart.js';

const colors = [
    "#62cc93",
    "#eb6c23",
    "#62c3cc"
]
Chart.pluginService.register({
    beforeRender: function (chart) {
        if (chart.config.options.showAllTooltips) {
            // create an array of tooltips
            // we can't use the chart tooltip because there is only one tooltip per chart
            chart.pluginTooltips = [];
            chart.config.data.datasets.forEach(function (dataset, i) {
                chart.getDatasetMeta(i).data.forEach(function (sector, j) {
                    chart.pluginTooltips.push(new Chart.Tooltip({
                        _chart: chart.chart,
                        _chartInstance: chart,
                        _data: chart.data,
                        _options: chart.options.tooltips,
                        _active: [sector]
                    }, chart));
                });
            });

            // turn off normal tooltips
            chart.options.tooltips.enabled = false;
        }
    },
    afterDraw: function (chart, easing) {
        if (chart.config.options.showAllTooltips) {
            // we don't want the permanent tooltips to animate, so don't do anything till the animation runs atleast once
            if (!chart.allTooltipsOnce) {
                if (easing !== 1)
                    return;
                chart.allTooltipsOnce = true;
            }

            // turn on tooltips
            chart.options.tooltips.enabled = true;
            Chart.helpers.each(chart.pluginTooltips, function (tooltip) {
                tooltip.initialize();
                tooltip.update();
                // we don't actually need this since we are not animating tooltips
                tooltip.pivot();
                tooltip.transition(easing).draw();
            });
            chart.options.tooltips.enabled = false;
        }
    }
});

class ViolationsAll extends Component {
    state = this.props.jestData !== undefined ? this.props.jestData : {
        doneLoadingViolations: false,
        creDefaultViolations: {},
        addressData: {},
        violationsData: {},
        filter: {},
        places: [],
        hasStopWorkOrders: false,
        hasVacateOrders: false,
        selectedDate: null,
        snapshotData: {},
        snapshotDataLoading: false,
    };


    setSelectedDate = (selectedDate) => this.setState({ selectedDate, snapshotData: {} });

    getViolationsData = async (bin) => {
        try {
            let propertyInfo = await creApi.getPropertyInfo(bin);
            if (propertyInfo.data?.status === false) {
                this.props.history.push('/my-buildings')
                toast.error(propertyInfo.data?.message)
            } else {
                this.setState({ addressData: propertyInfo.data });

                let violationsData = await creApi.getViolationsData(bin);

                const { ECB, DOB, HPD } = violationsData.data
                const dataCounts = [DOB, HPD, ECB].map(el => {
                    let count = 0
                    for (let key in el) {
                        if (el[key].Open) {
                            count += el[key].Open
                        }
                    }
                    return count
                })
                let customData = {}
                // check permission
                if (guard({
                    allow: () => true,
                    deny: () => false,
                    serviceName: 'custom-compliance',
                    action: 'compliance-full-access',
                })) {
                    let cutsom = await creApi.getCustomComplianceDashboard(bin);
                    if (cutsom.data?.customCompliances) {
                        customData['Compliace Portal'] = cutsom.data?.customCompliances.reduce((ac, a) => {
                            if (a.totalAllTime) {
                                ac[a.name] = a.totalAllTime
                            }
                            return ac

                        }, {})
                    }
                }



                this.setState({
                    dataCounts,
                    doneLoadingViolations: true,
                    creDefaultViolations: { ...violationsData.data, ...customData },
                });
                this.identityVendorNotifications(violationsData.data);
            }
        } catch (e) {
            toast.error(e.message)
        }
    }
    getViolationsDataSnapshot = async (bin) => {
        this.setState({
            snapshotDataLoading: true
        });
        try {
            if (this.state.selectedDate) {
                const date = moment(new Date(this.state.selectedDate)).format('L')
                let snapshotData = await creApi.getViolationsDataSnapshot(bin, date);
                this.setState({
                    snapshotData: snapshotData.data
                });
            } else {
                this.setState({
                    snapshotData: null
                });
            }
        } catch (e) {
            toast.error(e.message)
        }
        this.setState({
            snapshotDataLoading: false
        });
    }

    componentDidMount() {
        //setInterval( () => {this.setState({doneLoadingViolations: true,doneLoadingActivity: true})}, 1000 )

        this.getViolationsData(this.props.match.params.id);
    }

    setVPU = (VPU) => {
        this.setState({ addressData: { ...this.state.addressData, VPU } })
    }

    componentDidUpdate(prevProps, prevState) {
        // Typical usage (don't forget to compare props):
        if (this.props.match.params.id !== prevProps.match.params.id) {
            this.setState({ doneLoadingViolations: false })
            this.getViolationsData(this.props.match.params.id);
        }
        if (this.state.selectedDate !== prevState.selectedDate) {
            this.getViolationsDataSnapshot(this.props.match.params.id);
        }
    }

    identityVendorNotifications = (data) => {
        if (data.DOB?.Vacate_Order.Open > 0) {
            this.setState({ hasVacateOrders: true })
        }
        if (data.DOB?.Stop_Work_Order.Open > 0) {
            this.setState({ hasStopWorkOrders: true })
        }
    }

    handleBack = () => {
        this.props.history.push('/my-buildings')
    };

    render() {
        const SWO = this.state.addressData.SWO
        const VO = this.state.addressData.VO
        const lat = this.state.addressData.hasOwnProperty('coordinates') ? this.state.addressData.coordinates['lat'] : 40.8174;
        const lng = this.state.addressData.hasOwnProperty('coordinates') ? this.state.addressData.coordinates['lng'] : -73.9576;
        return (
            <Fragment>
                <ToastContainer />

                <div className="container-fluid">
                    <div className="row">
                        <div className="col-xl-9 building-detail pr-2">
                            <div className="row ">
                                <div className="col-md-12">
                                    {this.state.addressData &&
                                        <AddressHeaderBlock data={this.state.addressData} handleGoBack={this.handleBack} setVPU={this.setVPU} />
                                    }
                                </div>
                            </div>
                            <div className="row-body">
                                <div className="row">
                                    <div className="col-md-12">
                                        {this.state.hasVacateOrders && <div className="alert alert-warning text-uppercase text-center font-weight-bold">
                                            {
                                                VO.includes('Y1') ? 'Full Vacate Order Exists on this property' : (
                                                    VO.includes('Y3') ? 'Partial Vacate Order Exists on this property' : 'Vacate Order Exists on this property'
                                                )
                                            }
                                        </div>}
                                        {this.state.hasStopWorkOrders && <div className="alert alert-danger text-uppercase text-center font-weight-bold">
                                            {
                                                SWO.includes('A3') ? 'Full Stop Work Order exists on this property' : (
                                                    SWO.includes('L1') ? 'Partial Stop Work Order exists on this property' : 'Stop Work Order exists on this property'
                                                )
                                            }

                                        </div>}
                                        <div className="map" style={{ height: '280px' }}>
                                            <GoogleMap
                                                defaultZoom={16}
                                                center={[parseFloat(lat), parseFloat(lng)]}
                                                lat={parseFloat(lat)}
                                                lng={parseFloat(lng)}
                                                yesIWantToUseGoogleMapApiInternals
                                                loaded={this.state.doneLoadingViolations}
                                            >
                                                <Marker
                                                    lat={lat}
                                                    lng={lng}
                                                    name="New York"
                                                    color="blue"
                                                />
                                            </GoogleMap>
                                        </div>
                                    </div>
                                </div>
                                <div className="d-flex justify-content-end">
                                    <SnapshotDataMenu selectedDate={this.state.selectedDate} setSelectedDate={this.setSelectedDate} />
                                </div>
                                <div className="row">
                                    {!this.state.doneLoadingViolations ? (<div className="col-md-12"><ReactLoading type={"cylon"} color={"grey"} /> </div>) :
                                        (
                                            Object.keys(this.state.creDefaultViolations).map((violationType, index) =>
                                                <div className={`${this.state.addressData?.locationEnv === 'Philadelphia' ? 'col-12' : 'col-xl-3 col-md-4'} d-flex`} key={index}>
                                                    <ViolationBlock
                                                        addressData={this.state.addressData}
                                                        title={violationType}
                                                        data={this.state.creDefaultViolations[violationType]}
                                                        snapshotData={this.state.snapshotData?.[violationType]}
                                                        snapshotDataLoading={this.state.snapshotDataLoading}
                                                        doneLoading={this.state.doneLoadingViolations}
                                                        bin={this.state.addressData.bin}
                                                        history={this.props.history}
                                                        snapshotDate={this.state.selectedDate}
                                                    />
                                                </div>
                                            ))
                                    }
                                </div>
                            </div>
                        </div>

                        <div className="col-xl-3 recent-activity pl-0 pr-0">
                            <div className="pt-3 pb-3">
                                {this.props.jestData !== undefined ? null : <SmallCalendar />}
                            </div>
                            {this.state.dataCounts && this.state.addressData?.locationEnv === 'NYC' &&
                                <div className="card user-card pointer">

                                    <Doughnut
                                        data={{
                                            labels: ['DOB', 'HPD', 'ECB'],
                                            datasets: [{
                                                data: this.state.dataCounts,
                                                backgroundColor: colors,
                                                hoverBackgroundColor: colors
                                            }]
                                        }}
                                        options={{
                                            responsive: true,
                                            maintainAspectRatio: true,
                                            showAllTooltips: true,
                                            tooltips: {
                                                yAlign: 'bottom',
                                                xAlign: 'right',
                                                rtl: true,
                                                backgroundColor: 'rgba(0, 0, 0, 0.4)',
                                                callbacks: {
                                                    label: (tooltipItem, data) => {
                                                        var dataset = data.datasets[tooltipItem.datasetIndex];
                                                        var total = dataset.data.reduce((previousValue, currentValue) => {
                                                            return previousValue + currentValue;
                                                        });
                                                        var currentValue = dataset.data[tooltipItem.index];
                                                        var precentage = Math.floor(((currentValue / total) * 100) + 0.5);
                                                        return precentage + "%";
                                                    }
                                                },


                                            },
                                            onClick: (evt, element) => {
                                                if (element.length > 0 && element[0]?._view?.label) {
                                                    this.props.history.push(`/violations/category/${this.state.addressData.bin}/${element[0]._view.label}/Open`)
                                                }
                                            }
                                        }}
                                    />
                                </div>
                            }
                            <FileAttachments bin={this.state.addressData.bin} />
                            <RecentActivity
                                type="buildingActivity"
                                match={this.props.match}
                                history={this.props.history}
                                bin={this.props.match.params.id}
                            />
                        </div>

                    </div>
                </div>
            </Fragment>

        );
    }
}

export default ViolationsAll;
