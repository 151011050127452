import React, { useEffect, useState } from 'react';
import { toast } from 'react-toastify';

import PreferenceDetails from './preferenceDetails';
import creService from '../../../services/creService';

const AlertPreference = ({ jestData = false }) => {
    const [loading, setLoading] = useState(false);
    const [data, setData] = useState(jestData ? jestData : '');
    const [addresses, setAddresses] = useState([]);
    const handleChange = (category, group, day, checked) => {
        const selectedGroup = data[group];
        const selectedCategory = selectedGroup.find(key => key.displayName === category);
        const index = selectedGroup.indexOf(selectedCategory);
        selectedCategory.alertDays[day] = !checked;
        data[group][index] = selectedCategory;
        setData({ ...data });
    }

    const handleBuildingCheck = (bin, value, group, category) => {
        const selectedGroup = data[group];

        for (let i = 0; i < selectedGroup.length; i++) {
            if (selectedGroup[i].displayName === category) {
                const updatedCategory = selectedGroup[i]
                if (!updatedCategory.excludedBins) updatedCategory.excludedBins = []
                if (value) {
                    if (bin) {
                        updatedCategory.excludedBins.push(bin)
                    } else {
                        updatedCategory.excludedBins = addresses.map(el => el.bin)
                    }
                } else {
                    if (bin) {
                        updatedCategory.excludedBins = updatedCategory.excludedBins.filter(el => el !== bin)
                    } else {
                        updatedCategory.excludedBins = []
                    }
                }
                data[group][i] = updatedCategory;
                setData({ ...data });
                break;
            }
        }
    }

    const handleSelectDeselectAll = (day, state) => {
        Object.keys(data).map(group => {
            data[group].map(preference => {
                preference.alertDays[day] = state;
                return true;
            })
            return true;
        })
        setData({ ...data });
    }

    async function fetchData() {
        try {
            const res = await creService.getAllAlertPreferences();
            if (res.data) setData(res.data);
            const buildingsList = await creService.getBuildingsListOnly('portfolio');
            if (buildingsList.data) {
                setAddresses(buildingsList.data)
            }
        } catch (error) {
            toast.error(error);
        }
    }
    useEffect(() => {
        fetchData();
    }, []);

    async function savePreference() {
        setLoading(true);
        try {
            let userPreferences = [];
            Object.keys(data).map((group) => userPreferences.push(...data[group]));
            const res = await creService.updateAlertPreferences(userPreferences);
            if (res.data.status && typeof res.data.message !== 'undefined') {
                toast.success(res.data.message);
            } else {
                toast.error(res.data?.message || 'Something went wrong.');
            }
        } catch (error) {
            toast.error(error);
        }
        setLoading(false);
    }

    return (
        <React.Fragment>
            <PreferenceDetails data={data} handleChange={handleChange} handleSelectDeselectAll={handleSelectDeselectAll} addresses={addresses} handleBuildingCheck={handleBuildingCheck} />
            <button onClick={savePreference} disabled={loading} className="btn mb-4 btn-primary">
                {!loading ? 'Save' : 'Saving...'}
            </button>
        </React.Fragment>
    );
}

export default AlertPreference;
