import React, { Fragment, useState, useEffect } from 'react';
import { Link, useHistory, useLocation } from 'react-router-dom';
import { toast } from 'react-toastify';
import DataTable from 'react-data-table-component';
import moment from 'moment';
import Swal from 'sweetalert2/dist/sweetalert2.js';

import creService from '../../services/creService';
import FilterReactDataTable from '../../common/filterReactDataTable';

const FireSafetyList = () => {
  const history = useHistory();
  const location = useLocation();
  const urlParams = new URLSearchParams(window.location.search);
  const [data, setData] = useState([]);
  const [filterText, setFilterText] = React.useState('');
  const [resetPaginationToggle, setResetPaginationToggle] =
    React.useState(false);
  const filteredItems = data.filter((item) => {
    if (filterText.length > 0) {
      if (
        (item.bin &&
          item.bin
            .toString()
            .toLowerCase()
            .includes(filterText.toLowerCase())) ||
        (item.building &&
          item.building.address
            .toLowerCase()
            .includes(filterText.toLowerCase()))
      ) {
        return true;
      }
      return false;
    }
    return true;
  });

  const columns = [
    {
      name: 'Bin',
      sortable: true,
      selector: (row) => row.bin,
    },
    {
      name: 'Building',
      sortable: true,
      selector: (row) =>
        row.building?.display_address
          ? row.building?.display_address
          : row.building?.address || '--',
    },
    {
      name: 'created By',
      sortable: true,
      selector: (row) => (row.createdBy?.name ? row.createdBy?.name : '--'),
    },
    {
      name: 'Added At',
      sortable: true,
      selector: (row) => moment(row.addedAt).format('MM/DD/YYYY'),
    },
    {
      name: 'Action',
      cell: (row) => (
        <>
          <button
            onClick={() => eventGeneratePreview(row._id)}
            className="btn btn-secondary btn-xs mr-2"
          >
            <i className="fa fa-file"></i> Preview
          </button>
          <Link
            to={`/fire-safety/edit/${row._id}`}
            className="btn btn-info btn-xs mr-2"
          >
            <i className="fa fa-edit"></i> Edit
          </Link>
        </>
      ),
    },
  ];

  const eventGeneratePreview = async (id) => {
    const formPreview = await creService.previewFireSafety(id);
    previewModal(formPreview.data);
  };

  const previewModal = (html) => {
    Swal.fire({
      showCloseButton: true,
      showCancelButton: false,
      showConfirmButton: false,
      grow: 'fullscreen',
      html: html,
    });
  };

  const fetchFireSafety = async () => {
    try {
      const { data } = await creService.getAllFireSafety({
        bins: [],
      });
      if (data.status) {
        setData(data.data);
      } else {
        toast.error(data.message);
      }
    } catch (_err) {
      toast.error(_err.message);
    }
  };

  const subHeaderComponentMemo = React.useMemo(() => {
    const handleClear = () => {
      if (filterText) {
        setResetPaginationToggle(!resetPaginationToggle);
        setFilterText('');
      }
    };

    return (
      <div className="row my-3">
        <div className="col-12 col-md-4"></div>
        <div className="col-12 col-md-4"></div>
        <div className="col-12 col-md-4">
          <FilterReactDataTable
            onFilter={(e) => setFilterText(e.target.value)}
            onClear={handleClear}
            filterText={filterText}
          />
        </div>
      </div>
    );
  }, [filterText, resetPaginationToggle]);

  const changeRowsPerPageHandler = (pages) => {
    const params = new URLSearchParams(window.location.search);
    params.set('rowsPerPage', pages)

    history.replace({ pathname: location.pathname, search: params.toString() });
  };

  const changePageHandler =  (newPage) => {
    const params = new URLSearchParams(window.location.search);
    params.set('page', newPage)

    history.replace({ pathname: location.pathname, search: params.toString() });
  };

  useEffect(() => {
    fetchFireSafety();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Fragment>
      <div className="row violation-sec">
        <div className="col-xl-12 my-3">
          <div className="card">
            <div className="card-header row">
              <div className="col-6">
                <h5>Fire Safety</h5>
              </div>
              <div className="col-6 text-right">
                <Link to="/fire-safety/create">
                  <button className="btn btn-primary text-white" type="button">
                    Create New Fire Safety
                  </button>
                </Link>
              </div>
            </div>
            <div className="card-body data-table">
              <DataTable
                columns={columns}
                data={filteredItems}
                paginationResetDefaultPage={resetPaginationToggle}
                subHeaderComponent={
                  data.length > 0 ? subHeaderComponentMemo : null
                }
                paginationRowsPerPageOptions={[
                  10, 20, 30, 40, 50, 60, 70, 80, 90, 100,
                ]}
                paginationDefaultPage={urlParams.get('page') || 1}
                onChangePage={changePageHandler}
                paginationPerPage={urlParams.get('rowsPerPage') || 50}
                onChangeRowsPerPage={changeRowsPerPageHandler}
                subHeader
                pagination
                responsive
                striped
                noHeader
              />
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default FireSafetyList;
