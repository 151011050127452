import React, { useEffect, useRef, useState, useCallback } from 'react';
import { createPortal } from 'react-dom';
import { Skeleton, Table, Checkbox, Spin, Modal, Input, Button } from 'antd';
import ReactDragListView from 'react-drag-listview';
import { toast } from 'react-toastify';
import {
  DeleteOutlined,
  CloseCircleOutlined,
  RollbackOutlined,
} from '@ant-design/icons';
import creService from '../../services/creService';
import { getTableSizeFromStorage, setTableSizeInStorage } from './utils/common';
import AddCustomColumnDropdown from './components/AddCustomColumnDropdown';
import { exportCsv, exportPDF } from './utils/export';
import TableHeaderWithSearchBar from './components/TableHeaderWithSearchBar';
import AdvancedSearch from './components/AdvancedSearch';
import {
  modifyColumnForTable,
  getRenderFunctionForCustomColumns,
} from './utils/buildColumns';
import ModalColor from '../../pages/violations/violationsTable/components/ModalColor';
import ModalVendors from '../../pages/violations/violationsTable/components/ModalVendors';
import ViolationsTableCharts from './components/Charts';
import PivotTable from './components/PivotTable';
import { Zoom } from 'react-toastify';
import './tableViolation.css';
import moment from 'moment';

const stringSearch = (str, search) => {
  return String(str).toLowerCase().includes(search.toLowerCase());
};

const TableViolation = (props) => {
  const [loading, setLoading] = useState(true);
  const [dataSource, setDataSource] = useState([]);
  const [searchString, setSearchString] = useState('');
  const [isModalSearchTermVisible, setIsModalSearchTermVisible] =
    useState(false);
  const [columns, setColumns] = useState([]);
  const [filterdColumns, setFilterdColumns] = useState([]);
  const [tableSize, setTableSize] = useState(getTableSizeFromStorage());
  const [modalShowColor, setModalShowColor] = useState(false);
  const [modalVendorColumn, setModalVendorColumn] = useState(null);
  const [uniqueKeys, setUniqueKeys] = useState({});
  const [searchTerms, setSearchTerms] = useState([
    { label: 'sample', _id: '123' },
  ]);
  const [currentSearchTerm, setCurrentSearchTerm] = useState({});

  const [fullSizeTable, setFullSizeTable] = useState(false);
  const [pivotSecondKey, setPivotSecondKey] = useState(null);
  const [pivotTableVisibility, setPivotTableVisibility] = useState(false);
  const [showAdvancedSearch, setShowAdvancedSearch] = useState(false);
  const [advancedSearchValues, setAdvancedSearchValues] = useState({});
  const [showLoadModel, setShowLoadModel] = useState(false);
  const [filterdDataSource, setFilterdDataSource] = useState([]);
  const [agencyGroups, setAgencyGroups] = useState([]);
  const [tablePosition, setTablePosition] = useState(0);
  const subUsersRef = useRef();
  const vendorsRef = useRef();
  const toastId = useRef(null);

  const coppedMessage = useRef(null);

  const handleUserKeyPress = useCallback((event) => {
    const { keyCode } = event;

    if (keyCode === 27) {
      setFullSizeTableHandler(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    window.addEventListener('keydown', handleUserKeyPress);
    return () => {
      window.removeEventListener('keydown', handleUserKeyPress);
    };
  }, [handleUserKeyPress]);

  useEffect(() => {
    setShowAdvancedSearch(props.advancedSearchDefault ? true : false);
  }, [props.advancedSearchDefault]);
  useEffect(() => {
    if (dataSource.length) {
      setFilterdDataSource(dataSourceFilter(dataSource, searchString));
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dataSource, showAdvancedSearch, advancedSearchValues, searchString]);

  useEffect(() => {
    generateInitialData(props.data).catch((error) => {
      toast(error.message);
    });
    return () => {
      toast.dismiss(toastId.current);
      toastId.current = null;
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.data, props.options]);

  useEffect(() => {
    setFilterdColumns(
      columns.filter((el) => !el.hide).sort((a, b) => a.order - b.order)
    );
  }, [columns]);

  const generateInitialData = async (data) => {
    setLoading(true);
    await generalData(data);
    setLoading(false);
    if (props.searchString) {
      setSearchString(props.searchString);
    }
  };
  const pdfExportHandler = async (setLoading) => {
    setLoading(true);
    try {
      await exportPDF(
        columns,
        filterdDataSource,
        props.data?.category,
        props.data?.sub,
        props.saveOn
      );
    } catch (err) {
      toast.error(err.message);
    }

    setLoading(false);
  };

  const getCustomFields = async (category, sub) => {
    const categories = await creService.getCustomFields(category, sub);
    if (!categories.data?.customFields) {
      return [
        [],
        categories.data.customFields?.categoriesCustomOptions,
        categories.data.uniqueKeys,
        [],
      ];
    }

    return [
      categories.data.customFields.fields,
      categories.data.customFields.categoriesCustomOptions,
      categories.data.uniqueKeys,
      categories.data.customFields.searchTerms || [],
    ];
  };

  const generalData = async ({
    category,
    sub,
    dataSource,
    customFieldData,
    columns,
    options,
  }) => {
    if (!dataSource || dataSource.length === 0) return;
    const [customColumns, categoriesCustomOptions, uniqueKeys, terms] =
      await getCustomFields(category, sub);

    setSearchTerms(terms);
    const priorityViolations = await getPriority();
    const subUsers = await getSubUsers();
    const vendors = await getVendors();

    const { data } = await creService.getAgencyGroups();
    setAgencyGroups(data.agencies);
    let dataUniqueKeysExist = false;

    const dataSourceForTable = dataSource.map((violation) => {
      violation = {
        uniqueKeys,
        isPriority: priorityViolations.includes(violation._id),
        ...violation,
      };
      if (
        uniqueKeys &&
        violation[uniqueKeys.identifierField] &&
        violation[uniqueKeys.reportUseDate]
      ) {
        dataUniqueKeysExist = true;
      }
      let customDataItem;
      if (props.customFieldsFromViolation) {
        customDataItem = violation.customFieldData?.[0];
      } else {
        customDataItem = customFieldData[violation._id];
      }
      violation.key = violation._id;
      violation.clientDataId = customDataItem?._id;

      if (customFieldData && customDataItem?.fields) {
        const updatedViolation = { ...violation };

        for (let field of customDataItem.fields) {
          updatedViolation[field.fieldId] = field.data;
          updatedViolation[`${field.fieldId}_id`] = field._id;
        }
        return updatedViolation;
      } else {
        return violation;
      }
    });

    if (dataUniqueKeysExist) {
      setUniqueKeys(uniqueKeys);
    }

    setDataSource(dataSourceForTable);

    const updatedColums = columns.map((column) => {
      const updatedColumn = {
        sourceUpload: props.saveOn,
        ...column,
        ...(categoriesCustomOptions?.[column.key] || {}),
        category,
        sub,
      };
      if (options.fixedColumn && options.fixedColumn[column.key]) {
        updatedColumn.fixed = options.fixedColumn[column.key];
      }
      if (options.links && options.links[column.key]) {
        updatedColumn.linkFunc = (violation) =>
          handleViolationDetailsClick(violation, options.links[column.key]);
      }
      return updatedColumn;
    });

    const allColumns = [
      ...updatedColums,
      ...customColumns.map((customColumn) => {
        return {
          sourceUpload: props.saveOn,
          subUsers: customColumn.bcType === 'users' && subUsers,
          vendors: customColumn.bcType === 'vendors' && vendors,
          category,
          sub,
          title: customColumn.name,
          key: customColumn._id,
          isCustom: true,
          data: customColumn,
          ...(categoriesCustomOptions?.[customColumn._id] || {}),
        };
      }),
    ];

    // if here no master property wee need default one
    let canBeMaster = null;
    let foundMaster = false;
    if (
      !allColumns.some((el) => {
        if (!foundMaster) {
          if (!canBeMaster) {
            canBeMaster = el;
          }
          if (el.key === 'address') {
            canBeMaster = el;
            foundMaster = true;
          }
        }
        return el.isPivotMasterProperty;
      })
    ) {
      canBeMaster.isPivotMasterProperty = true;
    }

    const columnsForTable = allColumns.map((column) =>
      modifyColumnProcess(column)
    );
    if (props.removeFunction || props.multiChangeAction) {
      columnsForTable.unshift({
        title: props.multiChangeAction ? (
          <div className="d-flex">
            <Checkbox onChange={(e) => handleMultiChange(e.target.checked)} />
          </div>
        ) : (
          ''
        ),
        key: 'action',
        className: 'action-violation-table default-columns',
        description: 'Remove Action',
        fixed: true,
        sortDirections: ['descend'],
        sorter: (a, b) =>
          (a['multiChange'] ? 1 : 0) - (b['multiChange'] ? 1 : 0),
        render: (text, record) => (
          <div className="d-flex">
            {props.multiChangeAction && (
              <div>
                <Checkbox
                  onChange={(e) =>
                    handleMultiChange(e.target.checked, record._id)
                  }
                  checked={record.multiChange}
                />
              </div>
            )}
            {props.removeFunction && (
              <div className="ml-2" onClick={() => removeRow(record._id)}>
                <DeleteOutlined />
              </div>
            )}
            {props.currentArchive &&
              moment().diff(moment(record.updatedAt), 'minutes') >= 5 && (
                <div className="ml-2" onClick={() => unArchive(record._id)}>
                  <RollbackOutlined />
                </div>
              )}
          </div>
        ),
        width: 30,
      });
    }

    setColumns(columnsForTable);
  };
  const lastColumn = {
    title: () => (
      <AddCustomColumnDropdown
        isShortVersion
        columns={columns}
        addColumn={addColumn}
        addColumnSwitchHandler={addColumnSwitchHandler}
      />
    ),
    className: 'custom-columns checkbox-violation-table p-0',
    render: () => <></>,
  };
  const removeRow = async (id) => {
    try {
      const { data } = await props.removeFunction(id);
      setDataSource((pre) =>
        pre.filter((el) => {
          if (el._id !== id && !el.multiChange) {
            return true;
          } else {
            if (el._id !== id) {
              props.removeFunction(el._id);
            }
            return false;
          }
        })
      );
      toast.success(data.message);
    } catch (err) {
      toast.error(err.message);
    }
  };

  const unArchive = async (id) => {
    const ids = [id];
    setDataSource((pre) => {
      for (let el of pre) {
        if (el._id !== id && el.multiChange) {
          ids.push(el._id);
        }
      }
      props.archiveFunction(ids, true);
      return pre;
    });
  };

  const modifyColumnProcess = (column) =>
    modifyColumnForTable(
      column,
      // column methods
      handleColumnRemove,
      handleColumnHideToggle,
      // custom fields color methods
      handleColorLabelsUpdate,
      // custom fields general methods
      handleCustomDataChange,
      handleShowPivotTable,
      handleSubUserRestriction,
      handleSort,
      setModalVendorColumn,
      handleColumnToggle,
      handlePriorityChange,
      copyTextFunc,
      setDataSource
    );

  const handleColumnRemove = (key) => {
    setColumns((pre) => pre.filter((column) => column.key !== key));
  };

  const handleSubUserRestriction = (key) => {
    setColumns((pre) => {
      const newColumns = pre.map((column) => {
        if (column.key === key) {
          column.subUserRestriction = !column.subUserRestriction;
          column.render = getRenderFunctionForCustomColumns(column, {
            handleCustomDataChange,
            handleColorLabelsUpdate,
          });
        }
        return column;
      });

      updateCustomCategoryOptions(newColumns);
      return newColumns;
    });
  };

  const handleSort = (key, multi) => {
    let newSort = null;
    setColumns((pre) =>
      pre.map((column) => {
        if (column.key === key) {
          newSort = column.sortOrder
            ? column.sortOrder === 'descend'
              ? null
              : 'descend'
            : 'ascend';
          column.sortOrder = newSort;
        } else {
          if (!multi) {
            column.sortOrder = null;
          }
        }
        return column;
      })
    );
    return newSort;
  };

  const handleShowPivotTable = (key) => {
    setPivotTableVisibility(true);
    setPivotSecondKey(key);
  };

  const handleColumnHideToggle = (key) => {
    handleColumnToggle(key, 'hide');
  };

  const handleColumnToggle = (key, property, callback) => {
    setColumns((pre) => {
      const newColumns = pre.map((column) => {
        if (column.key === key) {
          return { ...column, [property]: !column[property] };
        }
        if ('customDashboard' === property) {
          return { ...column, customDashboard: false };
        }
        return column;
      });
      const promise = updateCustomCategoryOptions(
        newColumns,
        property === 'customDashboard'
      );
      if (callback) {
        callback(promise);
      }
      return newColumns;
    });
  };

  const handleSetColumnPivotMasterProperty = (key) => {
    setColumns((pre) => {
      const newColumns = pre.map((column) =>
        column.key === key
          ? { ...column, isPivotMasterProperty: true }
          : { ...column, isPivotMasterProperty: false }
      );
      updateCustomCategoryOptions(newColumns);
      return newColumns;
    });
  };
  const handleChartsDisplay = (key, showChart, chartType, chartTypeTicks) => {
    setColumns((pre) => {
      const newColumns = pre.map((column) =>
        column.key === key
          ? { ...column, chart: showChart, chartType, chartTypeTicks }
          : column
      );
      updateCustomCategoryOptions(newColumns);
      return newColumns;
    });
  };

  const updateCustomCategoryOptions = async (
    columns,
    refreshCustomDashboard
  ) => {
    try {
      let lastOrder = columns.reduce(
        (ac, c) => (!isNaN(c) && ac < c ? c : ac),
        0
      );
      const options = {};
      for (let i = 0; i < columns.length; i++) {
        options[columns[i].key] = {
          chartType: columns[i].chartType || 'Bar',
          chart: columns[i].chart || false,
          hide: columns[i].hide || false,
          order: columns[i].order || lastOrder++,
          subUserRestriction: columns[i].subUserRestriction || false,
          isPivotMasterProperty: columns[i].isPivotMasterProperty || false,
          addToCalendar: columns[i].addToCalendar || false,
          chartTypeTicks: columns[i].chartTypeTicks || 'day',
          customDashboard: columns[i].customDashboard || false,
        };
      }

      await creService.updateCustomFieldOptions(
        options,
        props.data.category,
        props.data.sub
      );

      if (refreshCustomDashboard) {
        props.refreshCustomDashboard();
      }
    } catch (err) {
      toast(err);
    }
  };

  const handleColorLabelsUpdate = (key, newlabels) => {
    setColumns((pre) =>
      pre.map((column) => {
        if (column.key === key) {
          const newcolumn = {
            ...column,
            data: { ...column.data, data: newlabels },
          };
          // for full column rerender we need update render func for color column
          newcolumn.render = getRenderFunctionForCustomColumns(newcolumn, {
            handleCustomDataChange,
            handleColorLabelsUpdate,
          });
          return newcolumn;
        }
        return column;
      })
    );
  };

  const handleAddCreatedVendor = async (
    { key, updateData, _id },
    newVendor
  ) => {
    updateData.data = newVendor._id;
    await handleCustomDataChange(_id, updateData);
    setColumns((pre) =>
      pre.map((column) => {
        if (column.key === key) {
          const newcolumn = {
            ...column,
            vendors: [...column.vendors, newVendor],
          };
          // for full column rerender we need update render func for color column
          newcolumn.render = getRenderFunctionForCustomColumns(newcolumn, {
            handleCustomDataChange,
            setModalVendorColumn,
          });
          return newcolumn;
        }
        return column;
      })
    );
  };

  const getPriority = async () => {
    try {
      const result = await creService.getPriority(
        props.data?.sub,
        props.data?.category
      );
      if (!result.data.status) {
        toast.error(`${result.data.message}`);
      } else {
        return result.data.data;
      }
    } catch (err) {
      toast.error(err.message);
    }

    return [];
  };

  const handlePriorityChange = async (id, priority) => {
    try {
      const result = await creService.setPriority(
        id,
        priority,
        props.data?.sub,
        props.data?.category
      );
      if (!result.data.status) {
        return toast.error(`${result.data.message}`);
      }

      setDataSource((pre) =>
        pre.map((record) => {
          if (record._id === id) {
            return { ...record, isPriority: priority };
          }
          return record;
        })
      );
      toast.info('Item Moved All the way on Top');
    } catch (err) {
      toast.error(err.message);
    }

    return;
  };

  const handleCustomDataChange = async (id, newData) => {
    await customDataServerSave(id, newData);

    const dataToSave = [];
    setDataSource((pre) =>
      pre.map((record) => {
        if (record._id === id || record.multiChange) {
          if (record.multiChange && record._id !== id) {
            dataToSave.push({
              id: record._id,
              data: {
                ...newData,
                violationFieldId: record[`${newData.fieldId}_id`],
                violationClientDataId: record.clientDataId,
              },
            });
          }
          return { ...record, [newData.fieldId]: newData.data };
        }
        return record;
      })
    );
    if (dataToSave.length) {
      await customDataServerSaveBatch(dataToSave);
    }
  };

  const customDataServerSaveBatch = async (dataToSave) => {
    setShowLoadModel(true);
    try {
      const result = await creService.updateCustomViolationFieldBatch(
        dataToSave,
        props.saveOn
      );
      if (!result.data.status) {
        return toast.error(`${result.data.message}, 
                            server error - [${result.data.error}]`);
      }
    } catch (err) {
      toast.error(err.message);
    }
    setShowLoadModel(false);
  };

  const customDataServerSave = async (id, newData) => {
    try {
      const result = await creService.updateCustomViolationField(
        id,
        newData,
        props.saveOn
      );
      if (!result.data.status) {
        return toast.error(`${result.data.message}, 
                            server error - [${result.data.error}]`);
      }
    } catch (err) {
      toast.error(err.message);
    }
  };

  const handleMultiChange = async (checked, violationID) => {
    setFilterdDataSource((filterdDataSource) => {
      setDataSource((pre) => {
        const result = pre.map((violation) => {
          if (
            violation._id === violationID ||
            (!violationID &&
              filterdDataSource.some((filter) => violation._id === filter._id))
          ) {
            return { ...violation, multiChange: checked };
          }
          return violation;
        });

        showSelected(result);

        return result;
      });
      return filterdDataSource;
    });
  };

  const getSubUsers = async () => {
    if (!subUsersRef.current) {
      const subUsersRes = await creService.getSubUsers();
      subUsersRef.current = subUsersRes.data.data || [];
    }
    return subUsersRef.current;
  };

  const getVendors = async () => {
    if (!vendorsRef.current) {
      const res = await creService.getVendors();
      vendorsRef.current = res.data.vendors || [];
    }
    return vendorsRef.current;
  };

  const addColumnSwitchHandler = async (name, data, type, bcType) => {
    const column = columns.find((col) => col.data?.bcType === bcType);
    if (!column) {
      addColumn(name, data, type, bcType);
    } else {
      handleColumnHideToggle(column.key);
    }
  };

  const addColumn = async (name, data, type, bcType) => {
    if (!data && bcType === 'colors') {
      setModalShowColor(true);
      return;
    }

    try {
      const dataArguments = [
        props.data.category,
        props.data.sub,
        type,
        name,
        data,
        bcType,
      ];
      const result = await creService.setCustomCategory(...dataArguments);

      if (result.data.status) {
        const newColumn = modifyColumnProcess({
          sourceUpload: props.saveOn,
          subUsers: bcType === 'users' && (await getSubUsers()),
          vendors: bcType === 'vendors' && (await getVendors()),
          title: result.data.name,
          key: result.data.id,
          isCustom: true,
          data: {
            name: result.data.name,
            _id: result.data.id,
            data: result.data.data,
            bcType: bcType,
            type: type,
          },
        });

        setColumns((pre) => [...pre, newColumn]);
        // here should be a change do not forget
        // if (bcType === 'priority') {
        //   setDataSource((pre) =>
        //     pre.map((el) => ({ ...el, prioritySortField: result.data.id }))
        //   );
        // }
      } else {
        toast.error(result.data.message);
      }
    } catch (err) {
      toast.error(err.message);
    }
  };

  const handleTableSizeChange = (event) => {
    setTableSize(() => event.target.value);
    setTableSizeInStorage(event.target.value);
  };

  const dataSourceFilter = (dataSource, value) => {
    let result = dataSource;
    if (value) {
      result = result.filter((row) => {
        let found;
        for (let column of columns) {
          if (column.isCustom) {
            if (column.data.bcType === 'colors') {
              found = column.data?.data?.some?.(
                (colorLabel) =>
                  colorLabel.id === row[column.key] &&
                  stringSearch(colorLabel.text, value)
              );
            } else if (column.data.bcType === 'users') {
              found = column.subUsers?.some((el) => {
                return (
                  el._id === row[column.key] && stringSearch(el.fullName, value)
                );
              });
            } else if (column.data.bcType === 'vendors') {
              found = column.vendors?.some((el) => {
                return (
                  el._id === row[column.key] && stringSearch(el.fullName, value)
                );
              });
            } else if (
              column.data.type === 'text' ||
              column.data.type === 'number' ||
              column.data.type === 'date'
            ) {
              found = stringSearch(String(row[column.key]), value);
            } else {
              found = false;
            }
          } else {
            found = stringSearch(row[column.key], value);
          }

          if (found) {
            break;
          }
        }

        return found;
      });
    }
    if (showAdvancedSearch) {
      result = result.filter((el) => {
        for (let key in advancedSearchValues) {
          if (!advancedSearchValues[key].length) continue;
          let res;
          if (el[key]) {
            res = advancedSearchValues[key].some((value) => el[key] === value);
          } else {
            res = advancedSearchValues[key].some(
              (value) => Boolean(el[key]) === Boolean(value)
            );
          }

          if (!res) return false;
        }
        return true;
      });
    }

    const prioritySorted = result.sort(
      (a, b) => (b.isPriority ? 1 : 0) - (a.isPriority ? 1 : 0)
    );
    return prioritySorted;
  };

  const createColorColumn = async (data) => {
    let initialData = [];
    if (data) {
      initialData = data;
    } else {
      initialData = [
        {
          text: 'Label1',
          color: '#f3a53c',
        },
        {
          text: 'Label2',
          color: '#db4057',
        },
        {
          text: 'Label3',
          color: '#09ba70',
        },
      ];
    }

    await addColumn('Color', initialData, 'bcType', 'colors');
    setModalShowColor(false);
  };

  const handleViolationDetailsClick = (record, href) => {
    let dividedHref = href.split('/');
    let newHref = [];
    for (let el of dividedHref) {
      if (~el.indexOf(':')) {
        el = el.replace(':', '');
        let dividedEl = el.split('.');
        if (dividedEl[0] === 'record') {
          el = record[dividedEl[1]];
        } else if (dividedEl[0] === 'props') {
          el = props.data[dividedEl[1]];
        }
      }
      newHref.push(el);
    }
    newHref = newHref.join('/');
    return newHref;
  };

  const copyTextFunc = (text, x, y) => {
    navigator.clipboard.writeText(text);
    coppedMessage.current.style.left = x + 'px';
    coppedMessage.current.style.top = y - 34 + 'px';
    coppedMessage.current.style.display = 'block';
    setTimeout(() => {
      coppedMessage.current.style.display = 'none';
    }, 1000);
  };

  const showSelected = (data) => {
    const checkedData = data.filter((el) => el.multiChange);
    if (checkedData.length !== 0) {
      const content = (
        <ul>
          <div> {checkedData.length} selected Items</div>
          {checkedData.slice(0, 8).map((el) => (
            <li key={el._id}>
              - {el[el.uniqueKeys?.identifierField] || el._id}
            </li>
          ))}
          {checkedData.length > 8 && (
            <li> ... {checkedData.length - 8} more </li>
          )}
        </ul>
      );
      if (toastId.current) {
        toast.update(toastId.current, {
          render: content,
        });
      } else {
        toastId.current = toast(content, {
          position: 'bottom-left',
          autoClose: false,
          closeButton: false,
          hideProgressBar: false,
          closeOnClick: false,
          draggable: false,
          theme: 'light',
          transition: Zoom,
          className: 'table-viol-checked',
        });
      }
    } else {
      toast.dismiss(toastId.current);
      toastId.current = null;
    }

    props.archiveFunction?.(checkedData.map((el) => el._id));
  };

  const dragEndHandler = async (fromIndex, toIndex) => {
    if (toIndex < 0) {
      return;
    }
    let startIndex;
    let endIndex;
    const sorted = columns.sort((a, b) => a.order - b.order);
    for (let i = 0; i < sorted.length; i++) {
      if (startIndex && endIndex) break;
      if (filterdColumns[fromIndex].key === sorted[i].key) {
        startIndex = i;
      } else if (filterdColumns[toIndex].key === sorted[i].key) {
        endIndex = i;
      }
    }

    const newColumn = [...sorted].map((column, i) => {
      if (!column.order) {
        column.order = i;
      }
      return column;
    });

    if (startIndex > endIndex) {
      for (let i = endIndex; i < startIndex; i++) {
        newColumn[i].order = (newColumn[i].order || i) + 1;
      }
    } else {
      for (let i = endIndex; i > startIndex; i--) {
        newColumn[i].order = (newColumn[i].order || i) - 1;
      }
    }

    newColumn[startIndex].order = endIndex;

    await updateCustomCategoryOptions(newColumn);

    setColumns(newColumn);
  };
  const setFullSizeTableHandler = (value) => {
    if (value) {
      setTablePosition(window.scrollY);

      document.getElementById('root').style.display = 'none';
    } else {
      document.getElementById('root').style.display = '';
    }

    if (value) {
      window.scrollTo(0, 0);
    } else {
      setTimeout(() => window.scrollTo(0, tablePosition), 0);
    }
    setFullSizeTable(value);
  };

  const handleTableSearchTerm = (value, edit = false) => {
    setCurrentSearchTerm(
      value === 'save'
        ? {
            searchString: searchString,
            showAdvancedSearch: showAdvancedSearch,
            advancedSearchValues: advancedSearchValues,
          }
        : searchTerms.find((el) => el._id === value)
    );

    if (value === 'save' || edit) {
      setIsModalSearchTermVisible(true);
    } else {
      const fond = searchTerms.find((el) => el._id === value);

      setSearchString(fond.searchString);
      setShowAdvancedSearch(fond.showAdvancedSearch);
      setAdvancedSearchValues(fond.advancedSearchValues);
    }
  };
  const updateSearchTerms = async (searchTerms) => {
    try {
      const response = await creService.updateSearchTerms(
        props.data.category,
        props.data.sub,
        searchTerms
      );
      if (response.data.status) {
        setIsModalSearchTermVisible(false);
        setSearchTerms(response.data.data);
      } else {
        toast.error(response.data.message);
      }
    } catch (err) {
      toast.error(err.message);
    }
  };

  const table = (
    <div
      className={`row ${fullSizeTable ? 'table-violation-full-size' : ''}`}
      id="violation-table-main"
    >
      {createPortal(
        <div
          className="card noselect"
          ref={coppedMessage}
          style={{ position: 'absolute', zIndex: 1000, display: 'none' }}
        >
          <div className="card-body m-0 p-1 border">Copied</div>
        </div>,
        document.body
      )}
      {fullSizeTable && (
        <div className="d-flex justify-content-end w-100 mr-3">
          <CloseCircleOutlined
            onClick={() => setFullSizeTableHandler(false)}
            style={{
              color: '#CCCCC',
              cursor: 'pointer',
              fontSize: '40px',
              padding: '2px 5px',
            }}
          />
        </div>
      )}
      {!props.noCharts && (
        <ViolationsTableCharts
          columns={columns}
          dataSource={filterdDataSource}
          uniqueKeys={uniqueKeys}
          dashboard={props.data.dashboard}
          bin={props.data.bin}
          category={props.data.category}
          sub={props.data.sub}
        />
      )}
      <div className="col-12 pt-3 violation-detail">
        <TableHeaderWithSearchBar
          noCharts={props.noCharts}
          columns={columns}
          handleToggleVisibility={handleColumnHideToggle}
          handleChartsDisplay={handleChartsDisplay}
          handleTableSizeChange={handleTableSizeChange}
          setFullSizeTable={setFullSizeTableHandler}
          fullSizeTable={fullSizeTable}
          handleTableSearch={setSearchString}
          handleTableSearchTerm={handleTableSearchTerm}
          defaultSearch={searchString}
          searchTerms={searchTerms}
          toggleAdvancedSearchVisibility={() => {
            setShowAdvancedSearch(!showAdvancedSearch);
            setAdvancedSearchValues({});
          }}
          showAdvancedSearch={showAdvancedSearch}
          uniqueKeys={uniqueKeys}
          onCSVExport={() => {
            exportCsv(
              columns,
              filterdDataSource,
              props.data?.category,
              props.data?.sub,
              props.saveOn
            );
          }}
          onPDFExport={pdfExportHandler}
          options={{ tableSize }}
        >
          <AddCustomColumnDropdown
            columns={columns}
            addColumn={addColumn}
            addColumnSwitchHandler={addColumnSwitchHandler}
          />
        </TableHeaderWithSearchBar>
        {showAdvancedSearch && columns.length > 0 && (
          <AdvancedSearch
            agencyGroups={agencyGroups}
            columns={columns}
            dataSource={dataSource}
            advancedSearchHandler={setAdvancedSearchValues}
            advancedSearchDefault={props.advancedSearchDefault}
            advancedSearchValues={advancedSearchValues}
          />
        )}

        <ReactDragListView.DragColumn
          nodeSelector=".drag"
          handleSelector=".dragHandler"
          ignoreSelector="react-resizable-handle"
          onDragEnd={dragEndHandler}
        >
          {loading ? (
            <Skeleton active />
          ) : (
            <div className="d-flex">
              <Table
                className="violation-table"
                size={tableSize}
                columns={[...filterdColumns, lastColumn]}
                dataSource={filterdDataSource}
                scroll={{ x: 'max-content' }}
                rowClassName={'pointer'}
                style={{
                  whiteSpace: 'pre',
                }}
                sticky={{
                  offsetHeader: 80,
                }}
                pagination={{ pageSize: 30, showSizeChanger: false }}
                tableLayout="auto"
                bordered
                showHeader={filterdDataSource.length > 0}
                showSorterTooltip={false}
                onRow={() => ({
                  onDoubleClick: (event) => {
                    if (event.target.title) {
                      copyTextFunc(
                        event.target.title,
                        event.pageX,
                        event.pageY
                      );
                    }
                  },
                })}
              />
            </div>
          )}
        </ReactDragListView.DragColumn>
      </div>
      <ModalVendors
        modalVendorColumn={modalVendorColumn}
        setModalVendorColumn={setModalVendorColumn}
        handleAddCreatedVendor={handleAddCreatedVendor}
      />
      <ModalColor
        showModalColor={modalShowColor}
        createColorColumn={createColorColumn}
        closeModal={() => setModalShowColor(false)}
      />
      <PivotTable
        pivotSecondKey={pivotSecondKey}
        isVisible={pivotTableVisibility}
        setIsVisible={(bool) => {
          if (!bool) {
            setPivotSecondKey(null);
          }
          setPivotTableVisibility(bool);
        }}
        columns={filterdColumns}
        dataSource={filterdDataSource}
        handleSetColumnPivotMasterProperty={handleSetColumnPivotMasterProperty}
      />
      <Modal visible={showLoadModel} footer={null} closable={false}>
        <div className="d-flex justify-content-center align-items-center flex-column">
          <Spin />
          Saving Batch...
        </div>
      </Modal>
      <Modal
        title="Search Term"
        visible={isModalSearchTermVisible}
        footer={null}
        onCancel={() => setIsModalSearchTermVisible(false)}
      >
        <Input
          value={currentSearchTerm.label}
          onChange={(event) =>
            setCurrentSearchTerm({
              ...currentSearchTerm,
              label: event.target.value,
            })
          }
        />
        <div className="d-flex justify-content-between">
          <Button
            type="primary"
            className="mt-2"
            onClick={() =>
              updateSearchTerms(
                currentSearchTerm._id
                  ? searchTerms.map((el) => {
                      if (el._id === currentSearchTerm._id) {
                        el.label = currentSearchTerm.label;
                        return el;
                      } else {
                        return el;
                      }
                    })
                  : [...searchTerms, currentSearchTerm]
              )
            }
          >
            Save
          </Button>
          {currentSearchTerm._id && (
            <Button
              type="danger"
              className="mt-2"
              onClick={() =>
                updateSearchTerms(
                  searchTerms.filter(
                    (el) => el._id !== currentSearchTerm._id
                  )
                )
              }
            >
              Remove
            </Button>
          )}
        </div>
      </Modal>
    </div>
  );
  if (fullSizeTable) {
    return createPortal(table, document.body);
  }
  return table;
};

export default TableViolation;
