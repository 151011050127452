import ReactLoading from "react-loading";
import { InfoCircleOutlined } from "@ant-design/icons";
import { UncontrolledTooltip } from "reactstrap";

function Block({ sub, data, dashboardDetails, snapshotData, style, selectedDate, snapshotOnClickHandler, type }) {
    let display = data[sub]?.label ? data[sub]?.label : sub

    if (sub === 'BcompliantRetained') {
        display = 'Retained'
        style = { background: '#c36c00', color: 'white' }
    }


    let percent = ''
    let snapshot = snapshotData?.[sub] || 0
    let current = data[sub]?.total || 0

    if (current === snapshot || (current === 0 && snapshot === 0)) {
        percent = 0
    } else if (current === 0 && snapshot !== 0) {
        percent = snapshot * -100
    } else if (snapshot === 0 && current !== 0) {
        percent = 100 * current
    } else {
        let diff = (current - snapshot)
        percent = (diff / current * 100).toFixed(2);
    }
    const id = display.replace(/[\s.]/ig, '-').replace('Philadelphia_', '')

    return <div className={`${type === 'Philly' ? 'col-12' :'col-6 col-md-4'}  d-flex`} >
        <div className="card card-hover align-self-stretch flex-grow-1" style={style}>
            <div className={`card-body ${selectedDate ? 'pt-0 pb-4' : 'd-flex align-items-center'} `}>
                {selectedDate &&

                    <div className='d-flex justify-content-end'>
                        <div style={{ fontSize: '11px' }} >
                            {!snapshotData ?
                                <span className="mr-4"><ReactLoading type={'bars'} color={'#DCDCDC'} width={20} height={20} /></span>
                                :
                                <span onClick={() => snapshotOnClickHandler(sub)}>
                                    <div
                                        className={`text-center ${!style ? `alert ${percent === 0 ? 'alert-secondary' : percent > 0 ? 'alert-warning' : 'alert-success'} p-0 m-0` : 'pr-1'}`}  >
                                        {`${percent > 0 ? '+' + percent : percent}${percent === 0 || percent === 100 ? '.0' : ''}%`}
                                    </div>

                                    <div className='text-center' id={id}>{snapshotData?.[sub] || 0} <InfoCircleOutlined /></div>
                                    <UncontrolledTooltip
                                        placement="top"
                                        autohide={true}
                                        target={id}
                                    >
                                       as of {selectedDate} Total was: {snapshotData?.[sub] || 0}
                                    </UncontrolledTooltip>
                                </span>
                            }
                        </div>
                    </div>
                }
                <div className="media-body text-center" onClick={() => data[sub]?.total > 0 && dashboardDetails(sub)}>
                    <h3>

                        {data[sub]?.total === '' ? (
                            <div align="center">
                                <ReactLoading type={'cylon'} color={'grey'} />
                            </div>
                        ) : data[sub]?.total > 0 ? (
                            <div className="pointer" >
                                {' '}
                                {data[sub]?.total}
                            </div>
                        ) : (
                            data[sub]?.total || 0
                        )}

                    </h3>
                    <p>{display}</p>
                </div>
            </div>
        </div>
    </div>
}


export default Block;


