import React, { Fragment, useEffect, useState, useContext } from "react";
import { ToastContainer, toast } from "react-toastify";
import Breadcrumb from "../../../common/breadcrumb";
import creApi from "../../../services/creService";
import ReactLoading from "react-loading";
import { SocketContext } from '../../../../context/socket';
import { Link } from "react-router-dom";
import { permissionsKey } from "../../../services/authService";
import Guard from "../../../common/guard";

const ChatsList = ({ jestData = false }) => {
    const socket = useContext(SocketContext);

    const [chatData, setChatData] = useState(jestData ? jestData.chatData : []);
    const [workOrderChatData, setWorkOrderChatData] = useState(jestData ? jestData.workOrderChatData : []);
    const [loading, setLoading] = useState(jestData ? jestData.loading : false);
    const [userPermissions, setUserPermissions] = useState(jestData ? jestData.userPermissions : JSON.parse(localStorage.getItem(permissionsKey))?.workOrders);

    const getChatList = async () => {
        setLoading(true);
        try {
            const chatList = await creApi.getChatList();
            setChatData(chatList.data.chats);
            setLoading(false);
        } catch (e) {
            toast.error(e.message)
        }
    }

    const getWorkOrderChatList = async () => {
        setLoading(true);
        try {
            const chatList = await creApi.getWorkOrderChatList();
            if (chatList.data.status) {
                if (chatList.data.permissions) {
                    localStorage.setItem(permissionsKey, JSON.stringify(chatList.data.permissions));
                    setUserPermissions(chatList.data.permissions['workOrders']);
                }
                setWorkOrderChatData(chatList.data.chats);
            }
        } catch (e) {
            toast.error(e.message)
        }
        setLoading(false);
    }

    useEffect(() => {
        getChatList();
        getWorkOrderChatList();

        const handler = (message) => {
            setChatData(message.chats);
        };

        const woChathandler = (message) => {
            setWorkOrderChatData(message.chats);
        };

        if (socket) {
            socket.on('chat-message', handler);
            socket.on('work-order-chat-message', woChathandler);
            return () => socket.off('chat-message', handler);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    return (
        <Fragment>
            <ToastContainer />
            <div className="container-fluid">
                <div className="row">
                    <div className="col-xl-9 xl-100">
                        <Breadcrumb title="Chats" parent="Dashboard" />
                        {loading ? (<ReactLoading type={"cylon"} color={"grey"} />) :
                            (<div className="card">
                                {chatData.length > 0 &&
                                    <Guard
                                        serviceName="violations"
                                        action="all"
                                        allow={() => (
                                            <>
                                                <div className="card-header">
                                                    <h5>Violation Chats</h5>
                                                </div>
                                                <div className="container">
                                                    <div className="card">
                                                        <ul className="list-group list-group-flush">
                                                            {
                                                                chatData && chatData.map((value, key) => {
                                                                
                                                                    return <li key={key} className="list-group-item chat-div">
                                                                        <Link
                                                                            className="d-flex justify-content-between text-dark text-decoration-none pointer"
                                                                            to={`/${value.violation.category !== 'Compliance' ? 'violation-details' : 'custom-compliance/' + value.violation.sub}/${value.violation._id}/chats#chatMessage`}
                                                                        >
                                                                            <div>
                                                                                {value.violation.category.replace(/_/g, ' ').replace(/__/g, ' ')}/
                                                                                {value.violation.sub.replace(/_/g, ' ').replace(/__/g, ' ')}
                                                                                <span className="badge badge-dark ml-2">{value.uniqueData.displayName}: {value.uniqueData.value}</span>
                                                                                {(value.unreadMessagesCount) > 0 &&
                                                                                    <span className="badge badge-danger">{value.unreadMessagesCount} New</span>
                                                                                }
                                                                                <small className="d-block">
                                                                                    {value.building.display_address ? value.building.display_address : value.building.address}
                                                                                </small>
                                                                            </div>
                                                                            <div>
                                                                                <span className="badge badge-info">
                                                                                    {value.messages.length} {value.messages.length === 1 ? 'Message' : 'Messages'}
                                                                                </span>
                                                                            </div>
                                                                        </Link>
                                                                    </li>
                                                                })
                                                            }
                                                        </ul>
                                                    </div>
                                                </div>
                                            </>
                                        )}
                                    />
                                }
                                {(!userPermissions || userPermissions.includes('view')) &&
                                    workOrderChatData.length > 0 &&
                                    <>
                                        <div className="card-header">
                                            <h5>Work Order Chats</h5>
                                        </div>
                                        <div className="container">
                                            <div className="card">
                                                <ul className="list-group list-group-flush">
                                                    {loading ? (<ReactLoading type={"cylon"} color={"grey"} />) : (
                                                        workOrderChatData.map((value, key) => {
                                                            return <li key={key} className="list-group-item chat-div">
                                                                <Link className="d-flex justify-content-between text-dark text-decoration-none pointer" to={`/work-orders/view/${value.workOrder._id}/chats`} >
                                                                    <div>
                                                                        {value.workOrder.category}
                                                                        <span className="badge badge-dark ml-2">#{value.workOrder.workOrderId}</span>
                                                                        {(value.unreadMessagesCount) > 0 &&
                                                                            <span className="badge badge-danger">{value.unreadMessagesCount} New</span>
                                                                        }
                                                                        <small className="d-block">
                                                                            {value.building.display_address ? value.building.display_address : value.building.address}
                                                                        </small>
                                                                    </div>
                                                                    <div>
                                                                        <span className="badge badge-info">
                                                                            {value.messages.length} {value.messages.length === 1 ? 'Message' : 'Messages'}
                                                                        </span>
                                                                    </div>
                                                                </Link>
                                                            </li>
                                                        })
                                                    )}
                                                </ul>
                                            </div>
                                        </div>
                                    </>
                                }
                                {chatData.length <= 0 && (workOrderChatData.length <= 0 || (userPermissions && !userPermissions?.includes('view'))) && !loading && <div className="alert alert-info mt-3">No Chats</div>}
                            </div>)}
                    </div>
                    {/* <div className="col-xl-3 recent-activity pr-0">
                        <div className="pt-3 pb-3">
                            <SmallCalendar />
                        </div>
                        <HistoryUpdates />
                    </div> */}
                </div>
            </div>
        </Fragment>
    )
}

export default ChatsList;