import React, { Fragment, useState, useEffect, useRef } from 'react';
import { toast } from 'react-toastify';
import { Link, useHistory, useLocation } from 'react-router-dom';
import { useParams } from 'react-router';
import { Button, ButtonGroup, Tooltip } from 'reactstrap';
import moment from 'moment';
import { Typeahead } from 'react-bootstrap-typeahead';
import { Checkbox } from 'antd';

import creService from '../../services/creService';
import WorkOrderUploadDocument from './uploadDocument';
import { permissionsKey } from '../../services/authService';
import ReactLoading from 'react-loading';
import PartialEditTextFields from './partialEditTextFields';
import DueDatePicker from './components/due-date-picker';

const WorkOrderCreate = (props) => {
  const previousLocation = props.location.state?.from;
  const previousLocationFullPath = previousLocation
    ? `${previousLocation.pathname}${previousLocation.search}`
    : '';
    
  const history = useHistory();
  const params = useParams();
  const location = useLocation();
  const cloneId = new URLSearchParams(location.search).get('clone');
  const workOrderId = params.id ? params.id : cloneId;
  const violationId = params.violationId;
  const safetyMailingPrintedResponseId = params.safetyMailingPrintedResponseId;
  const safetyMailingPrintedResponseAction = new URLSearchParams(
    location.search
  ).get('action');
  const bin = params.bin;
  const formRef = useRef();
  const editView = workOrderId && !cloneId ? true : false;

  const [isCreating, setIsCreating] = useState(false);
  const [isVendorLoading, setVendorLoading] = useState(true);
  const [isBuildingLoading, setBuildingLoading] = useState(true);
  const [isUsersLoading, setUsersLoading] = useState(true);
  const [isUploading, setIsUploading] = useState(false);
  const [loading, setLoading] = useState(false);
  const [formData, setFormData] = useState({ vendorNotification: 'NoEmail' });
  // Used for Edit
  const [originalData, setOriginalData] = useState({
    vendorNotification: 'NoEmail',
  });
  const [workOrderBuilding, setWorkOrderBuilding] = useState(null);
  const [buildings, setBuildings] = useState([]);
  const [priority, setPriority] = useState('Low');
  const [subUsers, setSubUsers] = useState([]);
  const [vendors, setVendors] = useState([]);
  const [uploads, setUploads] = useState([]);
  const [partialEdit, setPartialEdit] = useState(false);
  const [violationData, setViolationData] = useState({});
  const [safetyMailingPrintedResponse, setSafetyMailingPrintedResponse] =
    useState({});
  const [categories, setCategories] = useState([]);
  const [allStatus, setAllStatus] = useState([]);
  const [dueDatePicker, setDueDatePicker] = useState(false);
  const [tooltipOpen_1, setTooltipOpen_1] = useState(false);
  const [tooltipOpen_2, setTooltipOpen_2] = useState(false);
  const [tooltipOpen_3, setTooltipOpen_3] = useState(false);
  const [loadingCheck, setLoadingCheck]= useState(false);
  useEffect(() => {
    if (workOrderBuilding) {
      const buildingExists = buildings.find(
        (b) => b.bin === workOrderBuilding.bin
      );
      if (!buildingExists) {
        setBuildings((prev) => [...prev, workOrderBuilding]);
      }
    }
  }, [buildings, workOrderBuilding]);

  const loadBuildings = async () => {
    setBuildingLoading(true);
    const buildingRes = await creService.getBuildingsListOnly();
    setBuildings(buildingRes.data);
    setBuildingLoading(false);
  };

  const loadViolation = async () => {
    const res = await creService.getWorkOrderViolation(violationId);
    if (res.data.status) {
      setViolationData(res.data.data);
      setFormData((state) => ({
        ...state,
        description: res.data.data.description,
        aptNumber: res.data.data.apartment
      }));

    } else {
      toast.error(res.data.message);
    }
  };

  const buildSafetyMailingResponseDescription = (data) => {
    let description = '';
    if (data.windowsSummaryReport) {
      delete data.windowsSummaryReport.Signature;
      delete data.windowsSummaryReport.Tenant;
      delete data.windowsSummaryReport.Unit;
      delete data.windowsSummaryReport.Signature;

      for (const key of Object.keys(data.windowsSummaryReport)) {
        description += `${key}: ${data.windowsSummaryReport[key]}\n`;
      }
    }
    return description;
  };

  const loadSafetyMailingPrintedResponse = async () => {
    const res = await creService.getSafetyMailingPrintedResponse(
      safetyMailingPrintedResponseId
    );
    if (res.data.status) {
      const { data } = res.data;
      if (data) {
        setSafetyMailingPrintedResponse(data);
        setFormData((state) => ({
          ...state,
          title: safetyMailingPrintedResponseAction,
          category: `Safety Mailings: ${safetyMailingPrintedResponseAction}`,
          aptNumber: data?.printedRow.aptNumber,
          description: buildSafetyMailingResponseDescription(data),
        }));
      }
    } else {
      toast.error(res.data.message);
    }
  };

  const loadCategories = async () => {
    const res = await creService.getWorkOrderCategories(workOrderId);
    if (res.data.status) {
      setCategories(res.data.data);
    } else {
      toast.error(res.data.message);
    }
  };

  const loadAllStatus = async () => {
    const res = await creService.getWorkOrderStatus(workOrderId);
    if (res.data.status) {
      setAllStatus(res.data.data);
    } else {
      toast.error(res.data.message);
    }
  };

  const loadVendors = async () => {
    setVendorLoading(true);
    const vendorsRes = await creService.getWorkOrderVendors(
      editView,
      workOrderId
    );
    setVendors(vendorsRes.data.vendors || []);
    if (vendorsRes.data.permissions) {
      localStorage.setItem(
        permissionsKey,
        JSON.stringify(vendorsRes.data.permissions)
      );
    }
    setVendorLoading(false);
  };

  const loadSubUsers = async () => {
    setUsersLoading(true);
    const subUsersRes = await creService.getAssignees();
    setSubUsers(subUsersRes.data.data);
    if (subUsersRes.data.permissions) {
      localStorage.setItem(
        permissionsKey,
        JSON.stringify(subUsersRes.data.permissions)
      );
    }
    setUsersLoading(false);
  };

  const handleInput = (e) => {
    let { name, value, type, checked } = e.target;
    if (type === 'date') {
      value = new Date(value)
    } else if (type === 'checkbox') {
      value = checked
    } else {
      value = value.length ? value : null
    }
    if (name === 'estimatedTime')
      if (value < 0) toast.error('Please enter positive number');
    setFormData((state) => ({
      ...state,
      [name]: value
    }));
  };

  const handleOnSubmit = async (e) => {
    e.preventDefault();
    if (editView && formData['requireUpload'] && !uploads.length) {
      toast.error('Uploads are required');
      return
    }

    setIsCreating(true);
    let payload = {
      ...formData,
      priority,
      uploads,
    };



    if (partialEdit) {
      let updatedFormData = {};
      Object.keys(formData).map((dataKey) => {
        if (['_id', 'status', 'description'].indexOf(dataKey) !== -1) {
          updatedFormData = {
            ...updatedFormData,
            [dataKey]: formData[dataKey],
          };
        }
        return true;
      });
      payload = {
        ...updatedFormData,
        uploads,
      };
    }

    if (violationId) {
      payload = {
        ...payload,
        violationId,
        bin,
      };
    }

    if (safetyMailingPrintedResponseId) {
      payload = {
        ...payload,
        safetyMailingPrintedResponseId,
        safetyMailingAction: safetyMailingPrintedResponseAction,
        bin,
      };
    }

    try {
      if (cloneId) {
        delete payload.status;
      }
      if (editView && !cloneId) {
        delete payload.uploads;
      }
      delete payload.added_at;
      delete payload.masterClientId;
      delete payload.workOrderId;
      delete payload.createdBy;
      delete payload.cloneOf;
      delete payload.vendorDetails;
      delete payload.userId;

      if (payload.dueDate?.length > 10)
        payload.dueDate = moment(payload.dueDate.split('T')[0]).format(
          'MM/DD/YYYY'
        ); // If Due Date wasn't changed lets cut out the hours and leave date only

      const res =
        editView && !cloneId
          ? await creService.updateWorkOrder(payload)
          : await creService.createWorkOrder({
            ...payload,
            uploads: payload.uploads.map((doc) => ({
              originalname: doc.originalname,
              key: doc.key,
              size: doc.size,
              _id: doc._id
            })),
          });
      if (res.data.status) {
        formRef.current.reset();
        toast.success(res.data.message);
        if (res.data.workOrderId) {
          history.push(`/work-orders/view/${res.data.workOrderId}/documents`);
        } else if (previousLocationFullPath.length > 0) {
          history.push(previousLocationFullPath);
        } else {
          history.push('/work-orders/list');
        }
      } else {
        if (res.data.errors) {
          res.data.errors.map((err) => toast.error(err));
        } else {
          toast.error(res.data.message);
        }
      }
      if (res.data.permissions) {
        localStorage.setItem(
          permissionsKey,
          JSON.stringify(res.data.permissions)
        );
      }
      setIsCreating(false);
    } catch (_err) {
      toast.error(_err.message || 'Something went wrong');
      setIsCreating(false);
    }
  };

  const getworkOrderData = async (id) => {
    setLoading(true);
    const res = await creService.editWorkOrder(id);
    if (res.data.status) {
      if (res.data.violationData) setViolationData(res.data.violationData);
      if (res.data.building) {
        setWorkOrderBuilding(res.data.building);
      }
      setFormData(res.data.data);
      setOriginalData(res.data.data);
      setPriority(res.data.data.priority);
      setUploads(res.data.data.uploads);
    } else {
      toast.error(res.data.message);
    }
    if (res.data.permissions) {
      localStorage.setItem(
        permissionsKey,
        JSON.stringify(res.data.permissions)
      );
      setPartialEdit(
        res.data.permissions['workOrders'].includes('partial_edit')
          ? true
          : false
      );
    }
    setLoading(false);
  };

  const onDueDateChange = (date) => {
    setFormData((state) => ({
      ...state,
      dueDate: moment
        .utc(moment(date).format('YYYY-MM-DD'))
        .format('MM/DD/YYYY'),
    }));
    setDueDatePicker(false);
  };

  const handleAssignToChange = (assignedUsers) => {
    setFormData((state) => ({
      ...state,
      assignedUsers,
    }));
  };
  const checkWorkOrderCreateAblility = async () => {
    setLoadingCheck(true)
    try {
      const res = await creService.checkWorkOrderCreateAbility();
      if(!res.data.bcomplaint && res.data.visitt) {
        history.push('/work-orders/create-visitt')
      }
    } catch (err) {

    }
    setLoadingCheck(false)
   
  }


  useEffect(() => {
    checkWorkOrderCreateAblility()
    loadCategories();
    loadAllStatus();
    loadBuildings();
    loadVendors();
    loadSubUsers();
    if (violationId) {
      loadViolation();
    }

    if (safetyMailingPrintedResponseId) {
      loadSafetyMailingPrintedResponse();
    }
    if (editView || cloneId) {
      getworkOrderData(workOrderId);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [workOrderId]);

  useEffect(() => {
    if (!workOrderId) {
      formRef.current.reset();
      formRef.current.title.value = '';
      formRef.current.dueDate.value = '';
      formRef.current.description.value = '';
      formRef.current.recurring.value = '';
      formRef.current.recurringFrequency.value = '';
      formRef.current.estimatedTime.value = '';
      formRef.current.aptNumber.value = '';
      setFormData({ vendorNotification: 'NoEmail' });
      setWorkOrderBuilding(null);
      setPriority('Low');
      setUploads([]);
      setViolationData({});
      window.scrollTo(0, 0);
    }
  }, [workOrderId]);

  const currentVendor = vendors?.find((item) => formData.vendorId === item._id);

  return loadingCheck ? <ReactLoading type={'cylon'} color={'grey'} /> : (
    <Fragment>
      <div className="row violation-sec">
        <div className="col-xl-12 my-3">
          <div className="card">
            <div className="card-header">
              <Link to="/work-orders/list">
                <button
                  className="btn btn-light btn-lg align-self-center back-btn mx-2"
                  type="button"
                >
                  <i className="fa fa-angle-left" />
                </button>
              </Link>
              <h5>
                {editView ? 'Edit' : 'Create'} Work Order{' '}
                {editView ? `#${originalData.workOrderId}` : ''}
              </h5>
            </div>
            {loading ? (
              <ReactLoading type={'cylon'} color={'grey'} />
            ) : (
              <div className="card-body">
                {Object.keys(violationData).length > 0 && (
                  <h6>
                    <b>{violationData.label}:</b> {violationData.data}
                  </h6>
                )}
                {editView && partialEdit && (
                  <PartialEditTextFields
                    formData={formData}
                    priority={priority}
                    buildings={buildings}
                  />
                )}
                <form ref={formRef} onSubmit={(e) => handleOnSubmit(e)}>
                  {(!editView || !partialEdit) && (
                    <>
                      <div className="row">
                        <div className="form-group col-12">
                          <label>
                            Title <sup className="text-danger">*</sup>
                          </label>
                          <input
                            type="text"
                            name="title"
                            className="form-control"
                            onChange={(e) => handleInput(e)}
                            required
                            value={formData.title}
                          />
                        </div>
                        <div className="form-group col-12 col-md-8">
                          <label>
                            Building Address{' '}
                            <sup className="text-danger">*</sup>
                          </label>
                          <select
                            className="form-control"
                            defaultValue={formData.bin}
                            value={bin}
                            disabled={
                              isBuildingLoading ||
                              Object.keys(violationData).length > 0 ||
                              Object.keys(safetyMailingPrintedResponse).length
                            }
                            name="bin"
                            required
                            onChange={(e) => handleInput(e)}
                          >
                            <option value="">
                              {isBuildingLoading
                                ? 'Loading...'
                                : '-- Select --'}
                            </option>
                            {buildings &&
                              buildings.map((building) => (
                                <option
                                  key={`building_${building.bin}`}
                                  value={building.bin}
                                >
                                  {building.display_address
                                    ? building.display_address
                                    : building.address}
                                </option>
                              ))}
                          </select>
                        </div>
                        <div className="form-group col-12 col-md-4">
                          <label>Apt. #</label>
                          <input
                            type="text"
                            name="aptNumber"
                            className="form-control"
                            onChange={(e) => handleInput(e)}
                            value={formData.aptNumber}
                          />
                        </div>
                        <div className="form-group col-12 col-md-4">
                          <label>
                            Category <sup className="text-danger">*</sup>
                          </label>
                          <select
                            defaultValue={formData.category}
                            className="form-control"
                            name="category"
                            required
                            onChange={(e) => handleInput(e)}
                          >
                            <option value="">-- Select --</option>
                            {categories &&
                              categories.map((category, index) => (
                                <option key={index} value={category}>
                                  {category}
                                </option>
                              ))}
                          </select>
                        </div>
                        <div className="form-group col-12 col-md-4">
                          <label>Recurring Every</label>
                          <div className="d-flex">
                            <input
                              type="text"
                              className="form-control"
                              name="recurring"
                              onChange={(e) => handleInput(e)}
                              defaultValue={formData.recurring}
                            />
                            <select
                              defaultValue={formData.recurringFrequency}
                              className="form-control"
                              name="recurringFrequency"
                              onChange={(e) => handleInput(e)}
                            >
                              <option value="">-- Frequency --</option>
                              <option value="week">Week</option>
                              <option value="month">Month</option>
                            </select>
                          </div>
                        </div>
                        <div className="form-group col-12 col-md-4">
                          <label>Estimated Time (in hours)</label>
                          <input
                            type="number"
                            name="estimatedTime"
                            min="0"
                            className="form-control"
                            onChange={(e) => handleInput(e)}
                            defaultValue={formData.estimatedTime}
                          />
                        </div>
                      </div>
                      <div className="row">
                        <div className="form-group col-12 col-md-6">
                          <label>
                            Due Date <sup className="text-danger">*</sup>
                          </label>
                          <div className="input-group ">
                            <input
                              required
                              readOnly
                              type="text"
                              name="dueDate"
                              className="form-control bg-white"
                              onClick={() => setDueDatePicker((prev) => !prev)}
                              value={
                                formData.dueDate &&
                                moment
                                  .utc(formData.dueDate)
                                  .format('MM/DD/YYYY')
                              }
                            />
                            <div className="input-group-append">
                              <button
                                className="btn btn-primary"
                                onClick={() =>
                                  setDueDatePicker((prev) => !prev)
                                }
                                type="button"
                              >
                                <i className="fa fa-calendar"></i>
                              </button>
                            </div>
                          </div>
                          {dueDatePicker && (
                            <DueDatePicker
                              initialDate={originalData.dueDate}
                              currentDate={formData.dueDate}
                              editView={editView}
                              onChange={onDueDateChange}
                            />
                          )}
                        </div>
                      </div>
                      <div className="row">
                        <div className="form-group col-12 col-md-4">
                          <label className='mr-2'>
                            Priority
                          </label>
                          <ButtonGroup>
                            <Button
                              className="priority"
                              color="info"
                              onClick={() => setPriority('Low')}
                              active={priority === 'Low'}
                            >
                              Low
                            </Button>
                            <Button
                              className="priority"
                              color="warning"
                              onClick={() => setPriority('Medium')}
                              active={priority === 'Medium'}
                            >
                              Medium
                            </Button>
                            <Button
                              className="priority"
                              color="danger"
                              onClick={() => setPriority('High')}
                              active={priority === 'High'}
                            >
                              High
                            </Button>
                          </ButtonGroup>
                        </div>
                      </div>

                      <div className="row">
                        <div className="form-group col-12 col-md-6">
                          <label>Vendor</label>
                          <select
                            defaultValue={formData.vendorId}
                            className="form-control"
                            name="vendorId"
                            disabled={isVendorLoading}
                            onChange={(e) => handleInput(e)}
                          >
                            <option value="">
                              {isVendorLoading ? 'Loading...' : '-- Select --'}
                            </option>
                            {vendors &&
                              vendors.map((vendor) => (
                                <option
                                  key={`vendor_${vendor._id}`}
                                  value={vendor._id}
                                >
                                  {vendor.fullName}
                                </option>
                              ))}
                          </select>
                        </div>
                      </div>

                      {formData.vendorId && (
                        <div className="row">
                          <div className="col-12 col-md-6">
                            <label>
                              Vendor Notification
                              {currentVendor && (
                                <span className={'font-weight-bold'}>
                                  {' '}
                                  (All email sent to {currentVendor.email})
                                </span>
                              )}
                            </label>
                            <div className="row">
                              <div className="form-group col-12 col-md-5">
                                <div className="form-check">
                                  <label className="fom-check-label">
                                    <input
                                      className="form-check-input"
                                      type="radio"
                                      name={'vendorNotification'}
                                      checked={
                                        formData.vendorNotification ===
                                        'NoEmail' ||
                                        formData.vendorNotification ===
                                        'EmailNewOnly'
                                      }
                                      value={'NoEmail'}
                                      required={
                                        currentVendor !== null ||
                                        typeof currentVendor !== 'undefined'
                                      }
                                      onChange={(e) => handleInput(e)}
                                    />
                                    No Emails
                                    <i
                                      id="NoEmail"
                                      className="fa fa-info-circle text-black-50 p-2 fa-lg"
                                    />
                                  </label>
                                  <Tooltip
                                    placement="right"
                                    isOpen={tooltipOpen_1}
                                    target="NoEmail"
                                    toggle={() =>
                                      setTooltipOpen_1(!tooltipOpen_1)
                                    }
                                  >
                                    Vendors will not receive any emails for this
                                    work order.
                                  </Tooltip>
                                </div>
                              </div>
                              {!editView && (
                                <div className="form-group col-12 col-md-5">
                                  <div className="form-check">
                                    <label className="fom-check-label">
                                      <input
                                        className="form-check-input"
                                        type="radio"
                                        name={'vendorNotification'}
                                        checked={
                                          formData.vendorNotification ===
                                          'EmailNewOnly'
                                        }
                                        value={'EmailNewOnly'}
                                        required={
                                          currentVendor !== null ||
                                          typeof currentVendor !== 'undefined'
                                        }
                                        onChange={(e) => handleInput(e)}
                                      />
                                      Email New Only
                                      <i
                                        id="emailNewOnly"
                                        className="fa fa-info-circle text-black-50 p-2 fa-lg"
                                      />
                                    </label>
                                    <Tooltip
                                      placement="right"
                                      isOpen={tooltipOpen_2}
                                      target="emailNewOnly"
                                      toggle={() =>
                                        setTooltipOpen_2(!tooltipOpen_2)
                                      }
                                    >
                                      Vendor will receive an email when this
                                      work order is created.
                                    </Tooltip>
                                  </div>
                                </div>
                              )}
                              <div className="form-group col-12 col-md-5">
                                <div className="form-check">
                                  <label className="form-check-label">
                                    <input
                                      type="radio"
                                      className="form-check-input"
                                      name={'vendorNotification'}
                                      checked={
                                        formData.vendorNotification ===
                                        'EmailAllUpdates'
                                      }
                                      value={'EmailAllUpdates'}
                                      required={
                                        currentVendor !== null ||
                                        typeof currentVendor !== 'undefined'
                                      }
                                      onChange={(e) => handleInput(e)}
                                    />
                                    Email All Updates
                                    <i
                                      id="emailAllUpdates"
                                      className="fa fa-info-circle text-black-50 p-2 fa-lg"
                                    />
                                  </label>
                                  <Tooltip
                                    placement="right"
                                    isOpen={tooltipOpen_3}
                                    target="emailAllUpdates"
                                    toggle={() =>
                                      setTooltipOpen_3(!tooltipOpen_3)
                                    }
                                  >
                                    Vendor will receive an email when this work
                                    order is created and will receive an email
                                    about every modification of this work order.
                                  </Tooltip>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      )}

                      <div className="row">
                        <div className="form-group  col-6">
                          <label>Assign To</label>
                          <Typeahead
                            id="assignedToUsers"
                            multiple
                            disabled={isUsersLoading}
                            placeholder="Users"
                            onChange={(selected) => {
                              handleAssignToChange(selected.map((b) => b._id));
                            }}
                            selected={subUsers?.filter((b) =>
                              formData.assignedUsers?.includes(b._id)
                            )}
                            labelKey={(option) => {
                              if (option.fullName) {
                                return option.fullName;
                              }
                              return option._id;
                            }}
                            options={subUsers}
                          />
                        </div>

                      </div>
                      <div className="form-group">
                        <div>
                          <Checkbox
                            checked={formData['requireUpload']}
                            name='requireUpload'
                            onChange={handleInput} > Require upload before completing</Checkbox>
                        </div>
                      </div>

                    </>
                  )}
                  {editView && (
                    <div className="row">
                      <div className="form-group col-12 col-md-6">
                        <label>
                          Status <sup className="text-danger">*</sup>
                        </label>
                        <select
                          className="form-control"
                          defaultValue={formData.status}
                          name="status"
                          required
                          onChange={(e) => handleInput(e)}
                        >
                          <option value="">-- Select --</option>
                          {allStatus &&
                            allStatus.map((status, index) => (
                              <option key={index} value={status}>
                                {status}
                              </option>
                            ))}
                        </select>
                      </div>
                    </div>
                  )}
                  <div className="row">
                    <div className="form-group col-12">
                      <label>
                        Description <sup className="text-danger">*</sup>
                      </label>
                      <textarea
                        type="text"
                        name="description"
                        className="form-control"
                        onChange={(e) => handleInput(e)}
                        required
                        value={formData.description}
                      ></textarea>
                    </div>
                    <div className="form-group col-12">
                      <div className="row">
                        <div className="col">
                          <div className="form-group row">
                            <label className="col-sm-3 col-form-label">
                              Upload Document
                            </label>
                            <div className="col-sm-9">
                              <WorkOrderUploadDocument
                                isUploading={(state) => setIsUploading(state)}
                                uploads={uploads}
                                setUploads={setUploads}
                                allowed={true}
                                id={formData._id}
                                editView={editView || partialEdit}
                                createView={!(editView || partialEdit)}
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="form-group col-12 mb-0">
                      <button
                        type="submit"
                        className="btn btn-primary"
                        disabled={isCreating || isUploading}
                      >
                        {editView ? 'Update' : 'Add'}
                      </button>
                    </div>
                  </div>
                </form>
              </div>
            )}
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default WorkOrderCreate;
