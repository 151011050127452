import axios from 'axios';
import logger from './logService';
import { toast } from 'react-toastify';
import { logout } from './authService'
import ReactVersionTostify from '../common/reactVersionTostify';

let timeOut = false;

axios.interceptors.response.use(
  (response) => {

    const localStorageVersion = localStorage.getItem('x-react-version');
    const reactVersion = response.headers['x-react-version'];
    if (!localStorageVersion) {
      localStorage.setItem('x-react-version', reactVersion)
    } else if (reactVersion && localStorageVersion !== reactVersion && !timeOut) {
      toast(<ReactVersionTostify reactVersion={reactVersion} />, {
        autoClose: false,
      });
      timeOut = true;
      setTimeout(() => {
        timeOut = false;
      }, 5 * 60 * 1000);
    }

    return response;
  },
  (error) => {
    const expectedError =
      error.response &&
      error.response.status >= 400 &&
      error.response.status < 500;

    if (!expectedError) {
      logger.log(error);
      toast.error('An unexpected error occurred. ' + error);
    }



    if (error.response) {
      if (error.response.data.includes('Invalid token')) {
        logout()
      }
      const message =
        error.response?.data?.error ||
        error.response?.data?.message ||
        'Something went wrong!';
      const { status } = error.response;

      return Promise.reject({ message, status });
    }

    // logout();
    // window.location = "/login";
    return Promise.reject(error);
  }
);

const portfolioId = localStorage.getItem('portfolioId');

//setting a header for each request by default
function setJwt(jwt) {
  axios.defaults.headers.common['x-auth-cre-token'] = jwt;
  axios.defaults.headers.common['x-app-version'] = '2.5.6';
  axios.defaults.headers.common['x-platform'] = 'web';
  axios.defaults.headers.common['x-portfolioId'] = portfolioId
    ? portfolioId
    : null;
}

const downloadFile = (url, name, method, data) => {

  return axios({
    method,
    data,
    url,
    responseType: 'blob', // important
  }).then((response) => {
    // create file link in browser's memory
    const href = URL.createObjectURL(response.data);

    // create "a" HTML element with href to file & click
    const link = document.createElement('a');
    link.href = href;
    link.setAttribute('download', name); //or any other extension
    document.body.appendChild(link);
    link.click();

    // clean up "a" element & remove ObjectURL
    document.body.removeChild(link);
    URL.revokeObjectURL(href);
  });
}


// eslint-disable-next-line import/no-anonymous-default-export
export default {
  downloadFile,
  get: axios.get,
  post: axios.post,
  put: axios.put,
  delete: axios.delete,
  setJwt,
};
