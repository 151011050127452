import React, { Fragment, useState, useEffect } from "react";
import { useParams } from 'react-router-dom';
import ComplianceRequirements from './compilance';

import HistoryUpdates from "../buildings/partials/historyUpdates";
import SmallCalendar from '../calendar/smallCalendar';

const Compliance = (props) => {
    const params = useParams();

    const complianceList = [
        'BOILER',
        'DEP_Boiler',
        'HPD_Registration',
        'CAT1',
        'CAT5',
        'LL11',
        'LL84',
        'LL87',
        'LL152',
        'LL97',
        'Lead-HPD',
        'Mold-HPD',
        'PBS',
        'Water_Tank'

    ];

    const [board, setBoard] = useState(props.jestData !== undefined ? props.jestData.board : null);



    const handleMenuClick = async (category, type) => {
        setBoard(category);
        
        props.history.push(`/compliance-requirements/${category}`)

    }

 


    useEffect(() => {
        let category = params.category;
        if (!params.category || !category || category === 'DOB Boiler') {
            category = 'BOILER';
        }
        
        
        setBoard(category.replaceAll(' ', '_'))

        // eslint-disable-next-line    
    }, [])

    return (
        <Fragment>
            <div className="container-fluid">
                <div className="row violation-sec">
                    <div className="col-xl-9 compliance pl-0 pr-0">
                        <div className="card">
                            <div className="card-header">
                                <h5>compliance Due</h5>
                            </div>
                            <div className="card-body">
                                <ul className="custom_tab tab-sec">
                                    {complianceList.map((complianceName) => <li key={complianceName} className={`${board === complianceName ? "active" : ""}`} onClick={() => handleMenuClick(complianceName, "AUTO")}>
                                        {complianceName.split('-')[0].replace(/_/, ' ').toUpperCase()}
                                    </li>)}
                                </ul>
                          
                                <ComplianceRequirements board={board} {...props} />
                             
                            </div>
                        </div>
                    </div>
                    <div className="col-xl-3 recent-activity pr-0">
                        <div className="pt-3 pb-3">
                            {props.jestData !== undefined ? null : <SmallCalendar />}
                        </div>
                        <HistoryUpdates />
                    </div>
                </div>
            </div>
        </Fragment>
    );
};

export default Compliance
