
import { Modal } from "antd";
import { useEffect, useState } from "react";
import moment from 'moment';
import { toast } from 'react-toastify';

import creApi from '../services/creService';
import ChatThread from '../pages/buildings/chat/chatThread';
import './document-viewer.css'


const DocsViewer = ({ currentKey, document: docProp, isVisible, setIsVisible, disableChat, useAWSURL, customName }) => {

    const [url, setUrl] = useState(null)
    const [document, setDocument] = useState(null)
    const [uploaderName, setUploaderName] = useState(null)

    useEffect(() => {
        if (document) {
            getUploaderName()
            const jwt = localStorage.getItem('cre-token');
            setUrl(window.location.origin + `/api/documents/aws-s3-file-stream/${jwt}:${document._id}`)
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [document])

    useEffect(() => {

        if (currentKey && isVisible && document?.file?.key !== currentKey) {
            creApi.getDocumentByKey(currentKey).then(({ data }) => setDocument(data.document));
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [currentKey, isVisible])


    useEffect(() => {
        setDocument(docProp)
    }, [docProp])

    const getUploaderName = async () => {
        if (document.uploadedByClientId) {
            try {
                const response = await creApi.getUploaderName(document.uploadedByUserType, document.uploadedByClientId)
                if (response.data.status) {
                    setUploaderName(response.data.name)
                } else {
                    toast.error(response.data.message);
                }
            } catch (e) {
                toast.error(e.message);
            }
        }

    }

    const documentName = document?.signatureName ? document?.signatureName : (document?.file?.originalname ? document?.file?.originalname : document?.originalname)
    return document ? (

        <Modal
            style={{ top: 20 }}
            title={customName ? customName : documentName}
            visible={isVisible}
            footer={null}
            onCancel={() => setIsVisible(false)}
            width={'90%'}
        >
            {!disableChat && <div className="d-flex ">
                <div><strong>Uploaded:</strong> {moment(new Date(document.createdAt)).format('LLL')} {uploaderName ? `by ${uploaderName}` : ''} </div>

            </div>
            }

            <div className='d-flex justify-content-around flex-wrap'>
                <div className={` ${disableChat ? 'w-100' : 'w-60'} d-flex justify-content-between align-items-center flex-column`}>
                    {document.file?.mimetype && (document.file.mimetype.includes('image') ?
                        (<div className="w-100 d-flex justify-content-center align-items-center min-height-75vh ">
                            <img src={url} alt='here should be img' className="doc-image " />
                        </div>)
                        : (
                            <div className="w-100 p-3">
                                <iframe
                                    className="w-100 min-height-75vh"
                                    src={document.file.mimetype.includes('pdf') ?
                                        (document.file?.mimetype && !useAWSURL ? url : document.awsUrl) : "https://docs.google.com/viewer?url=" + url + "&embedded=true"}
                                    title={document.file.originalname}>

                                </iframe>
                            </div>))}
                    <a className="btn btn-primary mt-2" href={document.file?.mimetype ? url : document.awsUrl} target='_blank' rel="noreferrer">Download File</a>
                </div>
                {document.file?.mimetype && !disableChat && <div className="w-30 chat-wrapper-main">
                    <ChatThread
                        source={'documents'}
                        id={document._id}
                    />

                </div>
                }

            </div>

        </Modal>

    ) : null

}

export default DocsViewer;