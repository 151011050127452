import creApi from "../../../services/creService";
import React, { useState, useEffect } from "react";
import { toast } from "react-toastify";
import moment from "moment";
import '../chat/chat.css';
import { Button, Checkbox } from 'antd';
import guard from '../../../common/guard';
import { Modal } from "antd";

const NotesThread = ({ notesList , fetchNotes}) => {
  const [noteToDelete, setNoteToDelete] = useState(null);
  if (notesList.length === 0) {
    return null
  }

   const removeNote = async () => {
    try {
      const { data } = await creApi.removeNote(noteToDelete._id);
      if (data.status) {
        toast.success(data.message);
        fetchNotes()
        setNoteToDelete(null)
      } else {
        toast.error(data.message);
      }
    } catch (e) {
      toast.error(e.message);
    }
   }
  return (
    <div className="chat-wrapper mb-4">
      <div className="messages-wrapper">
        {
          notesList.map((note) => (
            <div key={note._id} className="chat-message w-100" style={{ whiteSpace: 'pre-wrap' }}>
              {note.notes}
              <div className="d-flex justify-content-between">
                {note?.createdByUser?.fullName ?
                  <div className="author text-dark">
                    Created by {note?.createdByUser?.fullName} {note?.createdByUserType &&
                      <span>({note?.createdByUserType})</span>} at {moment(note?.createdAt).format('LLL')}
                  </div>
                  :
                  <div className="author text-dark">
                    Created at {moment(note?.createdAt).format('LLL')}
                  </div>
                }
                <div>{note.isPrivate && <span className="badge badge-light mt-2">private</span>}</div>




              </div>
              {guard({
                allow: () => true,
                deny: () => false,
                serviceName: 'violations',
                action: 'remove_notes',
              }) && <div className="d-flex justify-content-end">
                  <button
                    type="button"
                    className="btn btn-sm btn-outline-danger"
                    onClick={() => setNoteToDelete(note)}
                  >
                    Remove
                  </button>
                </div>}

            </div>
          ))
        }
      </div>
      <Modal

        title='Are you sure you want to delete this note?'
        visible={noteToDelete}
        footer={null}
        onCancel={() => setNoteToDelete(null)}

      >
        <div className="d-flex justify-content-between">
          <button
            type="button"
            className="btn btn-primary"
            onClick={() => setNoteToDelete(null)}
          >
            Close
          </button>
          <button
            type="button"
            className="btn btn-danger"
            onClick={removeNote} 
          >
            Confirm
          </button>

        </div>
      </Modal>
    </div>
  )
}

const Notes = ({ violationId, setNotesCount, source }) => {
  const [notes, setNotes] = useState('');
  const [notesList, setNotesList] = useState([]);
  const handleChangeNotes = (data) => {
    setNotes(data);
  }
  const [isPrivate, setIsPrivate] = useState(false)

  const fetchNotes = async () => {
    const notesResponse = await creApi.getNotes(violationId);
    if (notesResponse.data && typeof notesResponse.data.data !== 'undefined') {
      setNotesList(notesResponse.data.data);
    }
    ;
  }

  const saveNotes = async () => {
    try {
      const { data } = await creApi.saveNotes(violationId, { notes, isPrivate }, source);
      if (data.status) {
        toast.success(data.message);
        fetchNotes();
        setNotes('');
        setNotesCount && setNotesCount(notesList.length + 1)
        setIsPrivate(false)
      } else {
        toast.error(data.message);
      }
    } catch (e) {
      toast.error(e.message);
    }
  }

  useEffect(() => {
    fetchNotes();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <div className="card">
      <div className="card-body pt-2">
        <NotesThread notesList={notesList} fetchNotes={fetchNotes} />
        <div className="form-group column mb-0 mt-3">
          <label className="col-sm-3 pl-0 col-form-label"><b>Custom Notes</b></label>
          <div>
            <textarea className="form-control" rows="4" onChange={(e) => handleChangeNotes(e.target.value)}
              value={notes} />
            {guard({
              allow: () => true,
              deny: () => false,
              serviceName: 'violations',
              action: 'add_notes',
            }) && <div className="mt-2 d-flex align-items-center">
                <Checkbox
                  checked={isPrivate}
                  onChange={() => {
                    setIsPrivate(!isPrivate)
                  }}
                />
                <span className='ml-2 min-width-120'> Private Note </span>

              </div>}
            <Button className="btn btn-darkblue mt-2" type="button" onClick={() => saveNotes()}>Save</Button>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Notes;
